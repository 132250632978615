import styled from 'styled-components';
import { Flex, colors } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translate(-50%, 0);
  box-sizing: border-box;
  background-color: ${colors.black.standard};
  border-radius: 60px;
  padding: 14px 24px;
  height: 52px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  z-index: 6;

  svg {
    width: 20px;
    height: 20px;

    :hover {
      cursor: pointer;
    }

    :active {
      stroke: ${colors.white.standard};
    }

    path {
      stroke: ${colors.grey.dark};
    }
  }
`;
