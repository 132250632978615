import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Flex, Div, H2, ListRegionCode, Checkbox, BodySmall } from '@beauty/beauty-market-ui';
import { FormikInput } from '../../../components/functional/formik/formik-input/FormikInput';
import { FormikPasswordInput } from '../../../components/functional/formik/formik-password-input/FormikPasswordInput';
import { InvalidVariants } from '../../../constants';
import { signupPhoneVerification } from '../../../helpers/signupPhoneVerification';
import { getInvalidType, getListRegionCode } from '../../../helpers/utils';
import { useToggle } from '../../../hooks/useToggle';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch } from '../../../store/hooks';
import { updateSmsCodeTime } from '../../../store/redux-slices/userSlice';
import { GappedFlex } from '../../../style';
import {
  RegistrationForm,
  initialvalues,
  RegistrationFormFields,
  registrationFormValidationSchema,
} from './Registration.definitions';

const Registration = () => {
  const { t } = useTranslation();
  const [currentRegionCode, setCurrentRegionCode] = useState<string>('+972');
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isNotifications, setNotifications] = useToggle(true);
  const { state } = useLocation();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const email = state?.email;

  const { Number } = RegistrationFormFields;

  const onFormSubmit = useCallback(
    async (data: RegistrationForm) => {
      const phoneVer = { code: currentRegionCode, number: data[Number], email: data.email };
      const response = await signupPhoneVerification(phoneVer);

      if (response.status === 'pending') {
        setInvalidType(null);
        dispatch(updateSmsCodeTime(new Date().getTime()));
        navigate(RouterUrl.PhoneVerification, {
          state: {
            ...data,
            name: data.name.trim(),
            surname: data.surname.trim(),
            number: data.number.trim(),
            code: currentRegionCode,
            agreement: isNotifications,
          },
        });
      } else setInvalidType(getInvalidType(response.statusCode, 'registration'));
    },
    [navigate, currentRegionCode, isNotifications, invalidType],
  );

  const formikContextValue = {
    initialValues: { ...initialvalues, [RegistrationFormFields.Email]: email },
    validationSchema: registrationFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Flex flexDirection={['column', 'column', 'column']}>
      <H2>{t('registration.title')}</H2>
      <Div width="100%" mt="16px">
        <Formik {...formikContextValue}>
          {({ isValid }) => (
            <Form autoComplete="on">
              <GappedFlex gap="8px" flexDirection={['column', 'column', 'column', 'row']}>
                <FormikInput
                  autoFocus
                  id={RegistrationFormFields.Name}
                  name={RegistrationFormFields.Name}
                  autoComplete="given-name"
                  placeholder={t('registration.name')}
                  mb="8px"
                />
                <FormikInput
                  autoFocus
                  id={RegistrationFormFields.Surname}
                  name={RegistrationFormFields.Surname}
                  autoComplete="family-name"
                  placeholder={t('registration.surname')}
                  mb="16px"
                />
              </GappedFlex>

              <Flex mb="16px">
                <ListRegionCode
                  currentRegionCode={currentRegionCode}
                  handleSelect={code => setCurrentRegionCode(code)}
                  id={RegistrationFormFields.Region}
                  name={RegistrationFormFields.Region}
                  placeholder={t('registration.region')}
                  options={getListRegionCode()}
                />
                <Div width="100%" ml="8px">
                  <FormikInput
                    id={RegistrationFormFields.Number}
                    name={RegistrationFormFields.Number}
                    placeholder={t('registration.phone')}
                    invalidType={invalidType}
                    type="tel"
                    autoComplete="tel"
                    onInput={() => setInvalidType(null)}
                  />
                </Div>
              </Flex>
              <FormikInput
                id={RegistrationFormFields.Email}
                name={RegistrationFormFields.Email}
                defaultValue={email}
                autoComplete="email"
                type="email"
                placeholder="E-mail"
                mb="16px"
                disabled
              />
              <FormikPasswordInput
                id={RegistrationFormFields.Password}
                name={RegistrationFormFields.Password}
                autoComplete="new-password"
                type="password"
                placeholder={t('registration.password')}
                caption={t('registration.passwordHint')}
              />
              <Flex alignItems="center" my="30px">
                <Checkbox shape="round" onClick={setNotifications} checked={isNotifications} />
                <BodySmall low ml="8px">
                  {t('registration.iAgree')}
                </BodySmall>
              </Flex>
              <Button
                disabled={!isValid || invalidType}
                design="primary"
                mt="24px"
                width="100%"
                size="large"
                type="submit"
              >
                {t('button.continue')}
              </Button>
            </Form>
          )}
        </Formik>
      </Div>
    </Flex>
  );
};

export default Registration;
