import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { BodyLarge, Flex, ListRegionCode } from '@beauty/beauty-market-ui';
import { FormikTextfield } from 'components/functional/formik/formik-textfield/FormikTextfield';
import { getListRegionCode } from 'helpers';
import { FormikInput } from '../../../../../components/functional/formik/formik-input/FormikInput';
import { WrapperInput } from '../../../style';
import { ClientForm, ClientFormFields } from '../ClientForm.definitions';

type ContactInformationFormProps = {
  initialCode: string;
  initialAddCode: string;
  verified: boolean;
  editMode: boolean;
};

const ContactInformationForm = ({ initialCode, initialAddCode, editMode, verified }: ContactInformationFormProps) => {
  const { t } = useTranslation();
  const { values, errors, setFieldValue, touched, handleChange } = useFormikContext<ClientForm>();

  return (
    <>
      <BodyLarge large mb="16px">
        {t('calendar.newClientSidebar.contactInformation')}
      </BodyLarge>
      <Flex flexDirection="column">
        <Flex mb="16px" width="100%" justifyContent="space-between">
          <ListRegionCode
            design="white"
            backgroudColor="white"
            id={ClientFormFields.Code}
            name={ClientFormFields.Code}
            currentRegionCode={initialCode}
            placeholder={t('profile.region')}
            options={getListRegionCode()}
            handleSelect={code => {
              setFieldValue(ClientFormFields.Code, code);
            }}
            disabled={editMode}
          />
          <WrapperInput>
            <FormikInput
              design="white"
              id={ClientFormFields.Number}
              name={ClientFormFields.Number}
              placeholder={t('profile.phone')}
              disabled={editMode}
              invalidType={touched[ClientFormFields.Number] && errors[ClientFormFields.Number]}
            />
          </WrapperInput>
        </Flex>
        <Flex mb="16px" width="100%" justifyContent="space-between">
          <ListRegionCode
            design="white"
            backgroudColor="white"
            id={ClientFormFields.AdditionalCode}
            name={ClientFormFields.AdditionalCode}
            currentRegionCode={initialAddCode}
            placeholder={t('profile.region')}
            options={getListRegionCode()}
            handleSelect={additionalCode => {
              setFieldValue(ClientFormFields.AdditionalCode, additionalCode);
            }}
          />

          <WrapperInput>
            <FormikInput
              design="white"
              id={ClientFormFields.AdditionalNumber}
              name={ClientFormFields.AdditionalNumber}
              placeholder={t('registration.additionalNumber')}
            />
          </WrapperInput>
        </Flex>
        <FormikInput
          design="white"
          id={ClientFormFields.Email}
          name={ClientFormFields.Email}
          placeholder={t('profile.email')}
          mb="16px"
          disabled={editMode && verified}
        />
        <FormikTextfield
          id={ClientFormFields.AdditionalInfo}
          name={ClientFormFields.AdditionalInfo}
          width="100%"
          rows={4}
          design="white"
          onChange={handleChange}
          value={values[ClientFormFields.AdditionalInfo]}
          defaultValue={values[ClientFormFields.AdditionalInfo]}
          placeholder={t('registration.additionalInfo')}
        />
      </Flex>
    </>
  );
};

export { ContactInformationForm };
