import { useState } from 'react';
import moment from 'moment/moment';
import { Nullable } from 'tsdef';
import { BodySmall, Caption, colors } from '@beauty/beauty-market-ui';
import { Widget } from 'components';
import { getFormattedCurrency, getTranslation } from 'helpers';
import { ClientPayments } from 'types';
import { getSelectedLanguage } from '../../../../constants';
import { StyledBodyLarge } from '../../style';
import { PaymentInfo } from './PaymentInfo';

type Props = {
  payments: ClientPayments[];
};

export const PaymentsList = ({ payments }: Props) => {
  const [selectedPayment, setSelectedPayment] = useState<Nullable<ClientPayments>>(null);

  const language = getSelectedLanguage();

  return (
    <>
      {payments.map((payment, index, arr) => {
        const translatedService = getTranslation(payment.service, language);
        return (
          <Widget
            key={payment.id}
            onClick={() => setSelectedPayment(payment)}
            title={<Caption color={colors.grey.dark}>{moment(payment.date).format('DD MMMM YYYY')}</Caption>}
            subtitle={
              <StyledBodyLarge large title={translatedService}>
                {translatedService}
              </StyledBodyLarge>
            }
            label={<BodySmall large>{getFormattedCurrency(payment.price, payment.currency, language)}</BodySmall>}
            separator={index !== arr.length - 1}
            m={index !== arr.length - 1 ? '16px 0' : '16px 0 0 0'}
            p="0 15px 0 0"
          />
        );
      })}
      {selectedPayment && <PaymentInfo payment={selectedPayment} onClose={() => setSelectedPayment(null)} />}
    </>
  );
};
