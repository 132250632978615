import { useTranslation } from 'react-i18next';
import join from 'lodash/join';
import lowerCase from 'lodash/lowerCase';
import {
  Avatar,
  Badge,
  BodyLarge,
  Caption,
  colors,
  Flex,
  getMinWidthMediaQuery,
  Separator,
  useMediaQuery,
  Div,
} from '@beauty/beauty-market-ui';
import { formatPhoneNumber, getFullName } from 'helpers';
import { Tooltip } from '../../Tooltip';

const EmployeeCard = ({ id, specialization, role, account, separator, onClick, onActivate, active, disabled }) => {
  const { t } = useTranslation();

  const mediaQuery = getMinWidthMediaQuery('sm');
  const isDesktop = useMediaQuery(mediaQuery);

  const fullName = getFullName(account);
  const phone = formatPhoneNumber(account.code, account.number);
  const contact = join([phone, isDesktop ? account.email : undefined].filter(Boolean), ' • ');

  return (
    <>
      <Flex
        onClick={onClick}
        cursor="pointer"
        p={isDesktop ? '16px 0' : '8px 0'}
        alignItems={isDesktop ? 'stretch' : 'center'}
      >
        <Div flexShrink={0}>
          <Avatar size="xs" url={account.avatarUrl} rating="" anonymos={!account.avatarUrl ? 'default' : undefined} />
        </Div>
        <Flex flexDirection="column" gap="2px" ml="8px" mr="auto" flexShrink={0} width={isDesktop ? 'auto' : '46vw'}>
          <Caption color={colors.grey.dark}>{isDesktop ? specialization : undefined}</Caption>
          <BodyLarge large truncated>
            {fullName}
          </BodyLarge>
          <Caption color={colors.grey.dark}>{contact}</Caption>
        </Flex>
        <Flex alignItems="center" flexWrap={isDesktop ? 'nowrap' : 'wrap'} gap="16px" justifyContent="end">
          {!active && (
            <Tooltip id={id} content={t('specialists.forActivateSpec')} show={disabled}>
              <Badge data-tooltip-id={id} text={t('specialists.activate')} design="red" onClick={onActivate} />
            </Tooltip>
          )}
          <Badge text={t(`settings.specialists.positions.${lowerCase(role)}`)} design="dark" />
        </Flex>
      </Flex>
      {separator && <Separator />}
    </>
  );
};

export default EmployeeCard;
