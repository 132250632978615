export const editFields = {
  photos: { mainPhoto: true },
  mainInformation: {
    email: true,
    name: true,
    properties: true,
    number: true,
    instagram: true,
    telegram: true,
  },
  detailedInformation: {
    country: true,
    city: true,
    street: true,
    building: true,
    office: true,
    postal: true,
  },
};
