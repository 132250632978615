import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { Button, Flex, H6, SearchIcon, ServiceCard } from '@beauty/beauty-market-ui';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { useAppSelector } from 'store/hooks';
import { selectCurrency } from 'store/redux-slices/organisationSlice';
import { AddressPayloadType } from 'types';
import {
  convertAllServicesToOptions,
  convertAllSpecialistsToOptions,
  filterBySelectedService,
  filterBySelectedSpecialist,
  getTiming,
} from '../helpers';
import { AppointmentFormFields } from './EditAppointment.definitions';
import { TimeSection } from './TimeSection';

type Props = {
  readOnly?: boolean;
  editMode: boolean;
  onDelete: () => void;
  onEdit: () => void;
  onApply: () => void;
  company: AddressPayloadType;
};

export const EditService = ({ editMode, onDelete, onEdit, onApply, company, readOnly }: Props) => {
  const { t } = useTranslation();
  const { Start, End, Service, Specialist, AppointmentDate } = AppointmentFormFields;
  const { values, errors, setFieldValue } = useFormikContext<AppointmentFormFields>();

  const currency = useAppSelector(selectCurrency);

  const specialistList = convertAllSpecialistsToOptions(company?.orgSpecialist ?? []);
  const serviceList = convertAllServicesToOptions(company?.orgService ?? [], currency, t);

  const filteredSpecialists = filterBySelectedService(specialistList, values[Service]);
  const filteredServices = filterBySelectedSpecialist(serviceList, values[Specialist], company?.orgSpecialist);

  const selectedService = serviceList.find(item => item.id === values[Service]);
  const selectedSpecialist = specialistList.find(item => item.id === values[Specialist]);

  const handleDeleteClick = async () => {
    await setFieldValue(Start, '');
    await setFieldValue(End, '');
    await setFieldValue(Service, '');
    await setFieldValue(Specialist, '');
    await setFieldValue(AppointmentDate, moment().format('DD.MM.YYYY'));
    onDelete();
  };

  return editMode ? (
    <Flex flexDirection="column" gap="16px">
      <FormikInput
        id={Service}
        name={Service}
        iconLeft={<SearchIcon />}
        design="white"
        searchInput
        placeholder={t('calendar.newAppointmentSidebar.serviceSearch')}
        currentOption={filteredServices.findIndex(item => item.id === values[Service])}
        options={filteredServices}
        onSelect={(index: number) => setFieldValue(Service, filteredServices[index].id)}
        onDeleteIconClick={() => setFieldValue(Service, '')}
        type="service"
      />
      <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.appointment')}</H6>
      <TimeSection company={company} />
      <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.specialist')}</H6>
      <FormikInput
        id={Specialist}
        name={Specialist}
        placeholder={t('calendar.newAppointmentSidebar.specialistSearch')}
        iconLeft={<SearchIcon />}
        design="white"
        searchInput
        currentOption={filteredSpecialists.findIndex(item => item.id === values[Specialist])}
        options={filteredSpecialists}
        onSelect={(index: number) => setFieldValue(Specialist, filteredSpecialists[index].id)}
        onDeleteIconClick={() => setFieldValue(Specialist, '')}
        type="client"
      />
      <Flex width="100%" justifyContent="space-between" gap="16px">
        <Button width="100%" design="secondary" type="button" onClick={handleDeleteClick}>
          {t('button.clear')}
        </Button>
        <Button
          width="100%"
          type="button"
          disabled={
            errors[Start] ||
            errors[End] ||
            !values[Start] ||
            !values[End] ||
            !values[Service] ||
            !values[Specialist] ||
            !values[AppointmentDate]
          }
          onClick={onApply}
        >
          {t('button.apply')}
        </Button>
      </Flex>
    </Flex>
  ) : (
    <ServiceCard
      timing={`${getTiming(values[Start], values[End])} ${t('time.min')}`}
      service={selectedService?.title ?? ''}
      price={selectedService?.price ?? ''}
      specialist={selectedSpecialist?.name ?? ''}
      avatarUrl={selectedSpecialist?.avatarUrl ?? ''}
      onDeleteClick={handleDeleteClick}
      onEditClick={onEdit}
      isInfoMode={readOnly}
    />
  );
};
