import { TFunction } from 'react-i18next';
import has from 'lodash/has';
import * as Yup from 'yup';

export enum NewClientFormFields {
  Name = 'name',
  Surname = 'surname',
  Number = 'number',
  Region = 'code',
}

export type NewClientForm = {
  [NewClientFormFields.Name]: string;
  [NewClientFormFields.Surname]: string;
  [NewClientFormFields.Region]: string;
  [NewClientFormFields.Number]: string;
};

export const initialValues: NewClientForm = {
  [NewClientFormFields.Name]: '',
  [NewClientFormFields.Surname]: '',
  [NewClientFormFields.Region]: '+972',
  [NewClientFormFields.Number]: '',
};

export const newClientValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [NewClientFormFields.Name]: Yup.string()
      .trim()
      .required(t('validation.notEmpty'))
      .min(2, t('validation.minLength2'))
      .max(50, t('validation.maxLength50')),
    [NewClientFormFields.Surname]: Yup.string()
      .trim()
      .required(t('validation.notEmpty'))
      .min(2, t('validation.minLength2'))
      .max(50, t('validation.maxLength50')),
    [NewClientFormFields.Number]: Yup.string()
      .required(t('validation.notEmpty'))
      .typeError(t('validation.wrongPhone'))
      .matches(/^\d{7,}$/, t('validation.enterValidPhone')),
  }).test(
    'code and phone',
    t('validation.codeAndNumber'),
    value => has(value, NewClientFormFields.Number) && has(value, NewClientFormFields.Region),
  );
