import { ClientFormFields } from 'page/Clients/components/ClientSidebar/ClientForm.definitions';
import { Currency } from '../constants';
import { BaseAppointment, TitleType } from './appointment';
import { GenderType, ImageType, PaymentType, Person, TextWithTranslations } from './general';
import { Client } from './organisation';

export enum ClientActions {
  None = 'NONE',
  Edit = 'EDIT_CLIENT',
  Add = 'ADD_CLIENT',
}

export type UpdateClientParams = {
  [ClientFormFields.Image]?: ImageType | null;
  [ClientFormFields.Name]?: string;
  [ClientFormFields.Surname]?: string;
  [ClientFormFields.DateOfBirth]?: string;
  [ClientFormFields.Gender]?: GenderType;
  [ClientFormFields.IDNumber]?: string | null;
  [ClientFormFields.AdditionalCode]?: string;
  [ClientFormFields.AdditionalNumber]?: string;
  [ClientFormFields.AdditionalInfo]?: string;
  [ClientFormFields.Email]?: string;
  [ClientFormFields.ShowAdditionalInfo]?: boolean;
  [ClientFormFields.AppointmentNotifications]?: boolean;
  [ClientFormFields.DiscountNotifications]?: boolean;
  [ClientFormFields.MarketingNotifications]?: boolean;
};

export type CreateClientParams = {
  [ClientFormFields.Name]: string;
  [ClientFormFields.Surname]: string;
  [ClientFormFields.Code]: string;
  [ClientFormFields.Number]: string;
  orgId?: string;
} & UpdateClientParams;

export type Rate = {
  reviewCount: number;
  rating: number;
};

export type ClientInfo = Rate & {
  totalSales: number;
  totalBookings: number;
  completedApp: number;
  cancelledApp: number;
  noShowApp: number;
};

export type ClientStateType = {
  clients: Client[];
  currentClient: Client;
  information: ClientInfo;
  isLoading: boolean;
  type: ClientActions | null;
};

export type ClientReviewPayload = {
  appointmentId: string;
  headOrgSpecId: string;
  headOrgClientId: string;
  summary: string;
  score: number;
};

export type ClientPayments = {
  id: string;
  date: string;
  price: string;
  clientName: string;
  currency: Currency;
  service: TextWithTranslations;
  specialist?: string;
  paymentMethod: PaymentType;
};

export type ClientAppointment = BaseAppointment & {
  orgService: {
    id: string;
    price: number | string;
    headOrgService: TextWithTranslations;
  };
};

export type ClientReviews = {
  id: string;
  author: Person;
  date: string;
  spec: Person;
  service: TitleType & { createdAt: string };
  score: number;
  summary: string;
};

export type ClientOption = {
  id: string | null;
  name: string;
  avatarUrl: ImageType | null | undefined;
  phone: string | null;
  email: string;
  idNumber: string | null | undefined;
};
