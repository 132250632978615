import styled from 'styled-components';
import { LinkBasicStyles, Flex } from '@beauty/beauty-market-ui';

export const TabName = styled(Flex)<{ active: boolean }>`
  ${LinkBasicStyles()}
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  width: 100%;

  ${({ theme }) => `color: ${theme.colors.grey.dark};`}
  ${({ active, theme }) =>
    active &&
    `
    border-bottom: 2px solid ${theme.colors.blue.standard};
    color: ${theme.colors.blue.standard};
  `}
  &:hover {
    ${({ active, theme }) => `
      border-bottom: 2px solid ${theme.colors.blue.standard};
      color: ${active ? theme.colors.blue.standard : theme.colors.black.standard};
    `}
  }
`;
