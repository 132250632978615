import styled from 'styled-components';
import { Flex, colors, Button, Div, mediaQueries, BodyLarge } from '@beauty/beauty-market-ui';
import { NavTag } from 'components';

export const Wrapper = styled(Flex)<{ delta?: string }>`
  flex-direction: column;
  width: 100%;
  max-height: ${({ delta }) => `calc(100vh - ${delta || '180px'})`};
  padding: 16px 8px;
  box-sizing: border-box;
  background-color: ${colors.white.standard};
  border-radius: 10px;

  & > div:nth-of-type(3) {
    max-width: 100%;
  }

  ${mediaQueries.md} {
    padding: 24px 24px 8px;
    border-radius: 0 0 16px 16px;
  }
`;

export const StyledButton = styled(Button)`
  svg path {
    stroke-width: 2 !important;
  }
`;

export const Container = styled(Flex)`
  width: 100%;
  padding: 8px 0 8px;
  align-items: center;
  background-color: ${colors.white.standard};
  gap: 16px;
  box-sizing: border-box;
  cursor: pointer;
  * {
    cursor: pointer !important;
  }

  ${mediaQueries.md} {
    padding: 16px 0 16px;
  }
`;

export const WrapperInput = styled(Div)`
  width: 100%;
  margin-left: 8px;
  @media (max-width: 395px) {
    width: 70%;
  }
`;

export const FormWrapper = styled(Flex)`
  flex-direction: column;
  padding-top: 16px;
  gap: 16px;
`;

export const InputWrapper = styled(Flex)`
  width: 100%;
  margin-bottom: 8px;
  & > div {
    width: 100%;
    max-width: 100%;
  }
  svg {
    width: 16px;
    height: 16px;
  }
  input {
    margin: 0 0 0 4px;
  }
`;

export const PopupWrapper = styled(Flex)`
  * {
    overflow-y: visible !important;
  }
`;

export const PageHeader = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  padding: 16px 8px;
  margin-top: 1px;
  background-color: ${({ theme }) => theme.colors.white.standard};

  ${mediaQueries.md} {
    border-radius: 16px 16px 0 0;
    padding: 24px 24px 8px 24px;
    margin-top: 0;
  }
`;

export const TabSection = styled(Div)`
  border-radius: 16px;
  padding: 16px 8px;
  margin-top: 1px;
  margin-bottom: 1px;
  background-color: ${({ theme }) => theme.colors.white.standard};

  ${mediaQueries.md} {
    gap: 16px;
    border-radius: 0;
    padding: 8px 24px 24px 24px;
    margin-top: 0;
  }
`;

export const TabItem = styled(NavTag)`
  flex: 0 0 100%;
  max-width: 100%;
  counter-increment: slide;
  position: relative;
`;

export const AccordionWrapper = styled(Flex)<{ last: boolean }>`
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  background-color: ${({ theme }) => theme.colors.white.standard};

  margin-bottom: 1px;
  border-radius: 16px;
  padding: 16px 8px;

  ${mediaQueries.md} {
    padding: 24px;
    border-radius: ${({ last }) => (last ? ' 0 0 16px 16px' : '0')};
  }
`;

export const StyledBodyLarge = styled(BodyLarge)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 55vw;

  ${mediaQueries.md} {
    width: 40vw;
  }
`;
