import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { ExtendedSearchInput, getMinWidthMediaQuery, H5, H6, Scroll, useMediaQuery } from '@beauty/beauty-market-ui';
import LogoutImage from 'assets/exit-image.png';
import { Loader, Stub } from 'components';
import { useDebounce, useEndScroll, useGetClientPayments } from 'hooks';
import { InputWrapper, Wrapper } from '../../style';
import { PaymentsList } from './PaymentsList';

export const Payments = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [query, setSearch] = useState('');

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);
  const debouncedValue = useDebounce(query);

  const [data, isLoading] = useGetClientPayments(id, page, 10, debouncedValue);

  const getMore = useCallback(() => {
    page < data.totalPages && setPage(page + 1);
  }, [setPage, data.totalPages, page]);

  const scrollRef = useEndScroll(getMore);

  const handleReset = () => {
    setSearch('');
    setPage(1);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPage(1);
  };

  return (
    <Wrapper delta={isDesktop ? '320px' : '400px'}>
      {isDesktop ? (
        <H5 mb="24px">{t('clients.profile.payments.payments')}</H5>
      ) : (
        <H6 mb="24px">{t('clients.profile.payments.payments')}</H6>
      )}
      <InputWrapper>
        <ExtendedSearchInput
          onlyService
          placeholder={t('clients.profile.payments.search')}
          maxWidth="100%"
          onChange={handleSearch}
          onCrossClick={handleReset}
          zIndex={1}
        />
      </InputWrapper>
      {!isLoading && isEmpty(data.payments) && (
        <Stub
          title={t('clients.profile.payments.noPayments')}
          src={LogoutImage}
          alt={t('clients.profile.payments.noPayments')}
          margin="auto"
        />
      )}
      <Scroll ref={scrollRef}>
        <PaymentsList payments={data.payments} />
        {isLoading && <Loader />}
      </Scroll>
    </Wrapper>
  );
};
