import { TFunction } from 'react-i18next';
import isArray from 'lodash/isArray';
import moment, { Moment } from 'moment';
import { AnyObject, Nullable } from 'tsdef';
import * as Yup from 'yup';
import { AppointmentStatus } from '@beauty/beauty-market-ui';
import {
  getFormattedCurrency,
  getFullName,
  getHoursAndMinutesForUserLocale,
  getMarkedTimeslots,
  timeToSlotNumber,
} from 'helpers/utils';
import { TimeItemType } from 'hooks/useTimeList';
import {
  AppointmentByIdResponse,
  Client,
  ClientOption,
  OrganisationServiceResponse,
  OrganisationSpecialistResponse,
  SpecParams,
  WorkDayType,
  WorkTimeslot,
} from 'types';
import { AppointmentPrefilledData, BreakType, GoogleEventType, ServiceOption } from 'types/appointment';
import { SpecialistEvents } from 'types/event';
import { getSelectedLanguage, SpecialistsRoles } from '../../../constants';
import { useAppSelector } from '../../../store/hooks';
import { selectAppointments } from '../../../store/redux-slices/appointmentsSlice';
import { MIN_DURATION, MINUTES_PER_SLOT } from '../constant';
import { BreakFormFields } from './BreakSidebar/BreakSidebar.definitions';
import { AppointmentFormFields } from './NewAppointmentSidebar/AppointmentSidebar.definitions';

export const statusList = [
  {
    item: 'pending',
    disabled: false,
    status: AppointmentStatus.PENDING,
  },
  {
    item: 'confirmed',
    disabled: false,
    status: AppointmentStatus.CONFIRMED,
  },
  {
    item: 'waiting',
    disabled: false,
    status: AppointmentStatus.WAITING,
  },
  {
    item: 'inprogress',
    disabled: false,
    status: AppointmentStatus.INPROGRESS,
  },
  {
    item: 'completed',
    disabled: false,
    status: AppointmentStatus.COMPLETED,
  },
  {
    item: 'no_show',
    disabled: false,
    status: AppointmentStatus.NOSHOW,
  },
];

export const convertAllServicesToOptions = (
  allServices: OrganisationServiceResponse[] | null,
  currency: Nullable<string>,
  t: TFunction<'translation', undefined>,
) => {
  const currentLanguage = getSelectedLanguage();

  return allServices
    ? allServices.map(service => ({
        id: service.id,
        title: service.title.text,
        description: getHoursAndMinutesForUserLocale(Number(service.duration), currentLanguage),
        price:
          service.price === null
            ? t('settings.services.nullPrice')
            : getFormattedCurrency(Number(service.price), currency, currentLanguage),
        duration: service.duration,
        // oldPrice: '$400', // We don't have this data in a response at the moment
      }))
    : [];
};

export const filterServicesBySpecialist = (
  services: ServiceOption[],
  specialist: string,
  orgSpecialists: OrganisationSpecialistResponse[] | null,
) =>
  services.flatMap(service => {
    if (specialist && orgSpecialists) {
      const spec = orgSpecialists.find(item => item.orgSpecId === specialist);
      if (spec && spec.serviceIds.includes(service.id)) {
        return service;
      }
      return [];
    }
    return service;
  });

export const convertAllSpecialistsToOptions = (allSpecialists: OrganisationSpecialistResponse[] | null) =>
  allSpecialists
    ? allSpecialists.map(specialist => ({
        id: specialist.orgSpecId,
        name: `${specialist.name} ${specialist.surname}`,
        avatarUrl: specialist.avatarUrl,
        phone: `${specialist.code} ${specialist.number}`,
        serviceIds: specialist.serviceIds,
      }))
    : [];

export const filterSpecialistsByService = (specialists: SpecParams, service: string) =>
  specialists.filter(specialist => (service ? specialist.serviceIds.includes(service) : true));

export const convertAllClientsToOptions = (
  allClients: Client[] | null,
  showPhone: boolean,
  isMobile: boolean,
  t: TFunction<'translation', undefined>,
) => {
  if (!allClients) return [];
  const options: ClientOption[] = allClients.map(client => ({
    id: client.id,
    name: `${client.name} ${client.surname}`,
    avatarUrl: client.avatar,
    phone: showPhone && client.number ? `${client.code} ${client.number}` : null,
    email: `${client.email}`,
    idNumber: client?.idNumber,
  }));
  if (isMobile)
    options.unshift({
      id: null,
      name: t('calendar.newAppointmentSidebar.addNewClient'),
      avatarUrl: undefined,
      phone: null,
      email: '',
      idNumber: undefined,
    });
  return options;
};

const getTimeOptions = (startHour: number, startMinutes: number, endHour: number) => {
  const timeList: TimeItemType[] = [];
  const minutesStep = 15;

  for (let hour = startHour; hour <= endHour; hour += 1) {
    const formattedHour = hour.toString().padStart(2, '0');
    const initialMinutes = hour === startHour ? startMinutes : 0;

    for (let minute = initialMinutes; minute < 60; minute += minutesStep) {
      const formattedMinute = minute.toString().padStart(2, '0');
      const time24Hour = `${formattedHour}:${formattedMinute}`;

      timeList.push({
        id: `${hour * 60 + minute}`,
        item: time24Hour,
        hour,
        minute,
        disabled: false,
      });

      if (hour === endHour) {
        break;
      }
    }
  }
  return timeList;
};

const getRoundedMinutes = (currentMinutes: number) => Math.ceil(currentMinutes / 15) * 15;

export const getTimeDropdownOptions = (date: string, organisationWorktime: WorkDayType[] | null) => {
  const currentDate = moment();
  const selectedDate = moment(date, 'DD.MM.YYYY');
  const selectedDay = selectedDate.isoWeekday();
  const isToday = currentDate.isSame(selectedDate, 'day');
  let timeOptions: TimeItemType[] = [];

  const selectedDayWorkTime = organisationWorktime ? organisationWorktime[selectedDay - 1] : null;

  if (selectedDayWorkTime) {
    const organisationStartTime = moment(selectedDayWorkTime.start, 'HH:mm');
    const organisationEndTime = moment(selectedDayWorkTime.end, 'HH:mm');

    const initialStartOptionHour = isToday ? currentDate.get('hour') : organisationStartTime.get('hour');
    const initialStartOptionMinutes = isToday
      ? getRoundedMinutes(currentDate.get('minute'))
      : organisationStartTime.get('minute');

    const initialEndOptionHour = organisationEndTime.get('hour');

    timeOptions = getTimeOptions(initialStartOptionHour, initialStartOptionMinutes, initialEndOptionHour);
  }

  return timeOptions;
};

export const isWorkday = (date: Date, organisationWorkTime: WorkDayType[] | null) => {
  const day = date.getUTCDay();

  if (organisationWorkTime) {
    return organisationWorkTime[day].isWorkDay;
  }

  return true;
};

const getTime = (date?: string | Date) => moment(date).format('HH:mm');
export const getDate = (date?: string | Date) => moment(date).format('DD.MM.YYYY');

export const getDefaultDate = (date: string | Date) =>
  moment(date).isBefore(moment(), 'day') ? getDate() : getDate(date);

export const getEndTimeIndex = (startIndex: number, timeList: TimeItemType[], duration?: string | number) => {
  const difference = duration && +duration > 0 ? duration : MIN_DURATION;
  const endTime = Number(timeList[startIndex].id) + Number(difference);
  const endIndex = timeList.findIndex(time => Number(time.id) === endTime);
  return endIndex !== -1 ? endIndex : startIndex;
};

export const getScrollTo = (
  date: string | Date,
  timeList: TimeItemType[],
  organisationWorkTime: WorkDayType[] | null,
  duration?: string | number,
  startTime?: number,
) => {
  // 27 - 06-45
  // 28 - 07-00
  const defaultValues = { preScrolledToStart: 27, preScrolledToEnd: 28 };
  const isToday = moment(date).isSame(moment(), 'day');

  if (isToday) {
    const now = moment().hours() * 60 + moment().minutes();
    const roundedNow = Math.round((now + 15) / 15) * 15;
    const startTimeIndex = timeList.findIndex(time => Number(time.id) === roundedNow);
    const startIndex = startTimeIndex !== -1 ? startTimeIndex : defaultValues.preScrolledToStart;
    return {
      preScrolledToStart: startIndex,
      preScrolledToEnd: startIndex !== timeList.length - 1 ? startIndex + 1 : 0,
    };
  }

  if (!organisationWorkTime) {
    return defaultValues;
  }

  const dayOfWeek = moment(date).format('dddd').toUpperCase();
  const workDay = organisationWorkTime.find(day => day.dayOfWeek === dayOfWeek && day.isWorkDay);

  if (workDay) {
    const startIndex = startTime || timeList.findIndex(time => time.item === workDay.start);
    const endIndex = getEndTimeIndex(startIndex, timeList, duration);

    return {
      preScrolledToStart: startIndex,
      preScrolledToEnd: endIndex,
    };
  }

  return defaultValues;
};

export const getPreselectedValues = (
  selectedWeekday: string,
  appointmentData: AppointmentByIdResponse | GoogleEventType | BreakType | null,
  newAppointmentData: AppointmentPrefilledData | null,
  organisationClients: Client[] | null,
  organisationServices: OrganisationServiceResponse[] | null,
  organisationSpecialists: OrganisationSpecialistResponse[] | null,
  organisationWorkTime: WorkDayType[] | null,
  timeList: TimeItemType[],
  dateFromMenu: string | null,
  isGoogle?: boolean,
) => {
  const { selectedSpecialist, selectedClient } = useAppSelector(selectAppointments);
  const clients = organisationClients ?? [];

  const appointmentStart = appointmentData
    ? getTime(appointmentData.start)
    : newAppointmentData && getTime(newAppointmentData.start);
  let appointmentEnd = appointmentData && getTime(appointmentData.end);
  if (appointmentData && new Date(appointmentData?.start).getDate() !== new Date(appointmentData.end).getDate())
    appointmentEnd = '24:00';

  // TODO Update if start time is in another day
  const appointmentDate = appointmentData
    ? getDate(appointmentData?.start)
    : (newAppointmentData && getDate(newAppointmentData.start)) ||
      (dateFromMenu && getDefaultDate(dateFromMenu)) ||
      getDefaultDate(selectedWeekday);

  const preselectedStart = appointmentStart ? timeList?.findIndex(time => time.item === appointmentStart) : -1;

  const preselectedEnd = appointmentEnd
    ? timeList?.findIndex(time => time.item === appointmentEnd)
    : (newAppointmentData &&
        timeList?.findIndex(time => time.item === appointmentStart) &&
        timeList.findIndex(time => time.item === appointmentStart) + 1) ||
      -1;
  const preselectedDate = appointmentDate;

  const preSelectedClient =
    appointmentData && !isGoogle
      ? clients.findIndex(client => client.id === (appointmentData as AppointmentByIdResponse).clientId)
      : clients.findIndex(client => client.headOrgClientId === selectedClient);

  const preSelectedServiceData =
    appointmentData && !isGoogle && organisationServices
      ? organisationServices.find(service => service.id === (appointmentData as AppointmentByIdResponse).orgServId)
      : undefined;

  const { id: preSelectedService = '', duration: preSelectedDuration = -1 } = preSelectedServiceData || {};

  const preSelectedSpecialist =
    appointmentData?.orgSpecId ||
    newAppointmentData?.orgSpecialist ||
    selectedSpecialist ||
    (organisationSpecialists?.length === 1 && organisationSpecialists[0].orgSpecId);

  const { preScrolledToStart, preScrolledToEnd } = getScrollTo(
    selectedWeekday,
    timeList,
    organisationWorkTime,
    preSelectedDuration,
  );

  return {
    preselectedStart,
    preselectedEnd,
    preselectedDate,
    preSelectedClient,
    preSelectedService,
    preSelectedSpecialist,
    preScrolledToStart,
    preScrolledToEnd,
  };
};

const isTimeAfterCurrent = (date, time) => {
  const dateTime = moment(`${date} ${time}`, 'DD.MM.YYYY HH:mm');
  return dateTime.isAfter(moment(), 'minute');
};

const isEndAfterStart = (startTime, endTime) => {
  const startMoment = moment(startTime, 'HH:mm');
  const endMoment = moment(endTime, 'HH:mm');
  return endMoment.isAfter(startMoment, 'minute');
};

export function compareBreakStartAndNow(this: Yup.TestContext<AnyObject>, start: string | undefined) {
  const date = this.parent[BreakFormFields.BreakDate];
  return start ? isTimeAfterCurrent(date, start) : true;
}

export function compareBreakStartAndEnd(this: Yup.TestContext<AnyObject>, start: string | undefined) {
  const end = this.parent[BreakFormFields.End];
  return start && end ? isEndAfterStart(start, end) : true;
}

export function compareBreakEndAndNow(this: Yup.TestContext<AnyObject>, end: string | undefined) {
  const date = this.parent[BreakFormFields.BreakDate];
  return end ? isTimeAfterCurrent(date, end) : true;
}

export function compareBreakEndAndStart(this: Yup.TestContext<AnyObject>, end: string | undefined) {
  const start = this.parent[BreakFormFields.Start];
  return start && end ? isEndAfterStart(start, end) : true;
}

export function compareAppointmentStartAndNow(this: Yup.TestContext<AnyObject>, start: string | undefined) {
  const date = this.parent[AppointmentFormFields.AppointmentDate];
  return start ? isTimeAfterCurrent(date, start) : true;
}

export function compareAppointmentStartAndEnd(this: Yup.TestContext<AnyObject>, start: string | undefined) {
  const end = this.parent[AppointmentFormFields.End];
  return start && end ? isEndAfterStart(start, end) : true;
}

export function compareAppointmentEndAndNow(this: Yup.TestContext<AnyObject>, end: string | undefined) {
  const date = this.parent[AppointmentFormFields.AppointmentDate];
  return end ? isTimeAfterCurrent(date, end) : true;
}

export function compareAppointmentEndAndStart(this, end) {
  const start = this.parent[AppointmentFormFields.Start];
  return start && end ? isEndAfterStart(start, end) : true;
}

export const getActualPrice: (currencySign: string, price: string | undefined, updatedPrice: string) => string = (
  currencySign,
  price,
  updatedPrice,
) => (updatedPrice ? `${currencySign}${updatedPrice}` : `${currencySign}${price}`);

const setEndNumber: (day: WorkDayType) => number | null = day => {
  if (!day.isWorkDay) return null;
  if (day.end !== '24:00') return timeToSlotNumber(moment(`2013-02-08 ${day.end}`)) - 1;
  return 95;
};

export const getAllWorkTimeslots: (
  organisationWorkTime: WorkDayType[] | null,
) => WorkTimeslot[] | undefined = organisationWorkTime =>
  organisationWorkTime?.map(day => ({
    dayOfWeek: day.dayOfWeek,
    slotsNumber: day.isWorkDay
      ? Math.trunc(
          // TODO: remove 2013-02-08 frome code
          moment(`2013-02-08 ${day.end}`).diff(moment(`2013-02-08 ${day.start}`), 'minute') / MINUTES_PER_SLOT,
        ) - 1
      : 0,
    startNumber: day.isWorkDay ? timeToSlotNumber(moment(`2013-02-08 ${day.start}`)) : null,
    endNumber: setEndNumber(day),
  }));

export const getSeparateBySpecialistEvents: (
  specialists: OrganisationSpecialistResponse[] | null,
  organisationWorkTime: WorkDayType[] | null,
  date: Moment,
) => SpecialistEvents[] | undefined = (specialists, organisationWorkTime, date) => {
  const allWorkTimeslots: WorkTimeslot[] | undefined = getAllWorkTimeslots(organisationWorkTime);

  const workTimeslot = isArray(allWorkTimeslots)
    ? (allWorkTimeslots.find(day => day.dayOfWeek === moment(date).format('dddd').toUpperCase()) as WorkTimeslot)
    : { startNumber: null, endNumber: null };

  const events = specialists?.reduce((res, spec) => {
    if (spec.role === SpecialistsRoles.SPECIALIST)
      // TODO: refactor events structure to avoid mistakes if two ore more specs have the same name and surname
      res[getFullName(spec)] = {
        events: [],
        timeslots: Array((24 * 60) / MINUTES_PER_SLOT)
          .fill(null)
          .map((_, index) =>
            workTimeslot?.startNumber !== null &&
            workTimeslot?.endNumber !== null &&
            workTimeslot?.startNumber <= index &&
            workTimeslot?.endNumber >= index
              ? false
              : null,
          ),
      };
    return res;
  }, []);

  events &&
    Object.keys(events).forEach(spec => {
      events[spec] = {
        events: events[spec].events,
        timeslots: getMarkedTimeslots(events[spec].events, events[spec].timeslots),
      };
    });
  return events;
};

export const getNumberOfEvents: (events: SpecialistEvents[] | undefined) => {
  total: number;
  totalWithoutBreaks: number;
} = events => {
  let total = 0;
  let totalWithoutBreaks = 0;
  events &&
    Object.keys(events).forEach(spec => {
      total += events[spec].events.length;
      totalWithoutBreaks += events[spec].events.filter(event => !event.resource.isBreak).length;
    });
  return { total, totalWithoutBreaks };
};
