import { ReactNode, useEffect, useState } from 'react';
import { DownIcon, Flex, getMinWidthMediaQuery, H5, H6, Scroll, UpIcon, useMediaQuery } from '@beauty/beauty-market-ui';
import { useEndScroll } from 'hooks';
import { AccordionWrapper } from '../../style';

type Props = {
  title: string;
  defaultState?: boolean;
  children?: ReactNode;
  maxHeight?: string;
  onEnd?: () => void;
  type: 'past' | 'future';
};

export const Accordion = ({ title, children, onEnd, type, defaultState = false, maxHeight = '275px' }: Props) => {
  const [open, setOpen] = useState(defaultState);
  const scrollRef = useEndScroll(onEnd);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  useEffect(() => {
    setOpen(defaultState);
  }, [defaultState]);

  return (
    <AccordionWrapper last={type === 'past'}>
      <Flex gap="8px" alignItems="center" cursor="pointer" onClick={() => setOpen(prev => !prev)}>
        {isDesktop ? <H5>{title}</H5> : <H6>{title}</H6>}
        {open ? <UpIcon /> : <DownIcon />}
      </Flex>
      <Scroll maxHeight={maxHeight} ref={scrollRef}>
        {open && children}
      </Scroll>
    </AccordionWrapper>
  );
};
