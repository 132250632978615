import { Avatar, BodyLarge, BodySmall, Caption, colors, Div } from '@beauty/beauty-market-ui';
import { Widget } from 'components';
import { Adaptive } from 'hoc';

export const SpecialistItem = ({ specialization, fullName, contact, avatarUrl, separator, onClick, isDesktop }) => (
  <Widget
    p="16px 0"
    title={
      <Adaptive mobile={Caption} desktop={BodySmall} color={isDesktop ? colors.black.standard : colors.grey.dark}>
        {specialization}
      </Adaptive>
    }
    subtitle={
      <Adaptive mobile={BodyLarge} desktop={BodyLarge} large>
        {fullName}
      </Adaptive>
    }
    caption={
      <Adaptive mobile={Caption} desktop={BodySmall} color={colors.grey.dark}>
        {contact}
      </Adaptive>
    }
    avatar={
      <Div alignSelf="start">
        <Avatar url={avatarUrl} anonymos="default" size="xs" />
      </Div>
    }
    separator={separator}
    onClick={onClick}
  />
);
