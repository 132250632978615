import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { SpecialistEducation } from 'types';
import { ValidEducation } from '../../../../../constants';

export enum EducationFormFields {
  School = 'school',
  Degree = 'degree',
  Field = 'field',
  Start = 'startYear',
  End = 'endYear',
  File = 'file',
  Name = 'fileName',
}

export type EducationFormType = {
  [EducationFormFields.School]: string;
  [EducationFormFields.Degree]: string;
  [EducationFormFields.Field]: string;
  [EducationFormFields.Start]: string;
  [EducationFormFields.End]: string;
  [EducationFormFields.File]: File | string | null;
  [EducationFormFields.Name]: string | null;
};

export const getInitialValues = (data?: SpecialistEducation | null) => ({
  [EducationFormFields.School]: data?.school || '',
  [EducationFormFields.Degree]: data?.degree || '',
  [EducationFormFields.Field]: data?.field || '',
  [EducationFormFields.Start]: data?.startYear ? data?.startYear.toString() : '',
  [EducationFormFields.End]: data?.endYear ? data?.endYear.toString() : '',
  [EducationFormFields.File]: data?.documentUrl ?? '',
  [EducationFormFields.Name]: data?.fileName ?? '',
});

export const schema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [EducationFormFields.School]: Yup.string()
      .required(t('validation.notEmpty'))
      .max(200, t('validation.maxLength', { count: 200 })),
    [EducationFormFields.Degree]: Yup.string().max(200, t('validation.maxLength', { count: 200 })),
    [EducationFormFields.Field]: Yup.string()
      .required(t('validation.notEmpty'))
      .max(200, t('validation.maxLength', { count: 200 })),
    [EducationFormFields.Start]: Yup.string()
      .required(t('validation.notEmpty'))
      .length(4, t('validation.availableYearFormat'))
      .test('isValidYear', t('validation.availableYears', { min: 1950, max: 2100 }), startYear => {
        if (!startYear) return false;
        const yearNumber = Number(startYear);
        return yearNumber >= 1950 && yearNumber <= 2100 && Number.isInteger(yearNumber);
      })
      .test('isStartGreaterThanEnd', t('validation.startYearGreatOrEqualEndYear'), (startYear, context) => {
        if (!startYear) return false;
        const endYear = Number(context.parent[EducationFormFields.End]);
        return endYear ? Number(startYear) <= endYear : true;
      }),
    [EducationFormFields.End]: Yup.string()
      .required(t('validation.notEmpty'))
      .length(4, t('validation.availableYearFormat'))
      .test('isValidYear', t('validation.availableYears', { min: 1950, max: 2100 }), endYear => {
        if (!endYear) return false;
        const yearNumber = Number(endYear);
        return yearNumber >= 1950 && yearNumber <= 2100 && Number.isInteger(yearNumber);
      })
      .test('isStartGreaterThanEnd', t('validation.startYearGreatOrEqualEndYear'), (endYear, context) => {
        if (!endYear) return false;
        const startYear = Number(context.parent[EducationFormFields.Start]);
        return startYear ? Number(endYear) >= startYear : true;
      }),
    [EducationFormFields.File]: Yup.mixed()
      .notRequired()
      .test('isValidFileFormat', t('validation.invalidateFormatDocument'), (file, context) => {
        if (typeof file === 'string') return true;
        const isValid = ValidEducation.includes(file?.type);
        if (!isValid) context?.createError();
        return !file || isValid;
      }),
  });
