import styled from 'styled-components';
import { mediaQueries, Flex } from '@beauty/beauty-market-ui';
import { Paper } from '../../../components';

export const AddressContentWrapper = styled(Flex)`
  flex-direction: column;
  gap: 1px;
  width: 100%;
  max-width: 100%;
  margin-bottom: 80px;

  ${mediaQueries.md} {
    // 310px address coverstack width
    // 42px gap between coverstack and section
    // need for photo gallery view
    max-width: calc(100% - 310px - 43px);
    margin-bottom: 0;
  }
`;

export const AddressListContent = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 1280px;
`;

export const IconBox = styled(Flex)`
  align-self: center;
  gap: 16px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  width: 0;
`;

export const Box = styled(Flex)<{ available: boolean }>`
  &:hover ${IconBox} {
    opacity: ${({ available }) => (available ? 1 : 0)};
    width: ${({ available }) => (available ? 'max-content' : 0)};
  }
`;

export const StyledIcon = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey.light};
  border-radius: 50%;
  margin-right: 16px;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;

  & svg,
  & path {
    width: 24px;
    height: 24px;

    stroke: ${({ theme }) => theme.colors.blue.standard};
  }
`;

export const EmployeeItemContent = styled(Flex)`
  flex-direction: column;
  width: 65vw;

  ${mediaQueries.md} {
    width: 400px;
  }
`;

export const getBorderRadius = (position: 'first' | 'middle' | 'last') => {
  switch (position) {
    case 'first':
      return '10px 10px 0 0';
    case 'last':
      return '0 0 10px 10px';

    case 'middle':
    default:
      return 0;
  }
};

export const Wrapper = styled(Paper)<{ position: 'first' | 'middle' | 'last' }>`
  flex-direction: column;
  border-radius: 10px;
  padding: 16px 8px;
  gap: 16px;

  ${mediaQueries.md} {
    border-radius: ${({ position }) => getBorderRadius(position)};
    padding: 24px;
  }
`;
