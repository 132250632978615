import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import {
  BodyLarge,
  BodySmall,
  Button,
  CardButton,
  CoverStack,
  CoverStackSpecialist,
  Flex,
  getMinWidthMediaQuery,
  NotifyStatus,
  Separator,
  useMediaQuery,
  useNotify,
  HeroBanner,
  Counter,
} from '@beauty/beauty-market-ui';
import { EditAddress, Paper, PopUp } from 'components';
import { getAddressNotifyContent, getTranslation } from 'helpers';
import { useGetUrl } from 'hooks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNewAddress, ThunkAddress } from 'store/redux-slices/addressSlice';
import { AddressAction, PublishPayloadType } from 'types';
import { getSelectedLanguage } from '../../../../../constants';
import { getAddressContent, getPublishInfo, showWarningMessages } from '../../helpers';
import { editFields } from './helpers';

export const AddressCoverStack = () => {
  const { t } = useTranslation();
  const organisation = useAppSelector(selectNewAddress);
  const { id, name, photos, categories, published } = organisation;
  const { client } = useGetUrl(`/organisation/${id}`);
  const language = getSelectedLanguage();
  const dispatch = useAppDispatch();
  const notify = useNotify();

  const [action, setAction] = useState<AddressAction>(AddressAction.None);
  const [isSubmitting, setSubmitting] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const topCategory = isArray(categories)
    ? categories.map(category => getTranslation(category.parent.title, language))
    : [];

  const handleTogglePublished = () => {
    published ? setAction(AddressAction.Unpublish) : setAction(AddressAction.Publish);
  };

  const handleClose = useCallback(() => setAction(AddressAction.None), []);

  const handleShowPreview = () => window.open(client);

  const handleEditCoverStack = () => {
    setAction(AddressAction.EditAddress);
  };

  const togglePublished = () => {
    setSubmitting(true);

    const params = {
      id,
      published: !published,
    };

    dispatch(ThunkAddress.updatePublish(params))
      .unwrap()
      .then((res: PublishPayloadType) => {
        res.success
          ? notify(getAddressNotifyContent(NotifyStatus.SUCCESS, action))
          : showWarningMessages(res.publishedLog, notify, t);
      })
      .finally(() => {
        setSubmitting(false);
        setAction(AddressAction.None);
      });
  };

  const header = (
    <CoverStackSpecialist
      imgUrl={photos.mainPhoto}
      specialistType={uniq(topCategory).join(', ')}
      specialistLabel={name || ''}
      button={<CardButton type="edit" onClick={handleEditCoverStack} />}
    />
  );

  const content = (
    <Flex flexDirection="column" p={isDesktop ? '0' : '0px 8px 16px'}>
      {getAddressContent(organisation, t).map((item, idx, arr) => (
        <Fragment key={item.id}>
          <Flex flexDirection="column" p="16px 0">
            <BodySmall>{item.label}</BodySmall>
            <BodyLarge truncated title={item.value}>
              {item.value}
            </BodyLarge>
          </Flex>
          {idx !== arr.length - 1 && <Separator />}
        </Fragment>
      ))}
      <Button size="small" onClick={handleTogglePublished} mt="10px">
        {published ? t('address.unpublishAddress') : t('address.publishAddress')}
      </Button>
      <Button size="small" design="quaternary" onClick={handleShowPreview} mt="10px">
        {published ? t('address.goToYourPage') : t('address.showPreview')}
      </Button>
    </Flex>
  );

  return (
    <>
      {isDesktop ? (
        <CoverStack width="310px" header={header} content={content} />
      ) : (
        <Paper borderRadius="10px" flexDirection="column" p="0" mt="1px">
          <HeroBanner images={[photos.mainPhoto]} />
          {content}
        </Paper>
      )}
      {action === AddressAction.EditAddress && <EditAddress onClose={handleClose} fields={editFields} />}
      {action === AddressAction.Publish && (
        <PopUp
          title={t('address.publishOrganisation', { name })}
          description={t('address.publishDescription')}
          confirm={t('address.publish')}
          cancel={t('button.cancel')}
          isLoading={isSubmitting}
          onSubmit={togglePublished}
          onClose={handleClose}
        >
          <Flex gap="8px" flexDirection="column" m={isDesktop ? '0 0 0 40px' : '0 auto'}>
            {getPublishInfo(t).map(item => (
              <Flex key={item} gap="8px" alignItems="center">
                <Counter />
                <BodyLarge>{item}</BodyLarge>
              </Flex>
            ))}
          </Flex>
        </PopUp>
      )}
      {action === AddressAction.Unpublish && (
        <PopUp
          title={t('address.unpublishOrganisation', { name })}
          description={t('address.unpublishDescription', { name })}
          confirm={t('address.unpublish')}
          cancel={t('button.cancel')}
          isLoading={isSubmitting}
          onSubmit={togglePublished}
          onClose={handleClose}
        />
      )}
    </>
  );
};
