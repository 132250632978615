import styled, { css } from 'styled-components';
import { colors, Flex, Caption } from '@beauty/beauty-market-ui';
import { cardTooltipWidth } from '../../../constants';

export const Wrapper = styled(Flex)<{ leftOffset?: number; isSingleAppointment: boolean }>`
  .react-tooltip {
    width: ${cardTooltipWidth}px;
    height: fit-content;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 16px;
    background-color: ${colors.black.normal};
    opacity: 0.8;
    z-index: 5;
    ${({ isSingleAppointment }) => isSingleAppointment && 'left: 7px!important;'}
    * {
      color: ${colors.white.standard};
    }

    hr {
      border-top-color: ${colors.grey.darker};
    }

    path {
      fill: ${colors.white.standard};
    }

    .react-tooltip-arrow {
      ${({ leftOffset }) =>
        leftOffset !== -4
          ? css`
              left: ${leftOffset}px!important;
              right: auto !important;
            `
          : css`
              top: 50% !important;
            `};
      ${({ isSingleAppointment }) => isSingleAppointment && 'left: -4px;'}
    }
  }
`;

export const StyledCaption = styled(Caption)`
  width: min-content;
  white-space: nowrap;
`;
