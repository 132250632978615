import { createSlice, isFulfilled, isPending, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';
import { organisationAPI } from '../../helpers/organisationAPI';
import { ServiceFullType, ServiceShortType } from '../../types/organisation';
import { CategoryResponseType, ServiceStateType } from '../../types/service';
import { clearAccountState, clearCurrentData, logout } from '../actions/common';
import { createAppAsyncThunk } from '../create-app-async-thunk';
import { RootState } from '../store';
import { extractTopCategoryId, packServiceToFormData } from '../utils';

const initialState: ServiceStateType = {
  isLoading: false,
  allServices: [],
  categories: {},
  topIds: [],
  newService: {
    id: '',
    service: null,
    category: null,
    language: '',
    name: '',
    photos: [],
    description: '',
    availableFor: 'ALL',
    duration: '',
    price: null,
    photoStore: {
      save: [],
      del: [],
    },
  },
};

const fetchAllCategory = createAppAsyncThunk<CategoryResponseType[], string>('service/fetchAllCategory', async id =>
  organisationAPI.fetchAllCategory(id),
);

const fetchAllServices = createAppAsyncThunk<ServiceShortType[], string>('service/fetchAllServices', async id =>
  organisationAPI.fetchAllServices(id),
);

const fetchServiceById = createAppAsyncThunk<ServiceFullType, string>('service/fetchServiceById', async id =>
  organisationAPI.fetchServiceById(id),
);

const deleteService = createAppAsyncThunk<{ id: string }, string>('service/deleteService', async id =>
  organisationAPI.deleteService(id),
);

const createService = createAppAsyncThunk<ServiceShortType>('service/createService', async (_, { getState }) => {
  const { newService } = getState().service;
  const headOrgId = getState().organisation.head.id;
  const data = packServiceToFormData({ newService, headOrgId });
  return organisationAPI.createService(data);
});

const editService = createAppAsyncThunk<ServiceShortType>('service/updateService', async (_, { getState }) => {
  const { newService } = getState().service;
  const data = packServiceToFormData({ newService });
  if (newService.id) {
    return organisationAPI.editService({ id: newService.id, data });
  }
  throw new Error("Id doesn't exist");
});

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServiceLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    updatePrice: (state, action) => {
      state.newService.price = action.payload;
    },
    updateDuration: (state, action) => {
      state.newService.duration = action.payload;
    },
    updatePhotos: (state, action) => {
      state.newService.photos = action.payload;
    },
    deletePhoto: (state, action) => {
      if (state.newService.photoStore) {
        const index = state.newService.photoStore.save.findIndex(item => item.url === action.payload);
        if (index !== -1) {
          const photo = state.newService.photoStore.save.splice(index, 1);
          state.newService.photoStore.del.push(photo[0]);
        }
        state.newService.photos = state.newService.photos.filter(item => item !== action.payload);
      }
    },
    updateAvailableFor: (state, action) => {
      state.newService.availableFor = action.payload;
    },
    updateCategory: (state, action) => {
      state.newService.category = action.payload;
    },
    updateService: (state, action) => {
      state.newService.service = action.payload;
    },
    updateMainServiceInfo: (
      state,
      action: PayloadAction<{
        name: string;
        language: string;
        description: string;
      }>,
    ) => {
      state.newService.name = action.payload.name;
      state.newService.language = action.payload.language;
      state.newService.description = action.payload.description;
    },
    clearServiceState: () => initialState,
    clearNewService: state => {
      state.newService = initialState.newService;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllCategory.fulfilled, (state, action) => {
        state.topIds = action.payload.map(item => {
          const topId = item.serviceCategory.id;
          state.categories[topId] = item.serviceCategory;
          return topId;
        });
      })
      .addCase(fetchAllCategory.rejected, state => {
        state.categories = initialState.categories;
        state.topIds = initialState.topIds;
      })
      .addCase(createService.fulfilled, (state, action) => {
        state.allServices.push(action.payload);
        state.newService = initialState.newService;
      })
      .addCase(fetchAllServices.fulfilled, (state, action) => {
        state.allServices = sortBy(action.payload, obj => (obj.title.createdAt ? new Date(obj.title.createdAt) : null));
      })
      .addCase(fetchAllServices.rejected, state => {
        state.allServices = [];
      })
      .addCase(fetchServiceById.fulfilled, (state, action: PayloadAction<ServiceFullType>) => {
        const { id, duration, listPrice, title, categoryId: serviceId, availableFor, descr, photo } = action.payload;
        const topCategoryId = extractTopCategoryId(state.topIds, state.categories, serviceId);
        state.newService.id = id;
        state.newService.name = title.text;
        state.newService.service = serviceId;
        state.newService.category = topCategoryId;
        state.newService.price = `${listPrice}`;
        state.newService.duration = `${duration}`;
        state.newService.availableFor = availableFor;
        state.newService.description = descr.text;
        state.newService.photos = photo.map(item => item.url);
        state.newService.photoStore = { save: photo, del: [] };
      })
      .addCase(deleteService.fulfilled, (state, action) => {
        const index = state.allServices.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.allServices.splice(index, 1);
        }
      })
      .addCase(editService.fulfilled, (state, action) => {
        const index = state.allServices.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.allServices.splice(index, 1, action.payload);
        }
        state.newService = initialState.newService;
      })
      .addCase(clearAccountState, () => initialState)
      .addCase(clearCurrentData, state => {
        state.newService = initialState.newService;
      })
      .addCase(logout, () => initialState)
      .addMatcher(isPending, state => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(isRejected, state => {
        state.isLoading = false;
      });
  },
});

export const {
  deletePhoto,
  updatePhotos,
  updatePrice,
  updateCategory,
  updateService,
  updateDuration,
  updateAvailableFor,
  updateMainServiceInfo,
  clearNewService,
  clearServiceState,
  setServiceLoading,
} = serviceSlice.actions;

export const selectService = (state: RootState) => state.service;
export const selectNewService = (state: RootState) => state.service.newService;
export const selectAllServices = (state: RootState) => state.service.allServices;
export const selectPhotos = (state: RootState) => state.service.newService.photos;

export const ThunkService = {
  editService,
  createService,
  deleteService,
  fetchAllCategory,
  fetchAllServices,
  fetchServiceById,
};

export default serviceSlice.reducer;
