import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';
import { Paper } from 'components';
import { IconBox } from '../../style';

export const Container = styled(Flex)`
  align-items: start;

  &:hover ${IconBox} {
    width: max-content;
    opacity: 1;
  }
`;

export const StyledPaper = styled(Paper)<{ active: boolean }>`
  justify-content: center;
  align-items: center;
  height: 112px;
  width: auto;
  padding: 24px;
  margin-left: 4px;

  transition: all 0.3s ease;

  border: ${({ theme, active }) => `1px solid ${active ? theme.colors.blue.standard : theme.colors.grey.standard}`};

  & p {
    color: ${({ theme, active }) => (active ? theme.colors.blue.standard : theme.colors.grey.dark)};
  }

  & path {
    stroke: ${({ theme, active }) => (active ? theme.colors.blue.standard : theme.colors.grey.dark)};
  }

  &:hover {
    & p {
      color: ${({ theme }) => theme.colors.black.standard};
    }

    & path {
      stroke: ${({ theme }) => theme.colors.black.standard};
    }

    cursor: pointer;
  }
`;
