import axios from 'axios';
import { apiRequest } from '../api/api';
import { HeadOrganisationEndpoints, OrganisationEndpoints, ServicesEndpoints } from '../api/endpoints/endpoints';
import { DayType } from '../types/organisation';

export type CreateAddressParams = {
  id: string;
  timezone: string;
  name: string;
  email: string;
  description: string | null;
  address: {
    country: string;
    city: string;
    street: string;
    building: string;
    office?: string | null;
    postal?: string | null;
    lat: number;
    lng: number;
    fullAddress: string;
    description: string | null;
  };
  property: string;
  instagram?: string;
  telegram?: string;
  phone: {
    code: string;
    number: string;
  };
  orgBusinessHours: DayType[];
};

const createHead = async (data: FormData) => {
  const [error, response] = await apiRequest.post({
    endpoint: HeadOrganisationEndpoints.Root,
    data,
  });
  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const createAddress = async ({ data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.post({
    endpoint: OrganisationEndpoints.OrganisationCreate,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const updatePhotos = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: OrganisationEndpoints.Photo,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const deleteService = async (id: string) => {
  const [error, response] = await apiRequest.delete({
    endpoint: ServicesEndpoints.HeadOrganizationService,
    endpointParams: id,
  });
  if (response) {
    return { ...response.data, id };
  }
  throw new Error(String(error?.data));
};

const fetchAddress = async ({ lat, lng, language }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&language=${language}&location_type=ROOFTOP&result_type=street_address&key=${apiKey}`;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return null;
  }
};

const fetchCoordinates = async ({ country, city, building, street }) => {
  try {
    const apiKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
    const address = `${building} ${street}, ${city}, ${country}`;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address,
    )}&key=${apiKey}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    return null;
  }
};

const fetchAllCategory = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.Category,
    endpointParams: id,
  });
  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const createService = async (data: FormData) => {
  const [error, response] = await apiRequest.post({
    endpoint: ServicesEndpoints.HeadOrganizationService,
    data,
  });
  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const fetchHead = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.Root,
    endpointParams: id,
  });
  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const fetchAllServices = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.Service,
    endpointParams: id,
  });
  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const fetchAllAddresses = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.Organization,
    endpointParams: id,
  });
  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const fetchServiceById = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: ServicesEndpoints.HeadOrganizationService,
    endpointParams: id,
  });
  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const editService = async ({ id, data }: { id: string; data: FormData }) => {
  const [error, response] = await apiRequest.patch({
    endpoint: ServicesEndpoints.HeadOrganizationService,
    endpointParams: id,
    data,
  });

  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const fetchAddressById = async id => {
  const [error, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.OrganisationTest,
    endpointParams: {
      id,
      include: [
        'orgBusinessHours',
        'address',
        'photo',
        'contact',
        'category',
        'orgSpecialist',
        'orgService',
        'property',
      ].join(','),
    },
  });
  if (response) {
    return response.data;
  }
  throw new Error(String(error?.data));
};

const fetchAddressForAppointmentsById = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.OrganisationTest,
    endpointParams: {
      id,
      include: ['orgBusinessHours', 'orgSpecialist', 'orgService'].join(','),
    },
  });
  return response ? response.data : error?.data;
};

const fetchAllClients = async (id: string) => {
  const [error, response] = await apiRequest.get({
    endpoint: HeadOrganisationEndpoints.AllClients,
    endpointParams: id,
  });
  return response ? response.data : error?.data;
};

const editOrganization = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: OrganisationEndpoints.OrganisationUpdate,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const editHead = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: HeadOrganisationEndpoints.Root,
    endpointParams: id,
    data,
  });
  return response ? response?.data : rejectWithValue(error?.data);
};

const fetchServicesForEmployee = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.get({
    endpoint: OrganisationEndpoints.OrganisationServices,
    endpointParams: id,
  });
  return response ? response?.data : rejectWithValue(error?.data);
};

const editSpecialists = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: OrganisationEndpoints.OrganisationEditSpecialists,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const deleteAddress = async ({ id, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.delete({
    endpoint: OrganisationEndpoints.OrganisationDelete,
    endpointParams: id,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const updatePublish = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: OrganisationEndpoints.OrganisationUpdate,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const subscribe = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.post({
    endpoint: HeadOrganisationEndpoints.Subscribe,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const setPaymentCard = async ({ id, cardId, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: HeadOrganisationEndpoints.SetPaymentCard,
    endpointParams: { id, cardId },
    data: {},
  });
  return response ? response.data[0] : rejectWithValue(error?.data);
};

const cancelSubscription = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: HeadOrganisationEndpoints.CancelSubscription,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const activateOption = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: HeadOrganisationEndpoints.ActivateOption,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

const deactivateOption = async ({ id, data, thunkAPI }) => {
  const { rejectWithValue } = thunkAPI;
  const [error, response] = await apiRequest.patch({
    endpoint: HeadOrganisationEndpoints.DeactivateOption,
    endpointParams: id,
    data,
  });
  return response ? response.data : rejectWithValue(error?.data);
};

export const organisationAPI = {
  fetchHead,
  fetchAddress,
  updatePhotos,
  fetchCoordinates,
  fetchServiceById,
  fetchAllCategory,
  fetchAllServices,
  fetchAllAddresses,
  fetchAllClients,
  createService,
  createHead,
  createAddress,
  deleteService,
  editHead,
  editService,
  editOrganization,
  fetchAddressById,
  fetchAddressForAppointmentsById,
  fetchServicesForEmployee,
  deleteAddress,
  editSpecialists,
  updatePublish,
  subscribe,
  setPaymentCard,
  cancelSubscription,
  activateOption,
  deactivateOption,
};
