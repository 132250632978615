import { BodyLarge, Caption, colors, EditIcon, Flex, Link, Separator, TrashIcon } from '@beauty/beauty-market-ui';
import { Box, IconBox } from '../../style';

export const LanguageItem = ({ title, subtitle, onDelete, onEdit, separator }) => (
  <>
    <Box available>
      <Flex flexDirection="column">
        <Caption color={colors.grey.dark} large>
          {subtitle}
        </Caption>
        <BodyLarge large>{title}</BodyLarge>
      </Flex>
      <IconBox ml="auto">
        <Link design="gray" size="md" icon={<EditIcon />} onClick={onEdit} />
        <Link design="gray" size="md" icon={<TrashIcon />} onClick={onDelete} />
      </IconBox>
    </Box>
    {separator && <Separator m="16px 0" />}
  </>
);
