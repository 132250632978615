import { ChangeEvent, useMemo, MouseEvent } from 'react';
import { Views } from 'react-big-calendar';
import moment, { Moment } from 'moment';
import { BodySmall, colors, H6, getMinWidthMediaQuery, useMediaQuery, Caption, Flex } from '@beauty/beauty-market-ui';
import { getProgress } from 'helpers/utils';
import { EventType, OrganisationSpecialistResponse } from 'types';
import { TimeslotType } from 'types/event';
import { WorkDayType } from 'types/organisation';
import { usePluralForm } from '../../../hooks/useDynamicTranslation';
import { isToday } from '../../../page/Calendar/helpers';
import { CircleProgress } from '../CircleProgress/CircleProgress';
import { MonthKebabMenu } from '../KebabMenu/MonthKebabMenu';
import { getNumberOfEvents, getSeparateBySpecialistEvents } from '../Sidebars/helpers';
import { BodyWrapper, DateAppointment, DateContainer, DateHeader } from './style';

type MonthDateProps = {
  date: Moment;
  label: string;
  isOffRange: boolean;
  onNavigate: (date: Moment) => void;
  view: Views;
  onView: (view: Views) => void;
  events: EventType[];
  organisationWorkTime: WorkDayType[] | null;
  organisationSpecialists: OrganisationSpecialistResponse[] | null;
  setAddFromMenu: (isOpen: boolean) => void;
  setDateFromMenu: (date: string) => void;
  onCancelAll: () => void;
  isAdmin: boolean;
  isSubscription: boolean;
  onCloseDay: () => void;
  setEmptyDay: (IsEmptyDay: boolean) => void;
  setOptionsMenu: (isMenu: boolean) => void;
};

export const MonthDate = ({
  date,
  label,
  isOffRange,
  onNavigate,
  view,
  onView,
  events,
  organisationWorkTime,
  organisationSpecialists,
  setAddFromMenu,
  setDateFromMenu,
  onCancelAll,
  isAdmin,
  isSubscription,
  onCloseDay,
  setEmptyDay,
  setOptionsMenu,
}: MonthDateProps) => {
  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const typeOfDate =
    moment(date).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')
      ? 'today'
      : (moment(date).isBefore(moment()) && 'past') || 'future';

  const handleClick = () => {
    if (isMobile) {
      setDateFromMenu(moment(date).toISOString());
      setOptionsMenu(true);
    } else {
      onNavigate(date);
      onView(Views.DAY);
    }
  };

  const separateBySpecialistEvents = getSeparateBySpecialistEvents(organisationSpecialists, organisationWorkTime, date);

  events
    .filter(event => moment(event.start).isSame(moment(date), 'day'))
    .forEach(
      event =>
        separateBySpecialistEvents &&
        separateBySpecialistEvents[event.resource.specialist!.specialistName].events.push(event),
    );

  const design = isToday(date) ? 'white' : 'grey';

  const allTimeslots = useMemo(() => {
    const result: Array<Array<TimeslotType>> = [];
    separateBySpecialistEvents &&
      Object.keys(separateBySpecialistEvents).forEach(specialist => {
        result.push(separateBySpecialistEvents[specialist].timeslots);
      });
    return result;
  }, [separateBySpecialistEvents]);

  const totalEventsCount = useMemo(() => getNumberOfEvents(separateBySpecialistEvents), [separateBySpecialistEvents]);

  const { progress } = getProgress(allTimeslots);
  const phrase = usePluralForm('calendar.appointment', totalEventsCount.totalWithoutBreaks);
  const isShowKebabMenu = isAdmin && !isMobile && !moment(date).isBefore(moment(), 'day');

  const handleCloseDay = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    onCloseDay();
    setEmptyDay(!totalEventsCount.totalWithoutBreaks);
  };

  const handleCreateAppointment = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setDateFromMenu(date.toISOString());
    setAddFromMenu(true);
  };

  const handleCancelAll = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setDateFromMenu(date.toISOString());
    onCancelAll();
  };

  return (
    <DateContainer isShowDots={isSubscription && !isMobile}>
      {isMobile && (
        <Flex width="56px" height="48px" alignItems="start">
          <Caption lowline>{moment(date).format('ddd')}</Caption>
        </Flex>
      )}
      <BodyWrapper onClick={handleClick} type={typeOfDate}>
        <DateHeader>
          <CircleProgress progress={progress} design={design} showCircle={!!totalEventsCount.total}>
            <H6 color={isOffRange ? colors.grey.standard : colors.black.standard}>{label}</H6>
          </CircleProgress>
          {isShowKebabMenu && (
            <MonthKebabMenu
              onCloseDay={handleCloseDay}
              onCancelAll={handleCancelAll}
              onCreateAppointment={handleCreateAppointment}
            />
          )}
        </DateHeader>
        {!!totalEventsCount.total && (
          <DateAppointment height={view === Views.MONTH && isMobile ? '20px' : 'auto'}>
            <BodySmall large color={colors.white.standard}>
              {phrase}
            </BodySmall>
          </DateAppointment>
        )}
      </BodyWrapper>
    </DateContainer>
  );
};
