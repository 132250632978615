import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import { useTimeList } from '../../../hooks/useTimeList';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectNewAddress, setAllDay, updateDayTime } from '../../../store/redux-slices/addressSlice';
import { WeekDay } from '../../../types';

export const useSchedule = () => {
  const { t } = useTranslation();
  const timeList = useTimeList();
  const dispatch = useAppDispatch();
  const { schedule, allDayTemplate } = useAppSelector(selectNewAddress);
  const [tabIndex, setTabIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(1);
  const [isWorkDay, setIsWorkDay] = useState(false);

  const dayName = t(`daysOfWeek.${capitalize(WeekDay[tabIndex])}`);

  const handleDayTimeChange = (newStartIndex: number, newEndIndex: number, newIsWorkDay: boolean) => {
    const dayTime = {
      start: timeList[newStartIndex].item,
      end: timeList[newEndIndex].item,
      isWorkDay: newIsWorkDay,
      dayOfWeek: WeekDay[tabIndex],
    };
    dispatch(updateDayTime(dayTime));
  };

  const handleStartChange = (index: number) => {
    handleDayTimeChange(index, endIndex, isWorkDay);
    setStartIndex(index);
  };

  const handleEndChange = (index: number) => {
    handleDayTimeChange(startIndex, index, isWorkDay);
    setEndIndex(index);
  };

  const handleIsWorkDayChange = (checked: boolean) => {
    handleDayTimeChange(startIndex, endIndex, checked);
    setIsWorkDay(checked);
  };

  const handleChangeAllDays = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    if (checked) {
      const dayTime = {
        start: timeList[startIndex].item,
        end: timeList[endIndex].item,
        breakStart: null,
        breakEnd: null,
      };
      dispatch(setAllDay(dayTime));
    } else {
      dispatch(setAllDay(null));
    }
  };

  const getData = () => {
    const day = schedule.find(item => item.dayOfWeek === WeekDay[tabIndex]);

    // 28 is 07-00
    // 29 is 07-  15
    if (day) {
      const newStartIndex = timeList.findIndex(date => date.item === day.start);
      const newEndIndex = timeList.findIndex(date => date.item === day.end);
      setStartIndex(newStartIndex === -1 ? 28 : newStartIndex);
      setEndIndex(newEndIndex === -1 ? 29 : newEndIndex);
      setIsWorkDay(day.isWorkDay);
    } else {
      setStartIndex(28);
      setEndIndex(29);
      setIsWorkDay(false);
    }
  };

  useEffect(() => {
    allDayTemplate && dispatch(setAllDay(null));
  }, [startIndex, endIndex, isWorkDay, tabIndex]);

  useEffect(() => {
    getData();
  }, [tabIndex]);

  return {
    timeList,
    dayName,
    tabIndex,
    endIndex,
    startIndex,
    isWorkDay,
    setTabIndex,
    handleEndChange,
    handleStartChange,
    handleIsWorkDayChange,
    handleChangeAllDays,
    allDays: Boolean(allDayTemplate),
  };
};
