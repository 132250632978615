import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { intersection, isEmpty } from 'lodash';
import { Nullable } from 'tsdef';
import { Button, Flex, BodySmall, BottomSheet, Caption, H6, colors, Avatar } from '@beauty/beauty-market-ui';
import { checkAccess, getTranslation } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllAddresses } from 'store/redux-slices/addressSlice';
import {
  selectAppointments,
  setLoading,
  setSelectedAddress,
  setSelectedCategory,
} from 'store/redux-slices/appointmentsSlice';
import { selectSpecialist } from 'store/redux-slices/specialistSlice';
import { ColumnFlex } from 'style';
import { AccessRange, getSelectedLanguage, ServiceIcons } from '../../../../constants';
import { getAllAddressOptions, getSpecialistAddressOptions } from '../helpers';
import { FilterMobileItem } from './FilterMobileitem';
import { TextWrapper, AddressOptionWrapper, CategoryOptionWrapper } from './style';

type MobileFiltersProps = {
  isReset: boolean;
  onClose: () => void;
  addressOptions: any;
};

export const MobileFilters = ({ isReset, onClose, addressOptions }: MobileFiltersProps) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const { selectedSpecialist, selectedAddress, selectedCategory, categoryIds, categoryData, specialistData } =
    useAppSelector(selectAppointments);

  const language = getSelectedLanguage();

  const filterBySelectedSpecialist = (categoryId: string | null) => {
    if (selectedSpecialist && categoryId && specialistData[selectedSpecialist]?.serviceIds) {
      const specServices = specialistData[selectedSpecialist].serviceIds;
      const categoryServices = categoryData[categoryId].serviceIds;

      return !isEmpty(intersection(specServices, categoryServices));
    }
    return true;
  };

  const categoryOptions = [null, ...categoryIds].filter(filterBySelectedSpecialist).map(categoryId => {
    const defaultIcon = categoryId === null ? ServiceIcons.All : null;
    return {
      id: categoryId,
      title: categoryId ? getTranslation(categoryData[categoryId].title, language) : t('calendar.allCategories'),
      description: '',
      icon: categoryId ? ServiceIcons[categoryData[categoryId].title.text] : defaultIcon,
    };
  });

  const [address, setAddress] = useState<Nullable<number>>(
    addressOptions.findIndex(option => option.id === selectedAddress),
  );
  const [category, setCategory] = useState<Nullable<number>>(
    categoryOptions.findIndex(option => option.id === selectedCategory),
  );

  const handleApply = () => {
    addressOptions[address as number].id !== selectedAddress &&
      dispatch(setSelectedAddress(addressOptions[address as number].id));
    dispatch(setSelectedCategory(categoryOptions[category as number].id));
    onClose();
  };

  const handleReset = () => {
    setAddress(addressOptions.findIndex(option => option.id === selectedAddress));
    setCategory(categoryOptions.findIndex(option => option.id === selectedCategory));
  };

  const handleClose = () => {
    handleReset();
    onClose();
  };

  const content = (
    <Flex pt="24px" flexDirection="column" gap="24px" onClick={e => e.stopPropagation()}>
      <Flex flexDirection="column" gap="16px" width="100%">
        <H6>{t('calendar.addresses')}</H6>
        <Flex flexDirection="column" gap="8px">
          {addressOptions.map((item, index) => {
            const { icon, title, description } = item;
            return (
              <FilterMobileItem
                key={item}
                id={index}
                body={
                  <AddressOptionWrapper>
                    <Avatar size="xs" url={icon} />
                    <TextWrapper>
                      <BodySmall>{title}</BodySmall>
                      <Caption lowline color={colors.grey.standard}>
                        {description}
                      </Caption>
                    </TextWrapper>
                  </AddressOptionWrapper>
                }
                onClick={() => setAddress(index)}
                isChecked={index === address}
              />
            );
          })}
        </Flex>
      </Flex>

      <Flex flexDirection="column" gap="16px" width="100%">
        <H6>{t('calendar.categories')}</H6>
        <Flex flexDirection="column" gap="8px">
          {categoryOptions?.map((item, index) => (
            <FilterMobileItem
              key={item.id}
              id={index}
              body={
                <CategoryOptionWrapper>
                  <Avatar size="xs" url={item.icon} bgColor={colors.grey.light} />
                  <TextWrapper>
                    <BodySmall>{item.title}</BodySmall>
                  </TextWrapper>
                </CategoryOptionWrapper>
              }
              onClick={() => setCategory(index)}
              isChecked={index === category}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  );

  const FooterBody = (
    <ColumnFlex width="100%" gap="8px">
      <Button size="large" width="100%" onClick={handleApply}>
        {t('button.apply')}
      </Button>
      <Button size="large" width="100%" design="secondary" onClick={handleReset}>
        {t('button.reset')}
      </Button>
    </ColumnFlex>
  );

  const someProps = {
    isOpen: true,
    label: t('calendar.filter'),
    descriptor: t('calendar.filterInfo'),
    handleClose,
    onClose: handleClose,
    FooterBody,
  };

  useEffect(() => {
    if (isReset) {
      handleReset();
    }
  }, [isReset]);

  return <BottomSheet {...someProps} content={content} detent="content-height" />;
};
