import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import { H6, Link, Flex, EditIcon, BodyLarge } from '@beauty/beauty-market-ui';
import { BlueIcon, LinkWrapper } from '../style';

const View = ({ schedule, onClick }) => {
  const { t } = useTranslation();

  const mappedWeekDays = useMemo(
    () =>
      schedule.map(day => {
        const dayName = t(`daysOfWeek.${capitalize(day.dayOfWeek)}`);
        if (day.isWorkDay) {
          return (
            <Flex key={day.dayOfWeek} justifyContent="space-between">
              <BodyLarge lowLine>{dayName}</BodyLarge>
              <BodyLarge lowLine>
                {day.start} - {day.end}
              </BodyLarge>
            </Flex>
          );
        }
        return (
          <Flex key={day.dayOfWeek} justifyContent="space-between">
            <BodyLarge lowLine>{dayName}</BodyLarge>
            <BodyLarge lowLine>{t('daysOfWeek.dayOff')}</BodyLarge>
          </Flex>
        );
      }),
    [schedule],
  );

  return (
    <Flex flexDirection="column" gap="18px" mb="48px">
      <Flex justifyContent="space-between" alignItems="center">
        <H6>{t('settings.addresses.sidebar.workingTime')}</H6>
        <LinkWrapper>
          <BlueIcon>
            <EditIcon />
          </BlueIcon>
          <Link onClick={onClick}>{t('settings.addresses.sidebar.editTime')}</Link>
        </LinkWrapper>
      </Flex>
      <Flex flexDirection="column" gap="12px" justifyContent="space-between">
        {mappedWeekDays}
      </Flex>
    </Flex>
  );
};

export const ScheduleView = memo(View);
