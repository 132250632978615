import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BodySmall,
  Caption,
  colors,
  DataList,
  Dropdown,
  Flex,
  H6,
  Separator,
  Toggle,
  TabStack,
  Div,
} from '@beauty/beauty-market-ui';
import { ShortWeekdays } from '../../../constants';
import { FormPropsType } from '../../../types';
import { useSchedule } from './useSchedule';

export const ScheduleForm = ({ title, setIsValid }: FormPropsType) => {
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const {
    timeList,
    tabIndex,
    startIndex,
    endIndex,
    isWorkDay,
    allDays,
    setTabIndex,
    dayName,
    handleStartChange,
    handleEndChange,
    handleIsWorkDayChange,
    handleChangeAllDays,
  } = useSchedule();

  useEffect(() => {
    if (startIndex >= endIndex) {
      setError(t('validation.endLaterStart'));
      setIsValid && setIsValid(false);
    } else {
      error && setError('');
      setIsValid && setIsValid(true);
    }
  }, [startIndex, endIndex]);

  const colorText = !isWorkDay || error ? colors.grey.standard : colors.black.standard;

  return (
    <Flex flexDirection="column" gap="16px" mb="40px">
      <H6>{title}</H6>
      <TabStack items={ShortWeekdays} active={tabIndex} onStackClick={setTabIndex} />
      <DataList label={dayName} onCheckboxClick={handleIsWorkDayChange} defaultChecked={isWorkDay} hovered={false} />
      <Flex flexDirection="column" pb="46px" gap="10px">
        <Flex width="100%" gap="16px">
          <Dropdown
            isSeparator
            options={timeList}
            placeholder={t('settings.addresses.sidebar.start')}
            disabled={!isWorkDay}
            currentOption={startIndex}
            onChange={handleStartChange}
            autoScroll
          />
          <Dropdown
            isSeparator
            options={timeList}
            placeholder={t('settings.addresses.sidebar.end')}
            disabled={!isWorkDay}
            currentOption={endIndex}
            onChange={handleEndChange}
            autoScroll
          />
        </Flex>
        <Caption color={colors.red.standard} pl="4px">
          {isWorkDay ? error : null}
        </Caption>
      </Flex>
      <Separator />
      <Flex justifyContent="space-between" alignItems="center" gap="16px">
        <Flex flexDirection="column">
          <H6 color={colorText}>{t('settings.addresses.sidebar.applyAllDays')}</H6>
          <BodySmall color={colorText}>{t('settings.addresses.sidebar.makeTheSame')}</BodySmall>
        </Flex>
        <Div flexShrink={0}>
          <Toggle size="small" checked={allDays} onChange={handleChangeAllDays} disabled={!isWorkDay || error} />
        </Div>
      </Flex>
    </Flex>
  );
};
