import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import { Badge, BodySmall, Caption, colors, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import LogoutImage from 'assets/exit-image.png';
import { Stub, Widget } from 'components';
import { getFormattedCurrency, getTranslation } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { selectCurrency } from 'store/redux-slices/organisationSlice';
import { EventStatus, getSelectedLanguage } from '../../../../constants';
import { StyledBodyLarge } from '../../style';

export const AppointmentsList = ({ appointments, onClick, placeholder }) => {
  const { t } = useTranslation();
  const language = getSelectedLanguage();
  const currency = useAppSelector(selectCurrency);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  return isEmpty(appointments) ? (
    <Stub title={placeholder} alt={placeholder} src={LogoutImage} margin="0 auto" imageWidth="200px" />
  ) : (
    <>
      {appointments.map(({ id, status, start, orgService }, index, arr) => {
        const isCancelled = status === EventStatus.CANCELLED;
        const translatedService = getTranslation(orgService.headOrgService.title, language);
        const price =
          orgService.price === null
            ? t('settings.services.nullPrice')
            : getFormattedCurrency(orgService?.price, currency, language);
        const isLast = index === arr.length - 1;
        return (
          <Widget
            key={id}
            title={<Caption color={colors.grey.dark}>{moment(start).format('DD MMMM YYYY')}</Caption>}
            subtitle={
              <StyledBodyLarge large title={translatedService}>
                {translatedService}
              </StyledBodyLarge>
            }
            label={
              <BodySmall large minWidth="50px" textAlign="end">
                {price}
              </BodySmall>
            }
            onClick={isCancelled ? undefined : () => onClick(id)}
            separator={!isLast}
            m={isLast ? '16px 0 0 0' : '16px 0'}
            p="0 15px 0 0"
          >
            {isCancelled && (
              <Badge
                text={t('clients.profile.canceled')}
                mr={isDesktop ? '8px' : '4px'}
                ml="auto"
                design="lightOrange"
              />
            )}
          </Widget>
        );
      })}
    </>
  );
};
