import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  BodyLarge,
  getMinWidthMediaQuery,
  Separator,
  useMediaQuery,
  BottomSheet,
  Button,
  Flex,
} from '@beauty/beauty-market-ui';
import { PopUp } from 'components';
import { useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectSpecialist, selectSpecialistInfo, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { SpecialistAction, SpecialistEducation } from 'types';
import { EmployeeSection } from '../EmployeeSection/EmployeeSection';
import { EditEducation } from './EditEducation';
import { EducationFormType } from './Education.definitions';
import { EducationItem } from './EducationItem';

export const Education = () => {
  const { t } = useTranslation();
  const { id } = useAppSelector(selectSpecialist);
  const { education } = useAppSelector(selectSpecialistInfo);

  const [action, setAction] = useState(SpecialistAction.None);
  const [values, setValues] = useState<SpecialistEducation | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const addEducation = useRequest(ThunkSpecialist.addEducation, SpecialistAction.AddEducation);
  const editEducation = useRequest(ThunkSpecialist.editEducation, SpecialistAction.EditEducation);
  const deleteEducation = useRequest(ThunkSpecialist.deleteEducation, SpecialistAction.DeleteEducation);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleClose = () => {
    setAction(SpecialistAction.None);
    setValues(null);
  };

  const handleDeleteApprove = async () => {
    try {
      setSubmitting(true);
      values && (await deleteEducation(values.id));
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };

  const handleDeleteClick = (item: SpecialistEducation) => {
    setAction(SpecialistAction.DeleteEducation);
    setValues(item);
  };

  const handleEditClick = (item: SpecialistEducation) => {
    setAction(SpecialistAction.EditEducation);
    setValues(item);
  };

  const handleAddEducation = () => {
    setAction(SpecialistAction.AddEducation);
    setValues(null);
  };

  const handleSubmit = async (data: EducationFormType) => {
    const isFileChanged = data.file instanceof File;
    const params = {
      startYear: Number(data.startYear),
      endYear: Number(data.endYear),
      school: data.school,
      field: data.field,
      degree: data?.degree,
      id: values?.id || id,
      headOrgSpecialistId: values?.headOrgSpecialistId,
      fileUrl: isFileChanged ? null : data.file,
      file: isFileChanged ? data.file : null,
      fileName: data?.file ? data?.fileName : null,
    };
    return values ? editEducation(params) : addEducation(params);
  };

  const educationItems = education.map((item, index, arr) => (
    <EducationItem
      key={item.id}
      avatar={item?.fileName?.endsWith('.pdf') ? null : item.documentUrl}
      range={`${item.startYear} - ${item.endYear}`}
      document={item.documentUrl}
      school={item.school}
      degree={item.degree}
      onDelete={() => handleDeleteClick(item)}
      onEdit={() => handleEditClick(item)}
      separator={index !== arr.length - 1}
    />
  ));

  const content = (
    <BodyLarge mb="80px">{t('specialists.personalInfo.deleteEducation', { value: values?.degree ?? '' })}</BodyLarge>
  );

  return (
    <>
      <EmployeeSection
        position="middle"
        visibleCount={3}
        count={educationItems.length}
        title={t('specialists.personalInfo.education')}
        description={t('specialists.personalInfo.certificates')}
        placeholder={t('specialists.personalInfo.noProfessional')}
        buttonLabel={t('specialists.personalInfo.fillInformation')}
        onClick={handleAddEducation}
        separator={isEmpty(educationItems) && isDesktop ? <Separator mb="16px" /> : null}
      >
        {educationItems}
      </EmployeeSection>
      {action === SpecialistAction.AddEducation && <EditEducation onSubmit={handleSubmit} onClose={handleClose} />}
      {action === SpecialistAction.EditEducation && (
        <EditEducation onSubmit={handleSubmit} onClose={handleClose} defaultValues={values} />
      )}
      {action === SpecialistAction.DeleteEducation &&
        (isDesktop ? (
          <PopUp
            title={t('specialists.personalInfo.deletingEducation')}
            description=""
            onSubmit={handleDeleteApprove}
            onClose={handleClose}
            confirm={t('button.delete')}
            cancel={t('button.cancel')}
            isLoading={isSubmitting}
          >
            {content}
          </PopUp>
        ) : (
          <BottomSheet
            isOpen={action === SpecialistAction.DeleteEducation}
            onClose={handleClose}
            handleClose={handleClose}
            label={t('specialists.personalInfo.deletingEducation')}
            content={content}
            detent="content-height"
            FooterBody={
              <Flex flexDirection="column" width="100%" gap="8px">
                <Button onClick={handleDeleteApprove}>{t('button.delete')}</Button>
                <Button onClick={handleClose} design="secondary">
                  {t('button.cancel')}
                </Button>
              </Flex>
            }
          />
        ))}
    </>
  );
};
