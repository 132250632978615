import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { upperCase } from 'lodash';
import {
  Avatar,
  Button,
  Flex,
  getMinWidthMediaQuery,
  H6,
  ListRegionCode,
  UploadIcon,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { SidebarSheet } from 'components';
import { checkAccess, getListRegionCode, toFormData } from 'helpers';
import { useRequest } from 'hooks';
import { StyledButton } from 'style';
import { ProfileActions } from 'types';
import { AccessRange, invalidHints, InvalidVariants, positions } from '../../../constants';
import { useAppSelector } from '../../../store/hooks';
import { selectProfile, ThunkUser } from '../../../store/redux-slices/userSlice';
import EditPhoto from '../../EditPhoto';
import { FormikDropdown } from '../../functional/formik/formik-dropdown/FormikDropdown';
import { FormikInput } from '../../functional/formik/formik-input/FormikInput';
import {
  EditProfileFormFields,
  EditProfileFormType,
  initialValues,
  validationSchema,
} from './EditProfileSidebar.definitions';

export const EditProfileSidebar = ({ onClose }) => {
  const { t } = useTranslation();
  const profile = useAppSelector(selectProfile);
  const { Name, Surname, Email, Number, Code, Role, Photo } = EditProfileFormFields;

  const [isEditPhotoMode, setEditPhotoMode] = useState(false);

  const updateProfile = useRequest(ThunkUser.editProfile, ProfileActions.EditProfile);
  const deleteAvatar = useRequest(ThunkUser.deleteUserAvatar, ProfileActions.None);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const regionOptions = getListRegionCode();
  const positionOptions = positions.map(position => ({
    id: upperCase(position),
    item: t(`profile.positions.${position}`),
    disabled: false,
  }));

  const handleSubmitForm = (data: EditProfileFormType, { setFieldError, setSubmitting }) => {
    const { photo, role: positionIndex } = data;
    const params: EditProfileFormType = {
      name: data.name?.trim() ?? null,
      surname: data.surname?.trim() ?? null,
      email: data.email?.trim() ?? null,
      number: data.number?.trim() ?? null,
      code: data.code?.trim() ?? null,
      role: positionOptions[positionIndex].id,
    };

    const extendedParams = photo instanceof File ? { ...params, file: photo } : params;

    setSubmitting(true);
    updateProfile({ id: profile.id, data: toFormData(extendedParams) })
      .then(onClose)
      .catch(({ message, statusCode }) => {
        setSubmitting(false);
        if (statusCode === 409) {
          if (message.includes('email')) setFieldError(Email, t(invalidHints[InvalidVariants.UsedEmail]));
          if (message.includes('number')) setFieldError(Number, t(invalidHints[InvalidVariants.UsedNumber]));
        } else if (statusCode === 400) {
          if (message[0].includes('email')) setFieldError(Email, t(invalidHints[InvalidVariants.Email]));
        }
      });

    if (profile.id && photo === null) deleteAvatar(profile.id);
  };

  const formikContextValue = {
    initialValues: initialValues(profile, positionOptions),
    validateOnMount: false,
    onSubmit: handleSubmitForm,
    validationSchema: validationSchema(t),
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid, dirty, handleSubmit, isSubmitting, setFieldValue, values }) => {
        const avatarUrl = values[Photo] instanceof File ? URL.createObjectURL(values[Photo] as File) : values[Photo];

        return (
          <SidebarSheet
            onClose={onClose}
            label={t('profile.editPersonalInformation')}
            descriptor={t('profile.changePersonalData')}
            FooterBody={
              <Button
                width="100%"
                size="large"
                type="submit"
                disabled={!dirty || !isValid || isSubmitting}
                onClick={handleSubmit}
              >
                {t('button.applyChanges')}
              </Button>
            }
          >
            <Form>
              <Flex gap="24px" flexDirection="column" mb="80px">
                <Flex flexDirection="column" gap="8px" mb="24px" alignItems="start" width="100px">
                  <Avatar
                    size="l"
                    url={avatarUrl}
                    deleteMode={isDesktop ? 'hover' : 'double-tap'}
                    onDelete={() => setFieldValue(Photo, null)}
                  />
                  <StyledButton
                    size="extraSmall"
                    design="tertiary"
                    prefix={<UploadIcon />}
                    onClick={() => setEditPhotoMode(true)}
                  >
                    {`${values[Photo] ? t('uploadPhoto.uploadPhoto') : t('uploadPhoto.newPhoto')} `}
                  </StyledButton>
                </Flex>
                <Flex gap="16px" flexDirection="column" padding="0">
                  <H6>{t('profile.generalInformation')}</H6>
                  <Flex gap="8px" padding="0">
                    <FormikInput
                      id={Name}
                      name={Name}
                      width="100%"
                      design="white"
                      placeholder={t('profile.name')}
                      autoComplete="given-name"
                    />
                    <FormikInput
                      id={Surname}
                      name={Surname}
                      width="100%"
                      design="white"
                      placeholder={t('profile.surname')}
                      autoComplete="family-name"
                    />
                  </Flex>
                </Flex>
                <Flex gap="16px" flexDirection="column" padding="0">
                  <H6>{t('profile.contactInformation')}</H6>
                  <Flex gap="8px" padding="0">
                    <Field
                      id={Code}
                      name={Code}
                      options={regionOptions}
                      handleSelect={code => setFieldValue(Code, code)}
                      placeholder={t('profile.region')}
                      design="white"
                      currentRegionCode={values[Code]}
                      component={ListRegionCode}
                    />
                    <FormikInput
                      id={Number}
                      name={Number}
                      width="100%"
                      design="white"
                      placeholder={t('profile.phone')}
                      autoComplete="tel"
                    />
                  </Flex>
                  <FormikInput
                    id={Email}
                    name={Email}
                    width="100%"
                    design="white"
                    placeholder={t('profile.email')}
                    autoComplete="email"
                    type="email"
                  />
                  <FormikDropdown
                    id={Role}
                    name={Role}
                    placeholder={t('profile.position')}
                    currentOption={values[Role] as number}
                    options={positionOptions}
                    onChange={index => setFieldValue(Role, index)}
                    disabled={!checkAccess(AccessRange.ADMIN)}
                  />
                </Flex>
              </Flex>
            </Form>
            {isEditPhotoMode && (
              <EditPhoto
                onClose={() => setEditPhotoMode(false)}
                onSubmit={async (photo: File) => {
                  await setFieldValue(Photo, photo);
                }}
              />
            )}
          </SidebarSheet>
        );
      }}
    </Formik>
  );
};
