import { BodyLarge, Caption, colors, Flex, Separator } from '@beauty/beauty-market-ui';

type Props = {
  label: string;
  title?: string;
  large?: boolean;
  separator?: boolean;
  placeholder?: string;
};

export const ProfileItem = ({ label, title, large, separator, placeholder }: Props) => (
  <>
    <Flex p="16px 0" flexDirection="column">
      <Caption color={colors.grey.dark}>{label}</Caption>
      {large || !title ? <BodyLarge large>{title || placeholder}</BodyLarge> : <Caption>{title}</Caption>}
    </Flex>
    {separator && <Separator />}
  </>
);
