import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import get from 'lodash/get';
import head from 'lodash/head';
import {
  H6,
  Flex,
  ListRegionCode,
  NotifyStatus,
  useNotify,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { CustomInput } from 'components/_common/CustomInput';
import { SidebarSheet } from 'components/SidebarSheet';
import { getNotifyContent, getListRegionCode } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllAddresses } from 'store/redux-slices/addressSlice';
import { ThunkClient } from 'store/redux-slices/clientSlice';
import { ClientActions } from 'types/client';
import { ErrorDataType } from '../../../../types';
import { SidebarFooter } from '../../../SidebarFooter';
import { FormWrapper } from '../style';
import {
  initialValues,
  NewClientForm,
  NewClientFormFields,
  newClientValidationSchema,
} from './NewClientSidebar.definitions';

interface Props {
  onClose: () => void;
}

export const NewClientSidebar = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const notify = useNotify();
  const allAddress = useAppSelector(selectAllAddresses);
  const organisationId = get(head(allAddress), 'id', undefined);
  const [isSubmitting, setSubmitting] = useState(false);

  const { Name, Surname, Number, Region } = NewClientFormFields;
  /*  const onFormSubmit = useCallback(
    async (data: NewClientForm, { setFieldError }) => {
      const { name, surname, phone, region } = data;
      if (organisationId) {
        const params = { name, surname, number: phone, code: region };
        const response = await appointmentsAPI.createClient(organisationId, params);
        if (response.data) {
          handleSubmit(response.data);
          handleClose();
        } else if (response.error?.statusCode === 409) setFieldError(Number, t('validation.phoneExists'));
        else if (response.error?.statusCode === 400) setFieldError(Number, t('validation.enterValidPhone'));
      }
    },
    [organisationId],
  ); */

  const onFormSubmit = useCallback(
    async (data: NewClientForm, { setFieldError }) => {
      setSubmitting(true);
      const response = dispatch(ThunkClient.createClient({ ...data, orgId: organisationId }));
      response
        ?.unwrap()
        .then(
          () => {
            notify(getNotifyContent(NotifyStatus.SUCCESS, ClientActions.Add, t));
            onClose();
          },
          ({ statusCode, message }: ErrorDataType) => {
            if (statusCode === 400) {
              if (message.includes('created')) setFieldError(Number, t('validation.phoneExists'));
            }
            notify(
              getNotifyContent(
                NotifyStatus.ERROR,
                ClientActions.Add,
                t,
                message.includes('created') ? t('clients.alreadyCreated') : undefined,
              ),
            );
          },
        )
        .finally(() => setSubmitting(false));
    },
    [organisationId],
  );

  const formikContextValue = {
    initialValues,
    validationSchema: newClientValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid, handleSubmit: onSubmit, values, setFieldValue }) => {
        const isFieldsFilled = !!values[Name] && !!values[Surname] && !!values[Region] && !!values[Number];

        const footerBody = (
          <SidebarFooter
            disabled={!isFieldsFilled || !isValid || isSubmitting}
            onSubmit={onSubmit}
            onBack={onClose}
            confirmationLabel={t('calendar.newClientSidebar.sendInvite')}
            cancel
            isLoading={isSubmitting}
          />
        );

        const sidebarProps = {
          isOpen: true,
          onClose,
          FooterBody: footerBody,
          label: t('calendar.newClientSidebar.newClient'),
        };

        const content = (
          <Form>
            <FormWrapper>
              <H6 marginTop="8px">{t('calendar.newClientSidebar.generalInformation')}</H6>
              <Flex gap={isDesktop ? '8px' : '16px'} flexDirection={isDesktop ? 'row' : 'column'}>
                <CustomInput fieldName={Name} placeholder={t('calendar.newClientSidebar.name')} autoComplete="off" />
                <CustomInput
                  fieldName={Surname}
                  placeholder={t('calendar.newClientSidebar.surname')}
                  autoComplete="off"
                />
              </Flex>
              <H6 marginTop="8px">{t('calendar.newClientSidebar.contactInformation')}</H6>
              <Flex gap="8px">
                <ListRegionCode
                  id={Region}
                  name={Region}
                  design="white"
                  placeholder={t('calendar.newClientSidebar.region')}
                  options={getListRegionCode()}
                  currentRegionCode={values[Region]}
                  handleSelect={(newCode: string) => setFieldValue(Region, newCode)}
                />
                <CustomInput fieldName={Number} placeholder={t('calendar.newClientSidebar.phone')} autoComplete="off" />
              </Flex>
            </FormWrapper>
          </Form>
        );

        return <SidebarSheet {...sidebarProps}>{content}</SidebarSheet>;
      }}
    </Formik>
  );
};
