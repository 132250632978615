import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cloneDeep, isEmpty, lowerCase } from 'lodash';
import {
  Button,
  colors,
  ExtendedSearchInput,
  Flex,
  getMinWidthMediaQuery,
  H3,
  NotifyStatus,
  PlusIcon,
  Scroll,
  Separator,
  useMediaQuery,
  useNotify,
  H4,
} from '@beauty/beauty-market-ui';
import { EditEmployee, EmployeeCard, Stub } from 'components';
import { getNotifyContent } from 'helpers';
import { OrganizationAction, SpecialistAction, SubscriptionStatusType } from 'types';
import LogoutImage from '../../../assets/exit-image.png';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectActiveSubscription, selectEmployeeNum } from '../../../store/redux-slices/organisationSlice';
import {
  emptySpecialist,
  selectAllSpecialists,
  selectSpecialistState,
  setSpecialistActive,
  ThunkSpecialist,
  updateSpecialist,
  updateType,
} from '../../../store/redux-slices/specialistSlice';
import { selectUserHead } from '../../../store/redux-slices/userSlice';
import { Wrapper } from '../style';
import { ActivateBar, PaymentBar } from './components';
import { sortSpecialist } from './helpers';
import { InputWrapper } from './style';

export const EmployeeList = () => {
  const { t } = useTranslation();
  const { type } = useAppSelector(selectSpecialistState);
  const userHead = useAppSelector(selectUserHead);
  const employeeNum = useAppSelector(selectEmployeeNum);
  const specialists = useAppSelector(selectAllSpecialists);
  const subscription = useAppSelector(selectActiveSubscription);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const notify = useNotify();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const [, startTransition] = useTransition();
  const [toSearch, setSearch] = useState('');
  const [toActivateId, setActivateId] = useState('');
  const [isActivateBar, setActivateBar] = useState(false);
  const [isPaymentBar, setPaymentBar] = useState(false);
  const [params] = useSearchParams();

  const isPast = subscription?.status === SubscriptionStatusType.PAST;

  const handleClose = useCallback(() => {
    setPaymentBar(false);
    setActivateBar(false);
    setActivateId('');
  }, []);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      setSearch(e.currentTarget.value);
    });
  };

  const handleAddButtonClick = useCallback(() => {
    const owner = specialists.find(item => item.accessLevel === 'OWNER');
    if (owner) {
      if (owner.role) {
        dispatch(updateType(SpecialistAction.Add));
        dispatch(updateSpecialist(emptySpecialist));
      } else {
        dispatch(updateType(SpecialistAction.Edit));
        dispatch(updateSpecialist(owner));
      }
    }
  }, [dispatch, specialists]);

  const sortedSpecialist = useMemo(() => cloneDeep(specialists).sort(sortSpecialist), [specialists]);
  const filteredSpecialists = useMemo(() => {
    const toSearchWithoutSpaces = lowerCase(toSearch).trim().split(' ').join('');
    return sortedSpecialist.filter(({ account }) => {
      const { name, surname, email, code, number } = account;
      const combinedProperties = lowerCase(`${name}${surname}${email}${code}${number}`);
      return combinedProperties.includes(toSearchWithoutSpaces);
    });
  }, [sortedSpecialist, toSearch]);

  useEffect(() => {
    if (userHead) {
      const orgId = userHead.headOrgId;
      dispatch(ThunkSpecialist.fetchAllSpecialists(orgId)).then(() => {
        const success = params.get('success');
        const specId = params.get('specId');
        if (success) {
          if (success === 'true') {
            notify(getNotifyContent(NotifyStatus.SUCCESS, OrganizationAction.PayForSpecialist, t));
            specId && dispatch(setSpecialistActive(specId));
          } else {
            notify(getNotifyContent(NotifyStatus.ERROR, OrganizationAction.PayForSpecialist, t));
          }
        }
      });
    }
  }, [userHead]);

  useEffect(() => {
    if (type !== SpecialistAction.None) {
      dispatch(updateType(SpecialistAction.None));
    }
  }, []);

  return (
    <>
      {!isEmpty(specialists) ? (
        <Wrapper>
          <Flex width="100%" justifyContent="space-between" pb="16px" alignItems="center" gap="23px">
            {isDesktop ? (
              <H3 mr="auto">{t('specialists.employeeList')}</H3>
            ) : (
              <H4 mr="auto">{t('specialists.employeeList')}</H4>
            )}
            {/* <Button size="small" design="tertiaryBlack" prefix={<FilterIcon />} /> */}
            <Button
              prefix={<PlusIcon width="20px" height="20px" />}
              onClick={handleAddButtonClick}
              design="primary"
              size="small"
            >
              {isDesktop ? t('specialists.addNew') : null}
            </Button>
          </Flex>
          <Separator mb="24px" />
          <Flex flexDirection="column">
            <InputWrapper>
              <ExtendedSearchInput
                onlyService
                placeholder={t('clients.placeholder')}
                maxWidth="100%"
                onChange={handleChangeSearch}
                onCrossClick={() => setSearch('')}
                zIndex={1}
              />
            </InputWrapper>
          </Flex>
          <Scroll scrollColor={colors.grey.light} mt="100px">
            {filteredSpecialists.map((spec, index, arr) => {
              const handleSpecialistClick = () =>
                navigate(generatePath(RouterUrl.EmployeeGeneralData, { id: spec.id }));

              const handleActivateSpecialistClick = (e: MouseEvent<HTMLDivElement>) => {
                e.stopPropagation();
                if (isPast) return;
                setActivateId(spec.id);
                if (subscription) {
                  subscription.employeeCount <= employeeNum ? setPaymentBar(true) : setActivateBar(true);
                }
              };

              return (
                <EmployeeCard
                  key={spec.id}
                  disabled={isPast}
                  {...spec}
                  onClick={handleSpecialistClick}
                  onActivate={handleActivateSpecialistClick}
                  separator={index !== arr.length - 1}
                />
              );
            })}
          </Scroll>
        </Wrapper>
      ) : (
        <Wrapper>
          <Stub
            title={t('specialists.noEmployees')}
            subtitle={t('specialists.addSpecialistToWork')}
            src={LogoutImage}
            alt={t('logout.altImg')}
            margin={isDesktop ? '171px auto 0' : '73px 8px auto'}
          >
            <Button
              prefix={isDesktop ? <PlusIcon width="20px" height="20px" /> : null}
              onClick={handleAddButtonClick}
              design="primary"
              size="small"
            >
              {t('specialists.addNew')}
            </Button>
          </Stub>
        </Wrapper>
      )}
      {type === SpecialistAction.Add && <EditEmployee />}
      {isPaymentBar && <PaymentBar specId={toActivateId} onClose={handleClose} />}
      {isActivateBar && <ActivateBar specId={toActivateId} onClose={handleClose} />}
    </>
  );
};
