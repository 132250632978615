import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Avatar, CalendarIcon, Flex, getMinWidthMediaQuery, H6, useMediaQuery } from '@beauty/beauty-market-ui';
import { FormikDropdown } from 'components/functional/formik/formik-dropdown/FormikDropdown';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { ImageType } from 'types';
import { genders } from '../../../../../constants';
import { GappedFlex } from '../../../../../style';
import { ClientForm, ClientFormFields } from '../ClientForm.definitions';

type GeneralInformationFormProps = {
  genderIndex: number;
  setGenderIndex: (index: number) => void;
  verified: boolean;
  idNumber: string | null;
  avatar?: ImageType | null;
};

export const GeneralInformationForm = ({
  genderIndex,
  setGenderIndex,
  verified,
  idNumber,
  avatar,
}: GeneralInformationFormProps) => {
  const { t } = useTranslation();

  const { setFieldValue } = useFormikContext<ClientForm>();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  return (
    <>
      {isDesktop ? (
        <Flex flexDirection="column" gap="8px" mb="24px" alignItems="start" width="100px">
          <Avatar size="l" url={avatar} />
        </Flex>
      ) : null}
      <H6 mt="8px">{t('settings.specialists.generalInfo')}</H6>
      <GappedFlex gap="8px">
        <FormikInput
          design="white"
          id={ClientFormFields.Name}
          name={ClientFormFields.Name}
          placeholder={t('registration.name')}
          disabled={verified}
        />
        <FormikInput
          design="white"
          id={ClientFormFields.Surname}
          name={ClientFormFields.Surname}
          placeholder={t('registration.surname')}
          disabled={verified}
        />
      </GappedFlex>
      <FormikInput
        design="white"
        id={ClientFormFields.DateOfBirth}
        name={ClientFormFields.DateOfBirth}
        iconLeft={<CalendarIcon />}
        placeholder={t('registration.dateOfBirth')}
        disabled={verified}
      />
      <FormikDropdown
        id={ClientFormFields.Gender}
        name={ClientFormFields.Gender}
        placeholder={t('registration.gender')}
        currentOption={genderIndex}
        options={genders.map(genderOption => ({
          item: t(`registration.genders.${genderOption}`),
          disabled: false,
        }))}
        isSeparator
        onChange={index => {
          setGenderIndex(index);
          setFieldValue(ClientFormFields.Gender, genders[index].toUpperCase());
        }}
        disabled={verified}
      />
      <FormikInput
        design="white"
        id={ClientFormFields.IDNumber}
        name={ClientFormFields.IDNumber}
        placeholder={t('registration.idNumber')}
        disabled={verified && idNumber}
      />
    </>
  );
};
