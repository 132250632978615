import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  BodySmall,
  Caption,
  colors,
  Flex,
  getMinWidthMediaQuery,
  H6,
  H7,
  Icon,
  NotifyStatus,
  RightIcon,
  useMediaQuery,
  useNotify,
} from '@beauty/beauty-market-ui';
import { extractFullAddress, getSpecialistNotifyContent, hasArrayChanged } from 'helpers';
import { Adaptive } from 'hoc';
import { getIconByStatus } from '../../../helpers/utils';
import { Content, StatusCard } from '../../../page/Instruments/EmployeeList/style';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAllAddresses } from '../../../store/redux-slices/addressSlice';
import { selectAllServices } from '../../../store/redux-slices/serviceSlice';
import { ThunkSpecialist } from '../../../store/redux-slices/specialistSlice';
import { NamedAddressType, SpecialistAction } from '../../../types';
import { SidebarSheet } from '../../index';
import { SidebarFooter } from '../../SidebarFooter';
import { EditServicesProps, SelectedServiceType } from '../types';
import { SelectServices } from './SelectServices';

const EditServices = ({ initial, orgIds, onClose }: EditServicesProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const allAddresses = useAppSelector(selectAllAddresses);
  const allServices = useAppSelector(selectAllServices);
  const notify = useNotify();

  const [selectedAddress, setSelectedAddress] = useState<NamedAddressType | null>(null);
  const [selectedServices, setSelectedServices] = useState<SelectedServiceType>(initial);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleChangeServices = (orgId: string, services: string[]) => {
    const prevServices = initial[orgId].services;
    selectedServices[orgId].isDirty = hasArrayChanged(prevServices, services);
    selectedServices[orgId].services = services;
    const isSomeDirty = orgIds.filter(id => selectedServices[id].isDirty);
    setSelectedServices(selectedServices);
    setIsDirty(!isEmpty(isSomeDirty));
    setSelectedAddress(null);
  };

  const handleSubmit = () => {
    setSubmitting(true);
    const specialistServicesToEdit = orgIds
      .filter(orgId => selectedServices[orgId].isDirty)
      .map(orgId => {
        const params = {
          orgId,
          orgSpecId: selectedServices[orgId].orgSpecId,
          orgServiceIds: selectedServices[orgId].services.join(','),
        };
        return dispatch(ThunkSpecialist.editServices(params)).unwrap();
      });
    Promise.all(specialistServicesToEdit)
      .then(() => notify(getSpecialistNotifyContent(NotifyStatus.SUCCESS, SpecialistAction.EditServices, t)))
      .catch(() => notify(getSpecialistNotifyContent(NotifyStatus.ERROR, SpecialistAction.EditServices, t)))
      .finally(() => {
        setSubmitting(false);
        onClose();
      });
  };

  const headOrgServices = allServices.reduce((acc, service) => {
    acc[service.id] = service;
    return acc;
  }, {});

  return (
    <SidebarSheet
      label={t('specialists.addServices')}
      descriptor={t('specialists.chooseServicesWhichProvides')}
      onClose={onClose}
      onBack={onClose}
      FooterBody={
        <SidebarFooter
          disabled={!isDirty}
          isLoading={isSubmitting}
          onSubmit={handleSubmit}
          onBack={onClose}
          save
          cancel
        />
      }
    >
      <Flex gap="16px" flexDirection="column" padding="0" mb="80px">
        <H6>{t('specialists.organisation')}</H6>
        <Flex flexDirection="column" gap={isDesktop ? '16px' : '8px'}>
          {allAddresses.map(org => {
            const address = extractFullAddress(org.address);
            return selectedServices[org.id] ? (
              <StatusCard key={org.id} onClick={() => setSelectedAddress(org)}>
                {getIconByStatus(
                  org.id,
                  isEmpty(selectedServices[org.id].services) ? 'pending' : 'completed',
                  '22px',
                  'start',
                )}
                <Content>
                  <H7 truncated title={org.name}>
                    {org.name}
                  </H7>
                  <Adaptive mobile={Caption} desktop={BodySmall} color={colors.grey.standard} truncated title={address}>
                    {address}
                  </Adaptive>
                </Content>
                <Icon stroke={colors.grey.dark} width="20px" height="20px" ml="auto">
                  <RightIcon />
                </Icon>
              </StatusCard>
            ) : null;
          })}
        </Flex>
      </Flex>
      {selectedAddress && (
        <SelectServices
          orgId={selectedAddress.id}
          orgName={selectedAddress.name}
          services={selectedServices[selectedAddress.id].services}
          headOrgServices={headOrgServices}
          onBack={() => setSelectedAddress(null)}
          onSubmit={handleChangeServices}
          onClose={onClose}
        />
      )}
    </SidebarSheet>
  );
};

export default EditServices;
