import i18next from 'i18next';
import { getSelectedLanguage } from '../constants';

export const useDynamicTranslation = (key, items): string => i18next.t(key, items);

// for count <= 100
const getRussianPluralForm = (key, count) => {
  // 5-20
  if (count >= 5 && count <= 20) {
    return i18next.t(`${key}.many`, { count });
  }

  // end 1
  if (count % 10 === 1) {
    return i18next.t(`${key}.one`, { count });
  }

  // end 2, 3, 4
  if (count % 10 >= 2 && count % 10 <= 4) {
    return i18next.t(`${key}.few`, { count });
  }

  // other
  return i18next.t(`${key}.many`, { count });
};

const getEnglishPluralForm = (key, count) => {
  if (count === 1) {
    return i18next.t(`${key}.one`, { count });
  }
  return i18next.t(`${key}.many`, { count });
};

export const usePluralForm = (key, count) => {
  const language = getSelectedLanguage();

  if (language === 'ru' || language === 'RU') {
    return getRussianPluralForm(key, count);
  }
  return getEnglishPluralForm(key, count);
};
