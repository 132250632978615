import { useTranslation } from 'react-i18next';
import {
  Avatar,
  BodyLarge,
  Caption,
  colors,
  EditIcon,
  Flex,
  Link,
  Separator,
  TrashIcon,
} from '@beauty/beauty-market-ui';
import { Box, IconBox } from '../../style';

export const EducationItem = ({ school, avatar, document, degree, range, onEdit, onDelete, separator }) => {
  const { t } = useTranslation();

  return (
    <>
      <Box alignItems="start" available>
        <Flex flexShirnk={0}>
          <Avatar url={avatar} size="xs" />
        </Flex>
        <Flex flexDirection="column" ml="8px" mr="auto" width="65vw">
          <Caption truncated color={colors.grey.dark} title={school}>
            {school}
          </Caption>
          <BodyLarge truncated large title={degree}>
            {degree}
          </BodyLarge>
          <Caption>{range}</Caption>
          {document ? (
            <Link href={document} size="s" mt="8px" target="_blank">
              {t('specialists.personalInfo.viewDocument')}
            </Link>
          ) : (
            <Link size="s" mt="8px" onClick={onEdit}>
              {t('button.edit')}
            </Link>
          )}
        </Flex>
        <IconBox>
          <Link design="gray" size="md" icon={<EditIcon />} onClick={onEdit} />
          <Link design="gray" size="md" icon={<TrashIcon />} onClick={onDelete} />
        </IconBox>
      </Box>
      {separator && <Separator m="16px 0" />}
    </>
  );
};
