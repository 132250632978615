import { t } from 'i18next';
import { NotifyPropsType, NotifyStatus } from '@beauty/beauty-market-ui';
import { AddressAction } from '../../types';

const successTitles = {
  [AddressAction.AddAddress]: 'address.alerts.title.addAddressSuccess',
  [AddressAction.AddPhotos]: 'address.alerts.title.addPhotosSuccess',
  [AddressAction.EditPhotos]: 'address.alerts.title.editPhotosSuccess',
  [AddressAction.EditDescription]: 'address.alerts.title.editDescriptionSuccess',
  [AddressAction.EditMainPhoto]: 'address.alerts.title.editMainPhotoSuccess',
  [AddressAction.EditAddress]: 'address.alerts.title.editAddressSuccess',
  [AddressAction.EditWorkingTime]: 'address.alerts.title.editWorkingTimeSuccess',
  [AddressAction.EditEmployees]: 'address.alerts.title.editEmployeesSuccess',
  [AddressAction.EditCurrentEmployee]: 'address.alerts.title.editCurrentEmployeeSuccess',
  [AddressAction.DeleteEmployee]: 'address.alerts.title.deleteEmployeeSuccess',
  [AddressAction.DeleteAddress]: 'address.alerts.title.deleteAddressSuccess',
  [AddressAction.Publish]: 'address.alerts.title.publishAddressSuccess',
  [AddressAction.Unpublish]: 'address.alerts.title.unpublishAddressSuccess',
};

const errorTitles = {
  [AddressAction.AddAddress]: 'address.alerts.title.addAddressError',
  [AddressAction.AddPhotos]: 'address.alerts.title.addPhotosError',
  [AddressAction.EditPhotos]: 'address.alerts.title.editPhotosError',
  [AddressAction.EditDescription]: 'address.alerts.title.editDescriptionError',
  [AddressAction.EditMainPhoto]: 'address.alerts.title.editMainPhotoError',
  [AddressAction.EditAddress]: 'address.alerts.title.editAddressError',
  [AddressAction.EditWorkingTime]: 'address.alerts.title.editWorkingTimeError',
  [AddressAction.EditEmployees]: 'address.alerts.title.editEmployeesError',
  [AddressAction.EditCurrentEmployee]: 'address.alerts.title.editCurrentEmployeeError',
  [AddressAction.DeleteEmployee]: 'address.alerts.title.deleteEmployeeError',
  [AddressAction.DeleteAddress]: 'address.alerts.title.deleteAddressError',
  [AddressAction.Publish]: 'address.alerts.title.publishAddressError',
  [AddressAction.Unpublish]: 'address.alerts.title.unpublishAddressError',
};

const successSubtitles = {
  [AddressAction.AddAddress]: 'address.alerts.subtitle.addAddressSuccess',
  [AddressAction.AddPhotos]: 'address.alerts.subtitle.addPhotosSuccess',
  [AddressAction.EditPhotos]: 'address.alerts.subtitle.editPhotosSuccess',
  [AddressAction.EditDescription]: 'address.alerts.subtitle.editDescriptionSuccess',
  [AddressAction.EditMainPhoto]: 'address.alerts.subtitle.editMainPhotoSuccess',
  [AddressAction.EditAddress]: 'address.alerts.subtitle.editAddressSuccess',
  [AddressAction.EditWorkingTime]: 'address.alerts.subtitle.editWorkingTimeSuccess',
  [AddressAction.EditEmployees]: 'address.alerts.subtitle.editEmployeesSuccess',
  [AddressAction.EditCurrentEmployee]: 'address.alerts.subtitle.editCurrentEmployeeSuccess',
  [AddressAction.DeleteEmployee]: 'address.alerts.subtitle.deleteEmployeeSuccess',
  [AddressAction.DeleteAddress]: 'address.alerts.subtitle.deleteAddressSuccess',
  [AddressAction.Publish]: 'address.alerts.subtitle.publishAddressSuccess',
  [AddressAction.Unpublish]: 'address.alerts.subtitle.unpublishAddressSuccess',
};

const errorSubtitles = {
  [AddressAction.AddAddress]: 'address.alerts.subtitle.addAddressError',
  [AddressAction.AddPhotos]: 'address.alerts.subtitle.addPhotosError',
  [AddressAction.EditPhotos]: 'address.alerts.subtitle.editPhotosError',
  [AddressAction.EditDescription]: 'address.alerts.subtitle.editDescriptionError',
  [AddressAction.EditMainPhoto]: 'address.alerts.subtitle.editMainPhotoError',
  [AddressAction.EditAddress]: 'address.alerts.subtitle.editAddressError',
  [AddressAction.EditWorkingTime]: 'address.alerts.subtitle.editWorkingTimeError',
  [AddressAction.EditEmployees]: 'address.alerts.subtitle.editEmployeesError',
  [AddressAction.EditCurrentEmployee]: 'address.alerts.subtitle.editCurrentEmployeeError',
  [AddressAction.DeleteEmployee]: 'address.alerts.subtitle.deleteEmployeeError',
  [AddressAction.DeleteAddress]: 'address.alerts.subtitle.deleteAddressError',
  [AddressAction.Publish]: 'address.alerts.subtitle.publishAddressError',
  [AddressAction.Unpublish]: 'address.alerts.subtitle.unpublishAddressError',
};

export const getAddressNotifyContent = (
  status: NotifyStatus,
  action: AddressAction,
  message?: string,
): NotifyPropsType => {
  const title = status === NotifyStatus.SUCCESS ? successTitles[action] : errorTitles[action];
  const subtitle = status === NotifyStatus.SUCCESS ? successSubtitles[action] : errorSubtitles[action];

  return {
    id: action,
    status,
    title: t(title),
    subtitle: message || t(subtitle),
  };
};
