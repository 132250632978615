import { BodySmall, Flex, H6, Toggle, Separator } from '@beauty/beauty-market-ui';

export const ApplyForAllDays = ({ title, subtitle, checked, onChange, disabled, color }) => (
  <>
    <Separator />
    <Flex>
      <Flex flexDirection="column" width="100%" gap="4px">
        <H6 color={color}>{title}</H6>
        <BodySmall color={color}>{subtitle}</BodySmall>
      </Flex>
      <Toggle size="small" checked={checked} onChange={onChange} disabled={disabled} />
    </Flex>
  </>
);
