import { forwardRef, memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import {
  Flex,
  getMinWidthMediaQuery,
  H6,
  InfoIcon,
  InstagramMonoIcon,
  ListRegionCode,
  TelegramMonoIcon,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { getListRegionCode, prepareInstagramUserName, prepareTelegramUserName } from 'helpers';
import { FormPropsType } from 'types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectNewAddress, updateMainInformation } from '../../../store/redux-slices/addressSlice';
import { FormikInput } from '../../functional/formik/formik-input/FormikInput';
import { defaultAddressFields } from '../constants';
import { StyledIcon } from '../style';
import { EditAddressFieldsType } from '../types';
import {
  getInitialValues,
  MainInformationFormFields,
  MainInformationFormType,
  mainInformationValidationSchema,
} from './MainInformation.definitions';

type EditableFields = {
  fields?: EditAddressFieldsType['mainInformation'];
};

type MainInformationFormProps = FormPropsType & EditableFields;

export const MainInformationForm = memo(
  forwardRef(
    (
      { setIsValid, setIsDirty, editBar, fields = defaultAddressFields.mainInformation }: MainInformationFormProps,
      ref,
    ) => {
      const { t } = useTranslation();
      const dispatch = useAppDispatch();
      const address = useAppSelector(selectNewAddress);
      const { Name, Email, Number, Instagram, Telegram, Code } = MainInformationFormFields;

      const mediaQuery = getMinWidthMediaQuery('md');
      const isDesktop = useMediaQuery(mediaQuery);

      const onFormSubmit = useCallback((data: MainInformationFormType) => {
        dispatch(
          updateMainInformation({
            ...data,
            instagram: prepareInstagramUserName(data.instagram) || null,
            telegram: prepareTelegramUserName(data.telegram),
          }),
        );
      }, []);

      const formikContextValue = {
        initialValues: getInitialValues(address),
        validationSchema: mainInformationValidationSchema(t),
        onSubmit: onFormSubmit,
        validateOnMount: false,
      };

      return (
        <Formik
          innerRef={ref as (instance: FormikProps<MainInformationFormType> | null) => void}
          {...formikContextValue}
        >
          {({ isValid, errors, values, handleChange, dirty, touched, setFieldValue }) => {
            let captionName = null;
            let captionEmail = null;
            let captionNumber = null;

            if (!editBar && isDesktop) {
              captionName = t('settings.addresses.sidebar.nameDisplayed');
              captionEmail = t('settings.addresses.sidebar.emailDisplayed');
              captionNumber = t('settings.addresses.sidebar.phoneDisplayed');
            }

            useEffect(() => {
              setIsValid && setIsValid(isValid);
            }, [isValid]);

            useEffect(() => {
              setIsDirty && setIsDirty(dirty);
            }, [dirty]);

            return (
              <Form>
                <Flex flexDirection="column" gap="16px">
                  {editBar && <H6>{t('settings.addresses.sidebar.generalInformation')}</H6>}
                  {fields?.name && (
                    <FormikInput
                      id={Name}
                      name={Name}
                      width="100%"
                      design="white"
                      value={values[Name]}
                      onChange={handleChange}
                      captionIcon={<InfoIcon />}
                      placeholder={t('settings.addresses.sidebar.nameBranch')}
                      caption={touched[Name] && !!errors[Name] ? errors[Name] : captionName}
                    />
                  )}
                  {editBar && <H6>{t('settings.addresses.sidebar.contactInformation')}</H6>}
                  {fields?.number && (
                    <Flex gap="8px">
                      <ListRegionCode
                        design="white"
                        options={getListRegionCode()}
                        currentRegionCode={values[Code]}
                        handleSelect={(code: string) => setFieldValue(Code, code)}
                        placeholder={t('settings.addresses.sidebar.region')}
                      />
                      <FormikInput
                        id={Number}
                        name={Number}
                        width="100%"
                        design="white"
                        value={values[Number]}
                        onChange={handleChange}
                        captionIcon={<InfoIcon />}
                        placeholder={t('settings.addresses.sidebar.number')}
                        caption={touched[Number] && !!errors[Number] ? errors[Number] : captionNumber}
                      />
                    </Flex>
                  )}
                  {fields?.email && (
                    <FormikInput
                      id={Email}
                      name={Email}
                      width="100%"
                      design="white"
                      value={values[Email]}
                      onChange={handleChange}
                      captionIcon={<InfoIcon />}
                      placeholder={t('settings.addresses.sidebar.email')}
                      caption={touched[Email] && !!errors[Email] ? errors[Email] : captionEmail}
                    />
                  )}
                  {fields?.instagram && (
                    <FormikInput
                      id={Instagram}
                      name={Instagram}
                      width="100%"
                      design="white"
                      value={values[Instagram]}
                      onChange={e => setFieldValue(Instagram, e.target.value === '' ? '@' : e.target.value)}
                      placeholder={t('settings.addresses.sidebar.instagram')}
                      caption={touched[Instagram] && errors[Instagram]}
                      iconRight={
                        <StyledIcon>
                          <InstagramMonoIcon />
                        </StyledIcon>
                      }
                    />
                  )}
                  {fields?.telegram && (
                    <FormikInput
                      id={Telegram}
                      name={Telegram}
                      width="100%"
                      design="white"
                      value={values[Telegram]}
                      onChange={e => setFieldValue(Telegram, e.target.value)}
                      placeholder={t('settings.addresses.sidebar.telegram')}
                      caption={touched[Telegram] && errors[Telegram]}
                      iconRight={
                        <StyledIcon>
                          <TelegramMonoIcon />
                        </StyledIcon>
                      }
                    />
                  )}
                </Flex>
              </Form>
            );
          }}
        </Formik>
      );
    },
  ),
);
