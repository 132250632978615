import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import {
  Avatar,
  CalendarIcon,
  Flex,
  H6,
  InfoIcon,
  ListRegionCode,
  UploadIcon,
  getMinWidthMediaQuery,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { getListRegionCode } from 'helpers';
import { StyledButton } from '../../../style';
import { SidebarPropsType } from '../../../types';
import { FormikDropdown } from '../../functional/formik/formik-dropdown/FormikDropdown';
import { FormikInput } from '../../functional/formik/formik-input/FormikInput';
import { EditPhoto, SidebarSheet } from '../../index';
import { SidebarFooter } from '../../SidebarFooter';
import { FullName } from '../style';
import { EditEmployeeFormFields } from './EditEmployee.definitions';
import { useEditEmployee } from './useEditEmployee';

const EditEmployee = ({ onSubmit, onClose, onBack }: SidebarPropsType) => {
  const { t } = useTranslation();
  const { sidebarProps, confirmationLabel, positionOptions, accessOptions, formikContextValue, handleClose } =
    useEditEmployee({ onSubmit, onClose, onBack });

  const formRef = useRef<HTMLFormElement>(null);
  const [editPhotoMode, setEditPhotoMode] = useState(false);

  const { Name, Surname, Position, Specialisation, Email, Region, Number, Start, Finish, Access, Photo } =
    EditEmployeeFormFields;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleCloseEditPhoto = () => setEditPhotoMode(false);

  const handleOpenEditPhoto = () => setEditPhotoMode(true);

  return (
    <Formik {...formikContextValue}>
      {({ isValid, handleSubmit, setFieldValue, dirty, isSubmitting, values }) => {
        const footerBody = (
          <SidebarFooter
            isLoading={isSubmitting}
            disabled={!dirty || !isValid}
            confirmationLabel={confirmationLabel}
            onSubmit={handleSubmit}
            onBack={handleClose}
            cancel
          />
        );

        const handleChangePhoto = async (photo: File) => {
          await setFieldValue(Photo, photo);
        };

        const avatarUrl = values[Photo] instanceof File ? URL.createObjectURL(values[Photo] as File) : values[Photo];

        return (
          <SidebarSheet {...sidebarProps} FooterBody={footerBody}>
            <Form ref={formRef}>
              <Flex gap="16px" flexDirection="column" mb="116px">
                <Flex flexDirection="column" gap="8px" alignItems="start" width="max-content">
                  <Avatar
                    size="l"
                    url={avatarUrl}
                    deleteMode={isDesktop ? 'hover' : 'double-tap'}
                    onDelete={() => setFieldValue(Photo, null)}
                  />
                  <StyledButton
                    size="extraSmall"
                    design="tertiary"
                    prefix={<UploadIcon />}
                    onClick={handleOpenEditPhoto}
                  >
                    {`${values[Photo] ? t('uploadPhoto.uploadPhoto') : t('uploadPhoto.newPhoto')} `}
                  </StyledButton>
                </Flex>
                <H6 mt="8px">{t('settings.specialists.generalInfo')}</H6>
                <FullName>
                  <FormikInput design="white" id={Name} name={Name} placeholder={t('registration.name')} />
                  <FormikInput design="white" id={Surname} name={Surname} placeholder={t('registration.surname')} />
                </FullName>
                <FormikDropdown
                  id={Position}
                  name={Position}
                  placeholder={t('settings.specialists.position')}
                  currentOption={values[Position]}
                  options={positionOptions}
                  disabled={positionOptions.length === 1}
                  onChange={index => setFieldValue(Position, index)}
                  caption={t('settings.specialists.positionHint')}
                  captionIcon={<InfoIcon />}
                />
                <FormikInput
                  design="white"
                  id={Specialisation}
                  name={Specialisation}
                  placeholder={t('settings.specialists.specialisation')}
                />
                <H6 mt="8px">{t('settings.specialists.contactInfo')}</H6>
                <Flex gap="8px" width="100%" justifyContent="space-between">
                  <ListRegionCode
                    design="white"
                    backgroudColor="white"
                    id={Region}
                    name={Region}
                    currentRegionCode={values[Region]}
                    placeholder={t('registration.region')}
                    options={getListRegionCode()}
                    handleSelect={code => setFieldValue(Region, code)}
                  />
                  <FormikInput design="white" id={Number} name={Number} placeholder={t('registration.phone')} />
                </Flex>
                <FormikInput design="white" id={Email} name={Email} placeholder={t('settings.head.email')} />
                <H6 mt="8px">{t('settings.specialists.period')}</H6>
                <FormikInput
                  design="white"
                  id={Start}
                  name={Start}
                  iconLeft={<CalendarIcon />}
                  placeholder={t('settings.specialists.start')}
                />
                <FormikInput
                  design="white"
                  id={Finish}
                  name={Finish}
                  iconLeft={<CalendarIcon />}
                  placeholder={t('settings.specialists.finish')}
                />
                <FormikDropdown
                  id={Access}
                  name={Access}
                  placeholder={t('settings.specialists.access')}
                  currentOption={values[Access]}
                  options={accessOptions}
                  disabled={accessOptions.length === 1}
                  onChange={index => setFieldValue(Access, index)}
                  caption={t('settings.specialists.levelHint')}
                  captionIcon={<InfoIcon />}
                  partialVisibility={150}
                  autoScroll
                />
              </Flex>
            </Form>
            {editPhotoMode && <EditPhoto onSubmit={handleChangePhoto} onClose={handleCloseEditPhoto} />}
          </SidebarSheet>
        );
      }}
    </Formik>
  );
};

export default EditEmployee;
