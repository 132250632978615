import { useTranslation } from 'react-i18next';
import { capitalize, isEmpty } from 'lodash';
import { BodyLarge, BodySmall, Caption, colors, Div, Flex, Separator } from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';
import { useTimeList } from 'hooks';

export const OrganisationDay = ({ dayOfWeek, isWorkDay, orgIds, orgMap, separator }) => {
  const { t } = useTranslation();
  const timeList = useTimeList();
  const dayName = t(`daysOfWeek.${capitalize(dayOfWeek)}`);
  const getFormattedTime = (startIndex: number, endIndex: number) =>
    `${timeList[startIndex].item} - ${timeList[endIndex].item}`;
  const getFormattedBreakTime = breaks => {
    if (isEmpty(breaks)) return '';
    return `${t('time.breakTime')} ${breaks
      .map(breakOne => getFormattedTime(breakOne.startIndex, breakOne.endIndex))
      .join(', ')}`;
  };
  const sortByStartIndex = (a, b) => (orgMap[a].startIndex >= orgMap[b].startIndex ? 1 : -1);

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" minHeight="42px" width="100%">
        <Flex flexDirection="column" gap="8px" width="inherit" justifyContent="space-between">
          <BodyLarge large>{dayName}</BodyLarge>
          {isWorkDay &&
            orgIds.sort(sortByStartIndex).map(orgId => {
              const { id, orgName, startIndex, endIndex, breaks } = orgMap[orgId];
              const workTime = getFormattedTime(startIndex, endIndex);
              const breakTime = getFormattedBreakTime(breaks);
              return (
                <Flex key={id} alignItems="start" justifyContent="space-between">
                  <Caption truncated color={colors.grey.dark} title={orgName}>
                    {orgName}
                  </Caption>
                  <Flex flexDirection="column" ml="auto" alignItems="end" flexShrink={0}>
                    <Adaptive desktop={BodyLarge} mobile={BodySmall} large>
                      {workTime}
                    </Adaptive>
                    <Caption color={colors.grey.dark}>{breakTime}</Caption>
                  </Flex>
                </Flex>
              );
            })}
        </Flex>
        <Div flexShrink={0}>
          {!isWorkDay && (
            <Adaptive mobile={BodyLarge} desktop={BodySmall} large>
              {t('daysOfWeek.dayOff')}
            </Adaptive>
          )}
        </Div>
      </Flex>
      {separator && <Separator m="16px 0" />}
    </>
  );
};
