import { withTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { BMTheme, theme } from "@beauty/beauty-market-ui";
import Router from "./routes/Router";
import { AppWrapper } from "./style";

const App = () => (
    <BMTheme>
      <ThemeProvider {...{ theme }}>
        <AppWrapper>
          <Router />
        </AppWrapper>
      </ThemeProvider>
    </BMTheme>
)

export default withTranslation()(App);
