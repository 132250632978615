import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { getMinWidthMediaQuery, H5, H6, Scroll, Separator, useMediaQuery } from '@beauty/beauty-market-ui';
import LogoutImage from 'assets/exit-image.png';
import { Loader, Stub } from 'components';
import { useEndScroll, useGetClientReviews } from 'hooks';
import { Wrapper } from '../../style';
import { ReviewsList } from './ReviewsList';

export const Reviews = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [reviews, isLoading, error] = useGetClientReviews(id, page);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const getMore = useCallback(() => {
    !error && setPage(page + 1);
  }, [setPage, error, page]);

  const scrollRef = useEndScroll(getMore);

  return (
    <Wrapper delta="320px">
      {isDesktop ? <H5 mb="24px">{t('clients.profile.reviews')}</H5> : <H6 mb="0">{t('clients.profile.reviews')}</H6>}
      {isDesktop && <Separator mb="16px" />}
      {!isLoading && isEmpty(reviews) && (
        <Stub
          title={t('clients.profile.noReviews')}
          src={LogoutImage}
          alt={t('clients.profile.noReviews')}
          margin="auto"
        />
      )}
      <Scroll ref={scrollRef}>
        <ReviewsList reviews={reviews} />
        {isLoading && <Loader />}
      </Scroll>
    </Wrapper>
  );
};
