import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Flex } from '@beauty/beauty-market-ui';
import { SidebarFooter } from 'components/SidebarFooter/SidebarFooter';
import { SidebarSheet } from '../../SidebarSheet';
import { MainInformationFormType } from './MainInformation.definitions';
import { MainInformationForm } from './MainInformationForm';

export const MainInformation = ({ handleClose, handleBackStep, handleNextStep, stepper }) => {
  const { t } = useTranslation();
  const [isValid, setIsValid] = useState(false);
  const formRef = useRef<FormikProps<MainInformationFormType>>();

  const handleSubmit = async () => {
    await formRef.current?.submitForm();
    handleNextStep();
  };

  const footer = <SidebarFooter disabled={!isValid} onSubmit={handleSubmit} onBack={handleBackStep} cancel />;
  const content = <MainInformationForm ref={formRef} setIsValid={setIsValid} />;

  return (
    <SidebarSheet
      onClose={handleClose}
      onBack={handleBackStep}
      FooterBody={footer}
      label={t('settings.addresses.sidebar.workplace')}
      descriptor={t('settings.addresses.sidebar.informationDisplayed')}
    >
      <Flex flexDirection="column" gap="16px">
        {stepper}
        {content}
      </Flex>
    </SidebarSheet>
  );
};
