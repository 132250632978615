import styled from 'styled-components';
import { Button, MenuDotsIcon, colors, Flex } from '@beauty/beauty-market-ui';

export const StyledMenuDotsIcon = styled(MenuDotsIcon)`
  width: 22px;
  height: 22px;
  path {
    stroke: ${colors.black.standard};
  }
`;

export const StyledButton = styled(Button)<{ isGrey: boolean; isShowDots: boolean }>`
  position: relative;
  padding: 6px 0px !important;
  ${({ isGrey }) => isGrey && `background-color: ${colors.grey.light}; !important`}
  path {
    stroke: ${colors.black.standard} !important;
  }
  ${StyledMenuDotsIcon} {
    display: ${({ isShowDots }) => (isShowDots ? 'flex' : 'none')};
  }
`;

export const ContextMenuWrapper = styled(Flex)<{ menuAtTop?: boolean }>`
  position: absolute;
  z-index: 10;
  right: 0px;
  ${({ menuAtTop }) => (menuAtTop ? 'bottom: 40px;' : 'top: 40px;')}
`;
