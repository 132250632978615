import React from 'react';
import {
  Avatar,
  AvatarSizesKey,
  BodyLarge,
  BodySmall,
  Button,
  Caption,
  colors,
  Flex,
  getMinWidthMediaQuery,
  H6,
  Icon,
  Separator,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { Container } from '../style';

export type ClientCardProps = {
  heading?: string;
  subtitle?: string;
  onClick?: () => void;
  icon?: React.ReactElement;
  avatar?: string | null | File;
  avatarSize?: AvatarSizesKey;
};

export const ClientCard = ({ heading, subtitle, onClick, icon, avatar, avatarSize = 'xs' }: ClientCardProps) => {
  const photo = <Avatar size={avatarSize} url={avatar || undefined} anonymos="default" />;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  return (
    <>
      <Container onClick={onClick}>
        {photo}
        <Flex flexDirection="column" gap="2px" mr="auto">
          {isDesktop ? <H6>{heading}</H6> : <BodyLarge large>{heading}</BodyLarge>}
          {isDesktop ? (
            <BodySmall color={colors.grey.dark}>{subtitle}</BodySmall>
          ) : (
            <Caption color={colors.grey.dark}>{subtitle}</Caption>
          )}
        </Flex>
        {icon && (
          <Button
            design="tertiaryBlack"
            prefix={
              <Icon width="20px" height="20px">
                {icon}
              </Icon>
            }
          />
        )}
      </Container>
      <Separator ml="56px" />
    </>
  );
};
