import styled from 'styled-components';
import { Flex, colors, MenuDotsIcon, Scroll, CheckIcon } from '@beauty/beauty-market-ui';
import { zIndex } from '../../../constants';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  min-height: 90px;
  justify-content: space-between;
  background-color: ${colors.white.standard};
  border-radius: 16px;
  padding: 24px;
  box-sizing: border-box;
  gap: 16px;
  margin-bottom: 16px;
  /*  button {
    color: ${colors.black.standard};
  } */
`;

export const Dots = styled(MenuDotsIcon)`
  width: 22px;
  height: 22px;
  :hover {
    cursor: pointer;
  }
`;

export const MenuWrapper = styled(Flex)`
  width: 32px;
  height: 32px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${colors.grey.light};
  align-items: center;
`;

export const ContextMenuWrapper = styled(Flex)`
  position: absolute;
  z-index: 4;
  top: 64px;
  right: 24px;
`;

export const KebabWrapper = styled(Flex)`
  position: absolute;
  right: 16px;
  & > div > div > div {
    background-color: ${colors.white.standard};
  }
`;

export const DropdownMenuWrapper = styled(Flex)`
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0px;
`;

export const DropdownWrapper = styled.ul`
  cursor: pointer;
  z-index: ${zIndex.dropdown};
  position: absolute;
  top: 12px;
  right: 1px;
  width: 170px;
  padding: 4px;
  list-style-type: none;
  background: ${({ theme }) => theme.colors.white.standard};
  box-shadow:
    0px 0px 2px rgba(0, 0, 0, 0.08),
    0px 2px 24px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
`;

export const ItemWrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;

export const CheckIconStyled = styled(CheckIcon)`
  width: 16px;
  height: 16px;
  path {
    stroke: ${({ theme }) => theme.colors.blue.standard};
  }
`;

export const MobileHeaderWrapper = styled(Flex)`
  position: relative;
  height: 124px;
  padding: 8px 8px 16px 8px;
  background-color: ${colors.white.standard};
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
`;
