import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { useRequest } from 'hooks';
import { getSelectedLanguage } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  selectAllSpecialists,
  selectSpecialistState,
  ThunkSpecialist,
  updateType,
} from '../../../store/redux-slices/specialistSlice';
import { selectUserHead } from '../../../store/redux-slices/userSlice';
import { ErrorDataType, SidebarPropsType, SpecialistAction } from '../../../types';
import { getAccessOptions, getErrorMessage, getPositionOptions } from '../helpers';
import { EditEmployeeForm, initialValues, validationSchema } from './EditEmployee.definitions';

export const useEditEmployee = ({ onSubmit, onClose, onBack }: SidebarPropsType) => {
  const { t } = useTranslation();
  const { type, currentSpecialist } = useAppSelector(selectSpecialistState);
  const userHead = useAppSelector(selectUserHead);
  const staff = useAppSelector(selectAllSpecialists);
  const notify = useNotify();

  const createSpecialist = useRequest(ThunkSpecialist.createSpecialist, SpecialistAction.Add);
  const updateSpecialist = useRequest(ThunkSpecialist.editSpecialist, SpecialistAction.Edit);
  const deleteAvatar = useRequest(ThunkSpecialist.deleteAvatar, SpecialistAction.None);

  const dispatch = useAppDispatch();
  const language = getSelectedLanguage();

  const isOwner = currentSpecialist.accessLevel === 'OWNER';
  const isOnlyOwner = isOwner && staff.length === 1;

  const positionOptions = useMemo(() => getPositionOptions(isOnlyOwner, t), [isOnlyOwner, t]);
  const accessOptions = useMemo(() => getAccessOptions(isOwner, t), [isOwner, t]);

  const handleClose = useCallback(() => {
    dispatch(updateType(SpecialistAction.None));
    onClose && onClose();
  }, [dispatch, onClose]);

  const handleSubmit = (data: EditEmployeeForm, { setFieldError, setSubmitting }) => {
    const { accessEnd, photo, accessLevel, role, accessStart, specialization, email, number, code, name, surname } =
      data;

    const params = {
      id: currentSpecialist.id || userHead?.headOrgId,
      name: name.trim(),
      surname: surname.trim(),
      code: code.trim(),
      number: number.trim(),
      email: email.trim(),
      role: positionOptions[role].id,
      accessLevel: accessOptions[accessLevel].id,
      accessStart: moment(accessStart, 'DDMMYYYY').toDate(),
      accessEnd: accessEnd ? moment(accessEnd, 'DDMMYYYY').toDate() : undefined,
      specialization,
      language,
    };

    const extendedParams = photo instanceof File ? { ...params, file: photo } : params;

    const handleError = (err: ErrorDataType) => {
      const message = getErrorMessage(err, t);
      if (message) {
        setFieldError(message.field, message.error);
        notify({
          id: message.error,
          status: NotifyStatus.ERROR,
          title: t('alerts.error'),
          subtitle: message.error,
        });
      }
      throw err;
    };

    setSubmitting(true);

    const request = type === SpecialistAction.Add ? createSpecialist(extendedParams) : updateSpecialist(extendedParams);
    request
      .catch(handleError)
      .then(() => {
        onSubmit && onSubmit();
        handleClose();
      })
      .finally(() => setSubmitting(false));

    if (currentSpecialist.id && currentSpecialist.account.avatarUrl && photo === null) {
      deleteAvatar(currentSpecialist.id);
    }
  };

  const formikContextValue = {
    initialValues: initialValues(currentSpecialist, positionOptions, accessOptions),
    validationSchema: validationSchema(t),
    onSubmit: handleSubmit,
    validateOnMount: true,
  };

  const label =
    type === SpecialistAction.Add
      ? t('settings.specialists.add')
      : `${t('settings.specialists.edit')}${currentSpecialist.account.name} ${currentSpecialist.account.surname}`;

  const descriptor =
    type === SpecialistAction.Add ? t('settings.specialists.addHint') : t('settings.specialists.editHint');

  const confirmationLabel = type === SpecialistAction.Add ? t('button.save') : t('button.applyChanges');

  const sidebarProps = {
    isOpen: true,
    label,
    descriptor,
    onClose: handleClose,
    onBack,
  };

  return {
    sidebarProps,
    accessOptions,
    positionOptions,
    formikContextValue,
    confirmationLabel,
    handleClose,
  };
};
