import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { FormikTextfield } from 'components/functional/formik/formik-textfield/FormikTextfield';
import { SidebarFooter, SidebarSheet } from 'components/index';
import { EditDescriptionFormFields, EditDescriptionFormType, schema } from './EditDescription.defenitions';

type Props = {
  title: string;
  subtitle: string;
  onClose: () => void;
  onSubmit: (data: EditDescriptionFormType) => Promise<void>;
  onBack?: () => void;
  defaultValue?: string | null;
};

export const EditDescription = ({ defaultValue, onClose, onSubmit, title, subtitle, onBack }: Props) => {
  const { t } = useTranslation();
  const { Description } = EditDescriptionFormFields;

  const formikContextValue = {
    initialValues: { description: defaultValue ?? '' },
    validationSchema: schema(t),
    validateOnMount: false,
    onSubmit,
  };

  return (
    <Formik {...formikContextValue}>
      {({ isValid, errors, values, handleChange, dirty, touched, isSubmitting, handleSubmit: handleSubmitForm }) => (
        <Form>
          <SidebarSheet
            label={title}
            descriptor={subtitle}
            onClose={onClose}
            onBack={onBack}
            FooterBody={
              <SidebarFooter
                onSubmit={handleSubmitForm}
                onBack={onClose}
                isLoading={isSubmitting}
                disabled={!isValid || !dirty}
                save
              />
            }
          >
            <FormikTextfield
              id={Description}
              name={Description}
              rows={8}
              width="100%"
              design="white"
              onChange={handleChange}
              value={values[Description]}
              defaultValue={values[Description]}
              placeholder={t('common.description')}
              caption={touched[Description] && !!errors[Description] ? errors[Description] : ''}
            />
          </SidebarSheet>
        </Form>
      )}
    </Formik>
  );
};
