import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { AddressType } from 'types/organisation';
import { emailReg, generalInstagramReg } from '../../../constants';

export enum MainInformationFormFields {
  Name = 'name',
  Number = 'number',
  Code = 'code',
  Email = 'email',
  Instagram = 'instagram',
  Telegram = 'telegram',
}

export type MainInformationFormType = {
  [MainInformationFormFields.Name]: string;
  [MainInformationFormFields.Number]: string;
  [MainInformationFormFields.Code]: string;
  [MainInformationFormFields.Email]: string;
  [MainInformationFormFields.Instagram]: string | null;
  [MainInformationFormFields.Telegram]: string;
};

export const getInitialValues = (address: AddressType) => ({
  [MainInformationFormFields.Name]: address.name,
  [MainInformationFormFields.Email]: address.email,
  [MainInformationFormFields.Number]: address.phone.number,
  [MainInformationFormFields.Code]: address.phone.code,
  [MainInformationFormFields.Instagram]: `@${address.social?.instagram ?? ''}`,
  [MainInformationFormFields.Telegram]: `${address.social?.telegram ?? ''}`,
});

export const mainInformationValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [MainInformationFormFields.Name]: Yup.string()
      .trim()
      .required(t('validation.notEmpty'))
      .min(2, t('validation.minLength2'))
      .max(100, t('validation.maxLength100')),
    [MainInformationFormFields.Number]: Yup.string()
      .typeError(t('validation.wrongPhone'))
      .max(15, t('validation.maxLength15'))
      .matches(/^\d{7,}$/, t('validation.enterValidPhone'))
      .required(t('validation.phoneRequired')),
    [MainInformationFormFields.Email]: Yup.string()
      .trim()
      .email(t('validation.emailNotValid'))
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [MainInformationFormFields.Instagram]: Yup.string().matches(generalInstagramReg, t('validation.instagramNotValid')),
    [MainInformationFormFields.Telegram]: Yup.string(),
  });
