import { checkAccess } from 'helpers';
import { AccessRange } from '../../../../constants';
import { AddressActivities, AddressServices, Employees, WorkingTime, DeleteAddress } from '../components';

export const GeneralData = () => {
  const isOwner = checkAccess(AccessRange.OWNER);
  return (
    <>
      <AddressActivities />
      <AddressServices />
      <Employees />
      <WorkingTime />
      {isOwner && <DeleteAddress />}
    </>
  );
};
