import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { ImageType, WorkTimeType } from 'types';
import {
  AddressFormType,
  AddressPayloadType,
  AddressStateType,
  GeolocationType,
  OrganisationServiceType,
  OrganizationPayloadType,
  WeekDay,
  WorkDayType,
} from 'types/organisation';
import { DetailedInformationFormType } from '../../components';
import { GoogleStatusCode } from '../../constants';
import { CreateAddressParams, organisationAPI } from '../../helpers/organisationAPI';
import { clearAccountState, clearCurrentData, logout } from '../actions/common';
import { editDescription, editEmployees, editPhotos, editPhotosChunk, updatePublish } from '../asyncActions/address';
import { deleteAddress } from '../asyncActions/common';
import { createAppAsyncThunk } from '../create-app-async-thunk';
import { RootState } from '../store';
import {
  checkRequiredFields,
  extractAddressDetailedInformation,
  extractMainAddressInformation,
  extractOrganizationPhotos,
  extractOrgBusinessHours,
  extractWeek,
  getUpdatedDataForEditOrganization,
  packPhotoStoreToFormData,
  parseAddress,
} from '../utils';

const initialState: AddressStateType = {
  isLoading: false,
  allAddress: [],
  selectedOrgId: undefined,
  newAddress: {
    id: '',
    name: '',
    email: '',
    description: '',
    property: [],
    address: {
      id: '',
      country: '',
      city: '',
      street: '',
      building: '',
      office: '',
      postal: '',
      fullAddress: '',
      description: '',
    },
    phone: {
      id: '',
      code: '',
      number: '',
    },
    social: {},
    photos: {
      mainPhoto: '',
      photos: [],
      deletePhotos: [],
      photoStore: [],
    },
    schedule: [],
    prevSchedule: [],
    isSave: false,
    allDayTemplate: null,
    orgService: [],
    orgSpecialist: [],
    orgSpecialistService: [],
    categories: [],
    published: false,
  },
  geolocation: {
    lng: null,
    lat: null,
  },
  initialValues: {},
};

const createNewAddress = createAppAsyncThunk<AddressPayloadType, void>(
  'address/createNewAddress',
  async (_, thunkAPI) => {
    const organisation = thunkAPI.getState().address.newAddress;
    const { office, postal, building, street, city, country, fullAddress } = organisation.address;
    const { lat, lng } = thunkAPI.getState().address.geolocation;

    const data: CreateAddressParams = {
      id: thunkAPI.getState().organisation.head.id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      orgBusinessHours: extractOrgBusinessHours(organisation),
      name: organisation.name,
      email: organisation.email,
      phone: {
        code: organisation.phone.code,
        number: organisation.phone.number,
      },
      description: organisation.description || null,
      property: organisation.property.join(','),
      address: {
        country,
        city,
        street,
        building,
        office: office || null,
        postal: postal || null,
        lat: lat || 0,
        lng: lng || 0,
        fullAddress: fullAddress || `${street} ${building}, ${city}, ${country}`,
        description: organisation.address.description || null,
      },
    };

    if (organisation.social.telegram) {
      data.telegram = organisation.social.telegram;
    }

    if (organisation.social.instagram) {
      data.instagram = organisation.social.instagram;
    }

    return organisationAPI.createAddress({ data, thunkAPI });
  },
);

const fetchAddress = createAppAsyncThunk<AddressFormType, GeolocationType>(
  'address/fetchAddress',
  async ({ lat, lng, language }, { getState, rejectWithValue }) => {
    try {
      const { address } = getState().address.newAddress;
      const res = await organisationAPI.fetchAddress({ lat, lng, language });
      if (res && res.status === GoogleStatusCode.OK) {
        const { results } = res;
        return parseAddress(results[0], address);
      }
      return rejectWithValue(null);
    } catch (error) {
      return rejectWithValue(null);
    }
  },
);

const fetchCoordinates = createAppAsyncThunk<GeolocationType & { fullAddress: string }, void>(
  'address/fetchCoordinates',
  async (_, { rejectWithValue, getState }) => {
    const { country, city, street, building } = getState().address.newAddress.address;
    try {
      const res = await organisationAPI.fetchCoordinates({ country, city, street, building });
      if (res && res.status === GoogleStatusCode.OK) {
        const { results } = res;
        const { lat, lng } = results[0].geometry.location;
        const fullAddress = results[0].formatted_address;
        return { lat, lng, fullAddress };
      }
      return rejectWithValue(null);
    } catch (e) {
      return rejectWithValue(null);
    }
  },
);

const addPhotos = createAppAsyncThunk('address/addPhotos', async (_, thunkAPI) => {
  const { photos } = thunkAPI.getState().address.newAddress;
  const { id } = thunkAPI.getState().address.newAddress;
  if (id) {
    const data = packPhotoStoreToFormData(photos);
    return organisationAPI.updatePhotos({ id, data, thunkAPI });
  }
  return thunkAPI.rejectWithValue("id doesn't exist");
});

const fetchAllAddresses = createAppAsyncThunk<OrganizationPayloadType[], string>(
  'address/fetchAllAddresses',
  async id => organisationAPI.fetchAllAddresses(id),
);

const fetchAddressById = createAppAsyncThunk<AddressPayloadType, string>('address/fetchAddressById', id =>
  organisationAPI.fetchAddressById(id),
);

const fetchServicesForEmployee = createAppAsyncThunk<OrganisationServiceType[], string>(
  'address/fetchServicesForEmployee',
  (id, thunkAPI) => organisationAPI.fetchServicesForEmployee({ id, thunkAPI }),
);

const editOrganization = createAppAsyncThunk('address/editOrganization', (_, thunkAPI) => {
  const addressState = thunkAPI.getState().address;
  const { id } = addressState.newAddress;
  if (id) {
    const data = getUpdatedDataForEditOrganization(addressState);
    return organisationAPI.editOrganization({ id, data, thunkAPI });
  }
  throw new Error("id doesn't exist");
});

export const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    setSave: (state, action: PayloadAction<boolean>) => {
      state.newAddress.isSave = action.payload;
    },
    updateMainInformation: (state, action) => {
      if (action.payload.name) state.newAddress.name = action.payload.name;
      if (action.payload.email) state.newAddress.email = action.payload.email;
      if (action.payload.number) state.newAddress.phone.number = action.payload.number;
      if (action.payload.code) state.newAddress.phone.code = action.payload.code;
      if (action.payload.instagram || action.payload.instagram === null) {
        state.newAddress.social.instagram = action.payload.instagram;
      }
      if (action.payload.telegram || action.payload.telegram === '') {
        state.newAddress.social.telegram = action.payload.telegram;
      }
    },
    updatePhoneCode: (state, action) => {
      state.newAddress.phone.code = action.payload;
    },
    updateDetailedInformation: (state, action: PayloadAction<DetailedInformationFormType>) => {
      state.newAddress.address = { ...state.newAddress.address, ...action.payload };
    },
    setGeolocation: (state, action: PayloadAction<GeolocationType>) => {
      state.geolocation.lng = action.payload.lng;
      state.geolocation.lat = action.payload.lat;
    },
    updateDayTime: (state, action: PayloadAction<WorkDayType>) => {
      const index = state.newAddress.schedule.findIndex(item => item.dayOfWeek === action.payload.dayOfWeek);
      if (index !== -1) {
        state.newAddress.schedule[index] = action.payload;
      } else {
        state.newAddress.schedule.push(action.payload);
      }
      const hasWorkDays = state.newAddress.schedule.filter(checkRequiredFields);
      state.newAddress.isSave = hasWorkDays.length > 0;
    },
    setAllDay: (state, action: PayloadAction<WorkTimeType | null>) => {
      if (action.payload) {
        state.newAddress.allDayTemplate = WeekDay.map(dayOfWeek => ({
          isWorkDay: true,
          dayOfWeek,
          start: action.payload?.start || '00:00',
          end: action.payload?.end || '00:15',
          breakStart: action.payload?.breakStart || null,
          breakEnd: action.payload?.breakEnd || null,
        }));
      } else {
        state.newAddress.allDayTemplate = null;
      }
    },
    updateMainPhoto: (state, action: PayloadAction<ImageType>) => {
      const { mainPhoto } = state.newAddress.photos;
      const index = state.newAddress.photos.photoStore.findIndex(item => item.url === mainPhoto);
      if (index !== -1) {
        const photo = state.newAddress.photos.photoStore.splice(index, 1);
        state.newAddress.photos.deletePhotos.push(photo[0]);
      }
      state.newAddress.photos.mainPhoto = action.payload;
    },
    updateAddressPhotos: (state, action: PayloadAction<ImageType[]>) => {
      state.newAddress.photos.photos = action.payload;
    },
    deleteAddressPhoto: (state, action: PayloadAction<ImageType>) => {
      const index = state.newAddress.photos.photoStore.findIndex(item => item.url === action.payload);
      if (index !== -1) {
        const photo = state.newAddress.photos.photoStore.splice(index, 1);
        state.newAddress.photos.deletePhotos.push(photo[0]);
      }
      state.newAddress.photos.photos = state.newAddress.photos.photos.filter(item => item !== action.payload);
    },
    setSchedule: (state, action: PayloadAction<WorkDayType[]>) => {
      state.newAddress.schedule = action.payload;
    },
    updateSelectedAddressId: (state, action: PayloadAction<string>) => {
      state.selectedOrgId = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(createNewAddress.fulfilled, (state, action) => {
        const address = {
          id: action.payload.id,
          name: action.payload.name,
          iconUrl: action.payload?.mainPhoto?.url || null,
          published: false,
          address: action.payload.address.address[0],
        };
        state.allAddress.push(address);
        extractAddressDetailedInformation(state, action.payload);
        extractMainAddressInformation(state, action.payload);
        const week = extractWeek(action.payload.orgBusinessHours);
        state.newAddress.schedule = week;
        state.newAddress.prevSchedule = week;
        state.newAddress.categories = [];
        state.newAddress.orgSpecialist = [];
        state.newAddress.orgSpecialistService = [];
        state.newAddress.orgService = [];

        state.newAddress.isSave = true;
        state.newAddress.allDayTemplate = null;
      })
      .addCase(addPhotos.fulfilled, (state, action) => {
        const index = state.allAddress.findIndex(org => org.id === action.payload.id);
        if (index !== -1) state.allAddress[index].mainPhoto = action.payload.mainPhoto;
        extractOrganizationPhotos(state, action.payload.mainPhoto, action.payload.photo);
      })
      .addCase(editPhotos.fulfilled, (state, action) => {
        extractOrganizationPhotos(state, action.payload.mainPhoto, action.payload.photo);
      })
      .addCase(editPhotosChunk.fulfilled, (state, action) => {
        const index = state.allAddress.findIndex(org => org.id === action.payload.id);
        if (index !== -1) state.allAddress[index].mainPhoto = action.payload.mainPhoto;
        extractOrganizationPhotos(state, action.payload.mainPhoto, action.payload.photo);
      })
      .addCase(fetchAddress.fulfilled, (state, action) => {
        state.newAddress.address = { id: state.newAddress.address.id, ...action.payload };
      })
      .addCase(fetchCoordinates.fulfilled, (state, action) => {
        state.geolocation.lng = action.payload.lng;
        state.geolocation.lat = action.payload.lat;
        state.newAddress.address.fullAddress = action.payload.fullAddress;
      })
      .addCase(fetchAllAddresses.fulfilled, (state, action) => {
        state.allAddress = action.payload
          .filter(org => !org.deleted)
          .map(org => ({
            id: org.id,
            name: org.name,
            address: org.address.address[0],
            mainPhoto: org.mainPhoto || null,
            published: org.published || false,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        state.selectedOrgId = action.payload[0].id;
      })
      .addCase(fetchAllAddresses.rejected, state => {
        state.allAddress = [];
      })
      .addCase(clearCurrentData, state => {
        state.newAddress = initialState.newAddress;
        state.geolocation = initialState.geolocation;
        state.initialValues = initialState.initialValues;
      })
      .addCase(fetchAddressById.fulfilled, (state, action) => {
        const week = extractWeek(action.payload.orgBusinessHours);
        extractAddressDetailedInformation(state, action.payload);
        extractMainAddressInformation(state, action.payload);
        extractOrganizationPhotos(state, action.payload.mainPhoto, action.payload.photo);

        state.newAddress.schedule = week;
        state.newAddress.prevSchedule = week;
        state.newAddress.isSave = false;

        state.newAddress.published = action.payload.published || false;
        state.newAddress.orgSpecialist = action.payload?.orgSpecialist || [];
        state.newAddress.orgService = action.payload?.orgService || [];
        state.newAddress.categories = action.payload?.category || [];
      })
      .addCase(editOrganization.fulfilled, (state, action) => {
        extractMainAddressInformation(state, action.payload);
        extractAddressDetailedInformation(state, action.payload);

        const index = state.allAddress.findIndex(item => item.id === action.payload.id);
        if (index !== -1) {
          state.allAddress[index].name = action.payload.name;
          state.allAddress[index].address = action.payload.address.address;
        }

        if (state.newAddress.id) {
          const week = extractWeek(action.payload.orgBusinessHours);
          state.newAddress.schedule = week;
          state.newAddress.prevSchedule = week;
        }
      })
      .addCase(editEmployees.fulfilled, (state, action) => {
        state.newAddress.orgSpecialist = action.payload.data;
        if (!action.payload.publishedCheck) state.newAddress.published = false;
      })
      .addCase(updatePublish.fulfilled, (state, action) => {
        const { id, published } = action.meta.arg;
        if (action.payload.success) {
          const index = state.allAddress.findIndex(item => item.id === id);
          if (index !== -1) {
            state.allAddress[index].published = published;
          }
          state.newAddress.published = published;
        }
      })
      .addCase(fetchServicesForEmployee.fulfilled, (state, action) => {
        state.newAddress.orgSpecialistService = action.payload;
      })
      .addCase(editDescription.fulfilled, (state, action) => {
        state.newAddress.description = action.payload.description || '';
        state.newAddress.property = action.payload.property || [];
      })
      .addCase(deleteAddress.fulfilled, (state, action) => {
        const { meta, payload } = action;
        if (payload.success) {
          const index = state.allAddress.findIndex(org => org.id === meta.arg);
          index !== -1 && state.allAddress.splice(index, 1);
          state.newAddress = initialState.newAddress;
        }
      })
      .addCase(clearAccountState, () => initialState)
      .addCase(logout, () => initialState)
      .addMatcher(isAnyOf(fetchAddressById.rejected), state => {
        state.newAddress = initialState.newAddress;
      })
      .addMatcher(isAnyOf(editOrganization.rejected, createNewAddress.rejected), state => {
        state.isLoading = false;
      })
      .addMatcher(isAnyOf(editOrganization.pending, createNewAddress.pending), state => {
        state.isLoading = true;
      })
      .addMatcher(isAnyOf(editOrganization.fulfilled, createNewAddress.fulfilled), state => {
        state.isLoading = false;
      });
  },
});

export const {
  updateMainInformation,
  updateDetailedInformation,
  setGeolocation,
  updateDayTime,
  updatePhoneCode,
  setSchedule,
  updateMainPhoto,
  updateAddressPhotos,
  deleteAddressPhoto,
  setSave,
  updateSelectedAddressId,
  setAllDay,
} = addressSlice.actions;

export const selectAddressState = (state: RootState) => state.address;
export const selectNewAddress = (state: RootState) => state.address.newAddress;
export const selectGeolocation = (state: RootState) => state.address.geolocation;
export const selectAllAddresses = (state: RootState) => state.address.allAddress;
export const selectAddressPhotos = (state: RootState) => state.address.newAddress.photos;
export const selectSchedule = (state: RootState) => state.address.newAddress.schedule;

export const ThunkAddress = {
  createNewAddress,
  fetchAddress,
  fetchCoordinates,
  addPhotos,
  fetchAllAddresses,
  fetchAddressById,
  editOrganization,
  editDescription,
  updatePublish,
  editEmployees,
  editPhotos,
  editPhotosChunk,
  deleteAddress,
  fetchServicesForEmployee,
};

export default addressSlice.reducer;
