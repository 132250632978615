import { ReactNode, useEffect, useState } from 'react';
import {
  BodySmall,
  Caption,
  colors,
  Flex,
  getMinWidthMediaQuery,
  H5,
  H6,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';
import { Heading, StyledDownIcon, Wrapper } from './style';

type Props = {
  title: string;
  description?: string;
  placeholder?: string;
  position?: 'first' | 'middle' | 'last' | 'default';
  count?: number;
  visibleCount?: number;
  badge?: ReactNode;
  button?: ReactNode;
  emptyButton?: ReactNode;
  separator?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
};

export const InfoBlock = ({
  children,
  title,
  description,
  placeholder,
  separator = null,
  badge = null,
  count = 0,
  visibleCount = 7,
  position = 'middle',
  button = null,
  emptyButton = null,
}: Props) => {
  const [expanded, setExpanded] = useState(count <= visibleCount);

  const isEmptyBody = count < 1;
  const isArrow = count > visibleCount;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const defaultGap = isDesktop ? '7px' : '14px';

  useEffect(() => setExpanded(count <= visibleCount), [count, visibleCount]);

  return (
    <Wrapper position={position}>
      <Heading isEmpty={isEmptyBody}>
        <Flex flexDirection="column" gap={description ? defaultGap : 0} width="100%">
          <Flex alignItems="center" gap="8px" onClick={isArrow ? () => setExpanded(prev => !prev) : null} width="100%">
            {isArrow && <StyledDownIcon $isUp={expanded} />}
            <Adaptive desktop={H5} mobile={H6}>
              {title}
            </Adaptive>
            {badge}
          </Flex>
          {isEmptyBody ? (
            <Adaptive desktop={BodySmall} mobile={Caption}>
              {description}
            </Adaptive>
          ) : null}
        </Flex>
        <Flex minWidth="max-content" flexShrink={0}>
          {(isDesktop || !isEmptyBody) && button}
        </Flex>
      </Heading>
      {expanded && (
        <Flex flexDirection="column" width="iherit">
          {separator}
          {isEmptyBody && <Caption color={colors.grey.dark}>{placeholder}</Caption>}
          {isEmptyBody && !isDesktop && emptyButton}
          {children}
        </Flex>
      )}
    </Wrapper>
  );
};
