import { Fragment, MouseEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import join from 'lodash/join';
import {
  Avatar,
  Badge,
  BodyLarge,
  Button,
  Caption,
  colors,
  EditIcon,
  Flex,
  getMinWidthMediaQuery,
  Link,
  Separator,
  TrashIcon,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { InfoBlock, PopUp, Tooltip } from 'components';
import { formatPhoneNumber, getFullName, getIconByStatus } from 'helpers';
import { useRequest } from 'hooks';
import { RouterUrl } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectNewAddress, ThunkAddress } from 'store/redux-slices/addressSlice';
import { AddressAction, AddressSpecialistType } from 'types';
import { getTooltipInfo } from '../../helpers';
import { Box, IconBox } from '../../style';
import { EditEmployees } from './EditEmployees';

export const Employees = () => {
  const { t } = useTranslation();
  const { orgSpecialist } = useAppSelector(selectNewAddress);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [specialist, setSpecialist] = useState<AddressSpecialistType | null>(null);
  const [action, setAction] = useState<AddressAction>(AddressAction.None);
  const [isSubmitting, setSubmitting] = useState(false);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const deleteEmployee = useRequest(ThunkAddress.editEmployees, AddressAction.EditEmployees);

  const specialistItems = isArray(orgSpecialist)
    ? orgSpecialist.map((spec, index, arr) => {
        const handleSpecialistClick = () => {
          navigate(generatePath(RouterUrl.EmployeeGeneralData, { id: spec.headOrgSpecId }));
        };

        const handleDeleteSpecialistClick = (e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          setSpecialist(spec);
          setAction(AddressAction.DeleteEmployee);
        };

        const isManager = spec.role === 'MANAGER';
        const tooltipId = `status-${spec.headOrgSpecId}`;
        const tooltipInfo = getTooltipInfo(spec, t);
        const iconStatus = isManager || tooltipInfo.length === 1 ? 'completed' : 'pending';
        const phone = formatPhoneNumber(spec.code, spec.number);
        const contact = join([phone, spec.email].filter(Boolean), ' • ');

        return (
          <Fragment key={spec.headOrgSpecId}>
            <Box onClick={handleSpecialistClick} available cursor="pointer">
              <Avatar size="xs" url={spec.avatarUrl} anonymos={!spec?.avatarUrl ? 'default' : undefined} rating="" />
              <Flex flexDirection="column" ml="8px" mr="auto">
                <Caption color={colors.grey.dark}>{spec.specialization}</Caption>
                <BodyLarge large>{getFullName(spec)}</BodyLarge>
                <Caption color={colors.grey.dark}>{contact}</Caption>
              </Flex>
              <Tooltip id={tooltipId} content={tooltipInfo} show={iconStatus === 'pending'} place="right">
                {getIconByStatus(tooltipId, iconStatus)}
              </Tooltip>
              <IconBox ml="16px">
                <Link design="gray" size="md" icon={<TrashIcon />} onClick={handleDeleteSpecialistClick} />
              </IconBox>
            </Box>
            {index !== arr.length - 1 && <Separator my="16px" />}
          </Fragment>
        );
      })
    : [];

  const handleClose = useCallback(() => setAction(AddressAction.None), []);

  const handleEditEmployeesClick = () => setAction(AddressAction.EditEmployees);

  const handleDeleteEmployee = () => {
    setSubmitting(true);

    const filteredSpecialists = specialist
      ? orgSpecialist.filter(spec => spec.headOrgSpecId !== specialist.headOrgSpecId)
      : orgSpecialist;

    const params = {
      headOrgSpecIds: filteredSpecialists.map(spec => spec.headOrgSpecId).join(','),
    };

    deleteEmployee(params).finally(() => {
      setSubmitting(false);
      handleClose();
      id && dispatch(ThunkAddress.fetchAddressById(id));
    });
  };

  const empty = isEmpty(specialistItems);

  return (
    <>
      <InfoBlock
        title={t('address.employees')}
        description={t('address.specialistWhoWork')}
        placeholder={t('address.haveNotEmployees')}
        badge={empty ? null : <Badge text={specialistItems.length} design="lightGrey" />}
        separator={empty && isDesktop ? <Separator mb="16px" /> : null}
        visibleCount={1}
        count={specialistItems.length}
        button={
          <Button
            size="extraSmall"
            design={empty ? 'primary' : 'secondary'}
            prefix={empty ? null : <EditIcon height="16px" width="16px" />}
            onClick={handleEditEmployeesClick}
          >
            {isDesktop && (empty ? t('address.chooseEmployees') : t('button.edit'))}
          </Button>
        }
        emptyButton={
          <Button size="small" onClick={handleEditEmployeesClick} my="16px">
            {t('address.chooseEmployees')}
          </Button>
        }
      >
        {specialistItems}
      </InfoBlock>
      {action === AddressAction.EditEmployees && <EditEmployees onClose={handleClose} />}
      {action === AddressAction.DeleteEmployee && specialist && (
        <PopUp
          title={t('address.deleteByName', { name: getFullName(specialist) })}
          description=""
          onSubmit={handleDeleteEmployee}
          onClose={handleClose}
          confirm={t('button.delete')}
          cancel={t('button.cancel')}
          isLoading={isSubmitting}
          isOpen={action === AddressAction.DeleteEmployee}
        >
          <Flex flexDirection="column">
            <BodyLarge>{t('address.doYouWantToDeleteByName', { name: getFullName(specialist) })}</BodyLarge>
            <BodyLarge>{t('address.afterDeleteAppointmentsCancelled')}</BodyLarge>
          </Flex>
        </PopUp>
      )}
    </>
  );
};
