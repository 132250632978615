import { Avatar, BodyLarge, Caption, colors, Flex, Link, Separator, TrashIcon } from '@beauty/beauty-market-ui';
import { Box, IconBox } from '../../style';

export const WorkPlace = ({ url, name, address, onDelete, separator, isDesktop }) => (
  <>
    <Box available>
      <Flex minWidth="max-content" flexShrink={0}>
        <Avatar url={url} rating="" size="xs" />
      </Flex>
      <Flex flexDirection="column" gap="2px" mr="auto" ml="8px" width={isDesktop ? '50vw' : '70vw'}>
        <BodyLarge large title={name} truncated>
          {name}
        </BodyLarge>
        <Caption color={colors.grey.dark} title={address} truncated>
          {address}
        </Caption>
      </Flex>
      <IconBox>
        <Link design="gray" size="md" icon={<TrashIcon />} onClick={onDelete} />
      </IconBox>
    </Box>
    {separator && <Separator m={isDesktop ? '16px 0' : '8px 0'} />}
  </>
);
