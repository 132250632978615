import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const OrganisationInfoContainer = styled(Flex)`
  flex-direction: column;
  box-sizing: border-box;
  gap: 1px;
  width: 100%;
  margin-bottom: 80px;

  ${mediaQueries.md} {
    gap: 16px;
    margin-bottom: 0;
  }
`;
