import { ChangeEvent, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { isEqual } from 'lodash';
import moment from 'moment';
import {
  H6,
  Flex,
  Button,
  NotifyStatus,
  useNotify,
  LeftIcon,
  RightIcon,
  SearchIcon,
  getMinWidthMediaQuery,
  useMediaQuery,
  BottomSheet,
} from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { FormikDropdown } from 'components/functional/formik/formik-dropdown/FormikDropdown';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import Popup from 'components/PopUp';
import { getSpecialistNotifyContent } from 'helpers';
import { useRequest } from 'hooks';
import { useTimeList } from 'hooks/useTimeList';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { OrganisationSpecialistResponse, SpecialistAction, SpecialistResponse, WorkDayType } from 'types';
import { BreakType } from 'types/appointment';
import { selectAppointments } from '../../../../store/redux-slices/appointmentsSlice';
import {
  convertAllSpecialistsToOptions,
  getDefaultDate,
  getEndTimeIndex,
  getPreselectedValues,
  getScrollTo,
} from '../helpers';
import { DatePickerWrapper, StyledNavButton, CustomCalendarHeader, FormWrapper, TimeWrapper } from '../style';
import { BreakForm, BreakFormFields, AddBreakValidationSchema, getInitialValues } from './BreakSidebar.definitions';
import { ToggleItem } from './ToggleItem';

type AppointmentDateType = {
  item: string;
  disabled: boolean;
};

type BreakSidebarProps = {
  isEditMode: boolean;
  breakDate: string;
  handleClose: () => void;
  selectedWeekday: string;
  organisationSpecialists: OrganisationSpecialistResponse[] | null;
  organisationWorkTime: WorkDayType[] | null;
  selectedBreakData: BreakType | null;
  currentSpecialist: SpecialistResponse;
  specialist?: string;
};

export const BreakSidebar = ({
  isEditMode,
  breakDate,
  handleClose,
  selectedWeekday,
  organisationSpecialists,
  organisationWorkTime,
  selectedBreakData,
  currentSpecialist,
  specialist,
}: BreakSidebarProps) => {
  const { t } = useTranslation();
  const timeList = useTimeList();
  const dispatch = useAppDispatch();
  const notify = useNotify();
  const { selectedAddress } = useAppSelector(selectAppointments);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isMobile = !useMediaQuery(mediaQuery);

  const cancelBreak = useRequest(ThunkSpecialist.deleteBreak, SpecialistAction.DeleteBreak);

  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isEditSpecialist, setEditSpecialist] = useState(false);

  const { preselectedStart, preselectedEnd, preselectedDate, preSelectedSpecialist } = getPreselectedValues(
    selectedWeekday,
    selectedBreakData,
    null,
    null,
    null,
    organisationSpecialists,
    organisationWorkTime,
    timeList,
    null,
  );

  const { name, surname, avatarUrl, code, number } = currentSpecialist.account;

  const { preScrolledToStart, preScrolledToEnd } = getScrollTo(selectedWeekday, timeList, organisationWorkTime);

  const { BreakDate, Start, End, Specialist } = BreakFormFields;

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [startScroll, setStartScroll] = useState(preScrolledToStart);
  const [endScroll, setEndScroll] = useState(preScrolledToEnd);
  const [startTime, setStartTime] = useState(preselectedStart);
  const [endTime, setEndTime] = useState(preselectedEnd);
  const [timeOptions, setTimeOptions] = useState(timeList);

  const [appointmentDate, setAppointmentDate] = useState<AppointmentDateType>({
    item: selectedBreakData ? preselectedDate : getDefaultDate(breakDate || selectedWeekday),
    disabled: false,
  });

  const [isDayOff, setDayOff] = useState(false);

  const handleDayOff = (e: ChangeEvent<HTMLInputElement>) => {
    setDayOff(e.currentTarget.checked);
  };

  const handleCalendarClick = () => {
    setIsCalendarVisible(prevValue => !prevValue);
  };

  const handleBreakCancel = async () => {
    if (selectedBreakData) {
      setSubmitting(true);
      const params = selectedBreakData.id;
      cancelBreak(params).finally(() => {
        setIsConfirmationModalVisible(false);
        setSubmitting(false);
        handleClose();
      });
    }
  };

  const onFormSubmit = async (data: BreakForm) => {
    setSubmitting(true);
    const { start, end, date, orgSpecialistId } = data;

    const formattedDate = moment(date, 'DD.MM.yyyy').format('YYYY-MM-DD');
    const startTimeUTC = moment(`${formattedDate} ${isDayOff ? '00:00' : start}`).utc();
    const endTimeUTC = moment(`${formattedDate} ${isDayOff ? '23:59' : end}`).utc();

    const response = selectedBreakData
      ? dispatch(
          ThunkSpecialist.editBreak({
            id: selectedBreakData.id,
            start: startTimeUTC,
            end: endTimeUTC,
          }),
        )
      : (orgSpecialistId !== '0' &&
          dispatch(
            ThunkSpecialist.addBreak({
              orgSpecialistId,
              start: startTimeUTC,
              end: endTimeUTC,
            }),
          )) ||
        dispatch(
          ThunkSpecialist.addBreakAll({
            orgId: selectedAddress || '',
            start: startTimeUTC,
            end: endTimeUTC,
          }),
        );

    response
      ?.unwrap()
      .then(
        () => {
          notify(
            getSpecialistNotifyContent(
              NotifyStatus.SUCCESS,
              selectedBreakData ? SpecialistAction.EditBreak : SpecialistAction.AddBreak,
              t,
            ),
          );
          handleClose();
        },
        () => {
          notify(
            getSpecialistNotifyContent(
              NotifyStatus.ERROR,
              selectedBreakData ? SpecialistAction.EditBreak : SpecialistAction.AddBreak,
              t,
            ),
          );
        },
      )
      .finally(() => setSubmitting(false));
  };

  const formikContextValue = {
    initialValues: getInitialValues({
      date: selectedBreakData ? preselectedDate : getDefaultDate(breakDate || selectedWeekday),
      specialistId: selectedBreakData
        ? selectedBreakData.orgSpecialistId
        : specialist || preSelectedSpecialist || currentSpecialist.id || '0',
      start: timeList[preselectedStart]?.item,
      end: timeList[preselectedEnd]?.item,
    }),
    validationSchema: AddBreakValidationSchema(isDayOff, t),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <>
      <Formik {...formikContextValue}>
        {({ isValid, handleSubmit: onSubmit, values, initialValues, setFieldValue, setFieldTouched }) => {
          const isFieldsFilled =
            !!values[BreakDate] &&
            (!!values[Specialist] || values[Specialist] === '0') &&
            ((!!values[Start] && !!values[End] && !isDayOff) || isDayOff);

          const specialistOptionsList =
            isEditMode || organisationSpecialists?.length === 1
              ? convertAllSpecialistsToOptions(organisationSpecialists)
              : (!currentSpecialist.id && [
                  { id: '0', name: t('specialists.allSpecialists') },
                  ...convertAllSpecialistsToOptions(organisationSpecialists),
                ]) || [{ id: currentSpecialist.id, name: `${name} ${surname}`, avatarUrl, phone: `${code} ${number}` }];

          const footerBody = (
            <SidebarFooter
              disabled={!isFieldsFilled || !isValid || (isEqual(values, initialValues) && isEditMode)}
              onSubmit={onSubmit}
              onBack={handleClose}
              handleExtraAction={isEditMode ? () => setIsConfirmationModalVisible(true) : null}
              extraActionLabel={isEditMode ? t('calendar.addBreakSidebar.cancelBreak') : ''}
              save
              cancel
              isLoading={isSubmitting}
            />
          );

          const handleChangeEndTime = (index: number) => {
            setEndTime(index);
            setFieldTouched(End, true);
            setFieldValue(End, timeList[index].item);
          };

          const handleChangeStartTime = async (index: number) => {
            setStartTime(index);
            setFieldTouched(Start, true);
            await setFieldValue(Start, timeList[index].item);
            handleChangeEndTime(getEndTimeIndex(index, timeList));
          };

          const handleChangeDate = async (selectedDate: Date) => {
            const isToday = moment(selectedDate).isSame(moment(), 'day');
            if (isToday) {
              const now = moment().hours() * 60 + moment().minutes();
              const startTimeIndex = timeList.findIndex(time => Number(time.id) > now);
              setTimeOptions(
                timeOptions.map((timeOption, index) =>
                  index < startTimeIndex ? { ...timeOption, disabled: true } : timeOption,
                ),
              );
            } else setTimeOptions(timeList);

            const selectedBreakDate = moment(selectedDate).format('DD.MM.yyyy');
            const scrollTo = getScrollTo(selectedDate, timeList, organisationWorkTime);
            await setFieldValue(BreakDate, selectedBreakDate);
            setIsCalendarVisible(prevValue => !prevValue);
            setAppointmentDate({
              item: selectedBreakDate,
              disabled: false,
            });
            setStartScroll(scrollTo.preScrolledToStart);
            setEndScroll(scrollTo.preScrolledToEnd);
          };

          useEffect(() => {
            if (isDayOff) {
              setStartTime(-1);
              setEndTime(-1);
              setFieldValue(Start, '');
              setFieldValue(End, '');
            }
          }, [isDayOff]);

          const sidebarProps = {
            isOpen: true,
            onClose: handleClose,
            FooterBody: footerBody,
            label: t('calendar.addBreakSidebar.title'),
            descriptor: t('calendar.addBreakSidebar.descriptor'),
          };

          const specialistForm = (
            <FormWrapper>
              <H6 mt="8px">{t('calendar.addBreakSidebar.specialist')}</H6>
              <FormikInput
                id={Specialist}
                name={Specialist}
                placeholder={t('calendar.newAppointmentSidebar.specialistSearch')}
                iconLeft={<SearchIcon />}
                design="white"
                searchInput
                currentOption={specialistOptionsList.findIndex(item => item.id === values[Specialist])}
                options={specialistOptionsList}
                onSelect={(index: number) => {
                  setFieldValue(Specialist, specialistOptionsList[index].id);
                  setEditSpecialist(false);
                }}
                onDeleteIconClick={() => {
                  setFieldValue(Specialist, '');
                  setEditSpecialist(true);
                }}
                type="client"
                disabled={specialistOptionsList.length === 1 || isEditMode}
              />
            </FormWrapper>
          );

          const content = (
            <Form>
              <FormWrapper>
                <H6 marginTop="8px">{t('calendar.addBreakSidebar.setDate')}</H6>
                <TimeWrapper>
                  <FormikDropdown
                    id={BreakDate}
                    name={BreakDate}
                    placeholder={t('calendar.addBreakSidebar.dayOfBreak')}
                    currentOption={0}
                    options={[appointmentDate]}
                    onClick={handleCalendarClick}
                    isSeparator
                    disabled={false}
                  />
                  {isCalendarVisible && (
                    <DatePickerWrapper>
                      <DatePicker
                        selected={moment(appointmentDate.item, 'DD.MM.yyyy').toDate()}
                        minDate={new Date()}
                        // TODO Uncomment to filter out nonworking days from the calendar
                        // filterDate={(day: Date) => isWorkday(day, organisationWorkTime)}
                        onChange={handleChangeDate}
                        inline
                        renderCustomHeader={({
                          date: headerDate,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <CustomCalendarHeader>
                            <StyledNavButton
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                              design="tertiaryBlack"
                            >
                              <LeftIcon />
                            </StyledNavButton>
                            {moment(headerDate).format('MMMM')}
                            <StyledNavButton
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                              design="tertiaryBlack"
                            >
                              <RightIcon />
                            </StyledNavButton>
                          </CustomCalendarHeader>
                        )}
                        todayButton={
                          <Button
                            size="small"
                            width="100%"
                            onClick={(e: Event) => {
                              e.stopPropagation();
                              setIsCalendarVisible(false);
                            }}
                          >
                            {t('calendar.newAppointmentSidebar.selectDate')}
                          </Button>
                        }
                        onSelect={() => {
                          // workaround since shouldCloseOnSelect not working
                          setIsCalendarVisible(true);
                        }}
                        calendarStartDay={1}
                      />
                    </DatePickerWrapper>
                  )}
                </TimeWrapper>

                <H6 mt="8px">{t('calendar.addBreakSidebar.setTime')}</H6>
                <Flex gap="8px">
                  <FormikDropdown
                    id={Start}
                    name={Start}
                    placeholder={t('calendar.addBreakSidebar.start')}
                    // currentOption={timeList.length >= startTime ? startTime : 0}
                    scrollTo={startScroll}
                    currentOption={startTime}
                    options={timeOptions}
                    onChange={handleChangeStartTime}
                    isSeparator
                    autoScroll
                    disabled={isDayOff}
                  />
                  <FormikDropdown
                    id={End}
                    name={End}
                    placeholder={t('calendar.addBreakSidebar.end')}
                    // currentOption={timeList.length >= endTime ? endTime : 0}
                    scrollTo={endScroll}
                    currentOption={endTime}
                    options={timeOptions}
                    onChange={handleChangeEndTime}
                    isSeparator
                    autoScroll
                    disabled={isDayOff}
                  />
                </Flex>
                <ToggleItem
                  label={t('calendar.addBreakSidebar.setDayOff')}
                  isChecked={isDayOff}
                  onChange={handleDayOff}
                />

                {!values[Specialist] && isMobile ? (
                  <Button design="secondary" onClick={() => setEditSpecialist(true)}>
                    {t('calendar.newAppointmentSidebar.chooseSpecialist')}
                  </Button>
                ) : (
                  specialistForm
                )}
              </FormWrapper>
            </Form>
          );

          return (
            <>
              <SidebarSheet {...sidebarProps}>{content}</SidebarSheet>;
              {isEditSpecialist && isMobile && (
                <BottomSheet
                  isOpen
                  content={specialistForm}
                  onClose={() => setEditSpecialist(false)}
                  label={t('calendar.newAppointmentSidebar.chooseSpecialistForAppointment')}
                  descriptor={t('calendar.newAppointmentSidebar.mainInformation')}
                />
              )}
            </>
          );
        }}
      </Formik>
      {isConfirmationModalVisible && (
        <Popup
          title={t('calendar.confirmation.cancellingBreak')}
          description={t('calendar.confirmation.doYouWantToCancelBreak')}
          onClose={() => setIsConfirmationModalVisible(false)}
          onSubmit={handleBreakCancel}
          isLoading={isSubmitting}
          confirm={t('button.confirm')}
          cancel={t('button.close')}
        />
      )}
    </>
  );
};
