import styled from 'styled-components';
import { colors, Flex } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  min-height: 88px;
  width: 100%;
  padding: 16px 8px 16px 16px;
  align-items: center;
  background-color: ${colors.white.standard};
  border-radius: 10px;
  border: 1px solid ${colors.grey.standard};
  gap: 16px;
  box-sizing: border-box;
`;
