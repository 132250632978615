import styled, { css } from 'styled-components';
import { Flex, colors, Caption } from '@beauty/beauty-market-ui';
import { cropped1LineText, cropped5LineText, croppedMultiLineText } from 'style';

export const EventWrapper = styled(Flex)<{
  lineColor: string;
  isGoogle: boolean;
  isBreak: boolean;
  stringsToTrim: number;
  isActive?: boolean;
  isAgenda?: boolean;
}>`
  position: relative;
  justify-content: space-between;

  ${({ isAgenda, lineColor, isBreak }) =>
    isAgenda &&
    css`
      ::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        height: 48px;
        width: 4px;
        background-color: ${isBreak ? '#54cc29' : lineColor};
        border-radius: 8px;
      }
      height: 48px;
      margin-bottom: 16px;
    `};

  z-index: 1;
  padding: ${({ isActive, isAgenda }) => (isActive ? '10px 16px' : (isAgenda && '6px 6px 6px 24px') || '4px 6px')};
  border-radius: ${({ isActive }) => (isActive ? '10px' : '6px')};
  border: 1px solid ${({ isBreak }) => (isBreak ? '#54cc29' : colors.white.standard)};
  ${({ isAgenda }) => isAgenda && `border: none`};
  cursor: pointer;

  div:first-child > span:nth-child(2) {
    ${({ stringsToTrim }) =>
      stringsToTrim === 1 ? cropped1LineText : (stringsToTrim === 3 && croppedMultiLineText) || cropped5LineText}
  }
`;

export const ServiceWrapper = styled(Caption)`
  color: rgba(255, 255, 255, 0.6) !important;
`;
