import { TFunction } from 'react-i18next';
import moment from 'moment/moment';
import { Nullable } from 'tsdef';
import { getFormattedCurrency, getHoursAndMinutesForUserLocale } from 'helpers/utils';
import { AddressPayloadType, AddressSpecialistType, OrganisationClientsType, OrganisationServiceResponse } from 'types';
import { getSelectedLanguage } from '../../../constants';

type SpecParams = Pick<
  AddressSpecialistType,
  'orgSpecId' | 'name' | 'surname' | 'avatarUrl' | 'code' | 'number' | 'orgSpecService'
>;
type ServiceOption = {
  id: string;
  title: string;
  description: string;
  price: string;
  duration: string | number;
};
type SpecOption = {
  id: string;
  name: string;
  avatarUrl: string | null;
  phone: string;
  orgSpecService: AddressSpecialistType['orgSpecService'];
};

export const convertAllServicesToOptions = (
  allServices: OrganisationServiceResponse[] | null,
  currency: Nullable<string>,
  t: TFunction<'translation', undefined>,
) => {
  const currentLanguage = getSelectedLanguage();

  return allServices
    ? allServices.map(service => ({
        id: service.id,
        title: service.title.text,
        description: getHoursAndMinutesForUserLocale(Number(service.duration), currentLanguage),
        price:
          service.price === null
            ? t('settings.services.nullPrice')
            : getFormattedCurrency(Number(service.price), currency, currentLanguage),
        duration: service.duration,
        // oldPrice: '$400', // We don't have this data in a response at the moment
      }))
    : [];
};

export const convertAllClientsToOptions = (allClients: OrganisationClientsType[] | null, showPhone: boolean) =>
  allClients
    ? allClients.map(client => ({
        id: client.id,
        name: `${client.name} ${client.surname}`,
        avatarUrl: client.avatar,
        phone: showPhone && client.number ? `${client.code} ${client.number}` : null,
        email: `${client.email}`,
        idClientNumber: client?.idNumber,
      }))
    : [];

export const getTiming = (start: string, end: string) => {
  const endInput = `${2023 - 11 - 11} ${end}`;
  const startInput = `${2023 - 11 - 11} ${start}`;
  const diff = moment(endInput).diff(moment(startInput), 'minute');
  return `${start}-${end} • ${diff}`;
};

export const convertAllSpecialistsToOptions = (allSpecialists?: SpecParams[] | null): SpecOption[] =>
  allSpecialists
    ? allSpecialists.map(specialist => ({
        id: specialist.orgSpecId,
        name: `${specialist.name} ${specialist.surname}`,
        avatarUrl: specialist.avatarUrl,
        phone: `${specialist.code} ${specialist.number}`,
        orgSpecService: specialist?.orgSpecService ?? [],
      }))
    : [];

export const filterBySelectedService = (specialists: SpecOption[], service: string) =>
  specialists.filter(specialist =>
    service && specialist.orgSpecService
      ? specialist.orgSpecService.some(item => item.orgService.id === service)
      : true,
  );

export const filterBySelectedSpecialist = (
  services: ServiceOption[],
  specialist?: string,
  orgSpecialist?: AddressPayloadType['orgSpecialist'],
) =>
  services.flatMap(serviceItem => {
    if (specialist && orgSpecialist) {
      const spec = orgSpecialist.find(item => item.orgSpecId === specialist);
      if (spec && spec?.orgSpecService?.some(specService => specService.orgService.id === serviceItem.id)) {
        return serviceItem;
      }
      return [];
    }
    return serviceItem;
  });

export const getErrorMessage = (value: string, required: boolean): string => {
  switch (true) {
    case value.length && value.length < 5:
      return 'validation.minLength5';
    case value.length && value.length > 1000:
      return 'validation.maxLength1000';
    case !value.length && required:
      return 'validation.notEmpty';
    default:
      return '';
  }
};
