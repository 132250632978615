import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'lodash';
import moment from 'moment';
import { Flex, getMinWidthMediaQuery, H5, H6, Separator, useMediaQuery } from '@beauty/beauty-market-ui';
import { EditButton, Paper } from 'components';
import { formatPhoneNumber } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectClient, ThunkClient } from 'store/redux-slices/clientSlice';
import { ClientActions } from 'types';
import { ClientForm, ClientSidebar, ProfileItem } from '../../components';
import { getParams } from '../../helper';

export const PageData = () => {
  const { t } = useTranslation();
  const client = useAppSelector(selectClient);
  const dispatch = useAppDispatch();
  const { number, email, code, dateOfBirth, gender, idNumber, info } = client;

  const [action, setAction] = useState(ClientActions.None);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const onSubmit = useCallback(
    (data: ClientForm) => {
      const params = getParams(data, client, true);
      return dispatch(ThunkClient.editClient(params)).unwrap();
    },
    [dispatch, getParams],
  );

  const handleEditClient = () => setAction(ClientActions.Edit);
  const handleClose = () => setAction(ClientActions.None);

  return (
    <Paper
      flexDirection="column"
      borderRadius={isDesktop ? '0 0 16px 16px' : '16px 16px 0 0'}
      p={isDesktop ? '24px' : '16px 8px'}
    >
      <Flex justifyContent="space-between" alignItems="center" mb="16px">
        {isDesktop ? <H5>{t('profile.personalInformation')}</H5> : <H6>{t('profile.personalInformation')}</H6>}
        <EditButton onClick={handleEditClient} edit />
      </Flex>
      {isDesktop && <Separator />}
      {number && code && (
        <ProfileItem
          label={t('profile.phone')}
          placeholder={t('profile.notFilledYet')}
          large
          title={formatPhoneNumber(code, number)}
          separator
        />
      )}
      <ProfileItem label={t('profile.email')} placeholder={t('profile.notFilledYet')} large title={email} separator />
      <ProfileItem
        label={t('profile.dateOfBirth')}
        placeholder={t('profile.notFilledYet')}
        large
        title={dateOfBirth && moment(dateOfBirth).format('DD.MM.YYYY')}
        separator
      />
      <ProfileItem
        label={t('profile.gender')}
        placeholder={t('profile.notFilledYet')}
        large
        title={capitalize(gender)}
        separator
      />
      <ProfileItem
        label={t('profile.idNumber')}
        placeholder={t('profile.notFilledYet')}
        large
        title={idNumber ?? undefined}
        separator
      />
      <ProfileItem label={t('profile.additionalInfo')} placeholder={t('profile.notFilledYet')} title={info} />
      {action !== ClientActions.None && <ClientSidebar onSubmit={onSubmit} onClose={handleClose} action={action} />}
    </Paper>
  );
};
