import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import {
  Avatar,
  BodySmall,
  BottomSheet,
  Button,
  colors,
  Flex,
  getMinWidthMediaQuery,
  Menu,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { zIndex } from '../../../../constants';
import { getMenuClient } from '../../../../helpers/utils';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setShowLogout } from '../../../../store/redux-slices/authSlice';
import { selectUser } from '../../../../store/redux-slices/userSlice';
import { LogoutButton, NavButton } from '../../style';

export const AvatarMenu = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);

  const [isOpen, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const menu = getMenuClient(t, user.headOrgSpecialist, isDesktop);

  const handleLogout = () => dispatch(setShowLogout(true));
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const icon = (
    <Avatar
      size="xs"
      width="48px"
      anonymos="default"
      borderRadius={isDesktop ? '8px' : '50%'}
      border={user.avatarUrl ? 'none' : `1px solid ${colors.grey.standard}`}
      url={user.avatarUrl}
      onClick={handleOpen}
    />
  );

  return isDesktop ? (
    <Menu zIndex={zIndex.menuClient} options={[]} iconMenu={icon} placement="bottom-start">
      {menu.map(item => (
        <NavButton key={item.id} to={item.link} end>
          <BodySmall>{item.item}</BodySmall>
        </NavButton>
      ))}
      <LogoutButton onClick={handleLogout}>{t('logout.logoutButton')}</LogoutButton>
    </Menu>
  ) : (
    <>
      {icon}
      <BottomSheet
        label={t('menu.menu')}
        isOpen={isOpen}
        detent="content-height"
        onClose={handleClose}
        handleClose={handleClose}
        FooterBody={null}
        content={
          <Flex flexDirection="column" width="100%" gap="16px">
            {menu.map(item => (
              <NavLink key={item.id} to={item.link} style={{ textDecoration: 'none' }}>
                {({ isActive }) => (
                  <Button
                    size="large"
                    design={isActive ? 'primary' : 'quaternary'}
                    width="100%"
                    onClick={isActive ? undefined : handleClose}
                  >
                    {item.item}
                  </Button>
                )}
              </NavLink>
            ))}
            <Button size="large" width="100%" design="quaternary" onClick={handleLogout}>
              {t('logout.logoutButton')}
            </Button>
          </Flex>
        }
      />
    </>
  );
};
