import _ from 'lodash';
import moment from 'moment';
import { TimeItemType } from '../../../../hooks/useTimeList';
import { SpecialistOrganizationType } from '../../../../types';
import { createDay, createWeek } from './helpers';
import { DayOrgType, DayType, ScheduleStateType } from './types';

export const initialState = (
  timeList: TimeItemType[],
  organization?: SpecialistOrganizationType[],
  selectedWeek?: number,
): ScheduleStateType => {
  const orgData = _.isArray(organization) ? organization : [];
  const getTimeIndex = (value: string) => timeList.findIndex(time => time.item === value);

  const numberOfWeeks = 4;
  const weeks: { id: string; dayOfWeek: string }[][] = Array.from({ length: numberOfWeeks }, createWeek);
  const days: Record<string, DayType> = {};
  const orgMap: Record<string, Record<string, DayOrgType>> = {};
  const orgIds: string[] = [];
  let scheduleStart = moment().format('DD.MM.YYYY');

  weeks.forEach(week => {
    week.forEach(day => {
      days[day.id] = createDay(day.dayOfWeek);
    });
  });

  const indexesOfWorkWeeks: number[] = [];

  orgData.forEach(org => {
    const { orgId, name, orgSpecSchedule, orgSchedule } = org;
    if (org?.scheduleStart) scheduleStart = moment(org?.scheduleStart).format('DD.MM.YYYY');
    orgIds.push(orgId);
    orgMap[orgId] = orgSchedule.reduce((acc, orgDay) => {
      const startIndex = getTimeIndex(orgDay.start);
      const endIndex = getTimeIndex(orgDay.end);
      acc[orgDay.dayOfWeek] = {
        dayOfWeek: orgDay.dayOfWeek,
        startIndex,
        endIndex,
        breakStartIndex: orgDay.breakStart ? getTimeIndex(orgDay.breakStart) : startIndex,
        breakEndIndex: orgDay.breakEnd ? getTimeIndex(orgDay.breakEnd) : endIndex,
      };
      return acc;
    }, {});

    orgSpecSchedule.forEach(schedule => {
      const weekIndex = schedule.weekNumber - 1;
      const workDays = schedule.orgSpecDaySchedule;

      indexesOfWorkWeeks.push(weekIndex);

      workDays.forEach(workDay => {
        const dayIndex = weeks[weekIndex].findIndex(day => day.dayOfWeek === workDay.dayOfWeek);
        if (dayIndex !== -1) {
          const dayId = weeks[weekIndex][dayIndex].id;
          days[dayId].isWorkDay = true;
          days[dayId].dayOrgIds.push(orgId);
          days[dayId].dayOrgMap[orgId] = {
            id: workDay.id,
            orgId,
            orgName: name || '',
            startIndex: getTimeIndex(workDay.start),
            endIndex: getTimeIndex(workDay.end),
            isApplyForAllDays: false,
            breaks: workDay.orgSpecDayBreak.map(breakOne => ({
              id: breakOne?.id || crypto.randomUUID(),
              startIndex: getTimeIndex(breakOne.start),
              endIndex: getTimeIndex(breakOne.end),
            })),
          };
        }
      });
    });
  });

  return {
    dayIndex: 0,
    weekIndex: selectedWeek || 0,
    date: scheduleStart,
    repeatIndex: Math.max(0, ...indexesOfWorkWeeks),
    isLoading: false,
    isDirty: false,
    isOpen: false,
    weeks: weeks.map(week => week.map(day => day.id)),
    days,
    orgMap,
    orgIds,
    errors: {
      date: null,
    },
  };
};
