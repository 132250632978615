import { useTranslation } from 'react-i18next';
import {
  Avatar,
  BodyLarge,
  BodySmall,
  Caption,
  CoverStack,
  CoverStackCustomer,
  Flex,
  getMinWidthMediaQuery,
  H4,
  H6,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { Paper, Widget } from 'components';
import { getFormattedCurrency, getFullName } from 'helpers';
import { usePluralForm } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectClient, selectClientInfo } from 'store/redux-slices/clientSlice';
import { selectCurrency } from 'store/redux-slices/organisationSlice';
import { getSelectedLanguage } from '../../../constants';
import { parseClientInfo } from '../helper';

export const ClientCoverStack = () => {
  const { t } = useTranslation();
  const client = useAppSelector(selectClient);
  const clientInfo = useAppSelector(selectClientInfo);
  const currency = useAppSelector(selectCurrency);
  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const rating = clientInfo?.rating ? clientInfo.rating.toString() : '0';
  const avatar = client?.avatar ?? undefined;
  const reviews = usePluralForm('clients.profile.review', clientInfo?.reviewCount);
  const fullName = getFullName(client);
  const totalSales = getFormattedCurrency(clientInfo.totalSales, currency, language);
  const personInfo = parseClientInfo(clientInfo, t);

  const header = (
    <CoverStackCustomer
      label={reviews}
      rating={rating}
      firstName={client?.name}
      lastName={client?.surname}
      imgUrl={avatar}
      onButtonClick={null}
      tagText=""
      width="310px"
    />
  );

  const content = [
    {
      title: t('clients.profile.totalSales'),
      value: totalSales,
    },
    ...personInfo,
  ].flatMap((item, index, arr) => (
    <Widget
      key={item.title}
      title={<BodySmall>{item.title}</BodySmall>}
      subtitle={<BodyLarge large>{item?.value}</BodyLarge>}
      separator={index !== arr.length - 1}
      m={index !== arr.length - 1 ? '12px 0' : '12px 0 0'}
    />
  ));

  return isDesktop ? (
    <CoverStack width="310px" header={header} content={content} />
  ) : (
    <Paper flexDirection="column" alignItems="center" p="16px" gap="16px">
      <Flex flexDirection="column" alignItems="center">
        <Avatar url={avatar} name={fullName} rating={rating} size="m" />
        <Caption lowline mt="8px">
          {reviews}
        </Caption>
        <H4 mt="4px">{fullName}</H4>
      </Flex>
      <Flex flexDirection="column" alignItems="center">
        <BodySmall>{t('clients.profile.totalSales')}</BodySmall>
        <H6>{totalSales}</H6>
      </Flex>
      <Flex gap="8px">
        {personInfo.map(item => (
          <Flex key={item.title} flexDirection="column" alignItems="start">
            <Caption>{item.title}</Caption>
            <H6>{item.value}</H6>
          </Flex>
        ))}
      </Flex>
    </Paper>
  );
};
