import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { Nullable } from 'tsdef';
import {
  BodyLarge,
  BottomSheet,
  getMinWidthMediaQuery,
  Separator,
  useMediaQuery,
  Button,
  Flex,
} from '@beauty/beauty-market-ui';
import { PopUp } from 'components';
import { useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectSpecialist, selectSpecialistInfo, ThunkSpecialist } from 'store/redux-slices/specialistSlice';
import { SpecialistAction, SpecialistLanguage, SpokenLanguage } from 'types';
import { getSpokenLanguages } from '../../../../../constants';
import { EmployeeSection } from '../EmployeeSection/EmployeeSection';
import { EditLanguages } from './EditLanguages';
import { LanguageItem } from './LanguageItem';

export const Languages = () => {
  const { t } = useTranslation();
  const { id } = useAppSelector(selectSpecialist);
  const { spokenLanguage } = useAppSelector(selectSpecialistInfo);

  const [action, setAction] = useState(SpecialistAction.None);
  const [selected, setSelected] = useState<Nullable<SpecialistLanguage>>(null);

  const addLanguage = useRequest(ThunkSpecialist.editSpokenLanguage, SpecialistAction.AddLanguage);
  const editLanguage = useRequest(ThunkSpecialist.editSpokenLanguage, SpecialistAction.EditLanguage);
  const deleteLanguage = useRequest(ThunkSpecialist.editSpokenLanguage, SpecialistAction.DeleteLanguage);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const languageOptions = getSpokenLanguages(t);

  const handleAddLanguage = () => {
    setAction(SpecialistAction.AddLanguage);
    setSelected(null);
  };

  const handleEditLanguage = (item: SpecialistLanguage) => {
    setAction(SpecialistAction.EditLanguage);
    setSelected(item);
  };

  const handleDeleteLanguage = (item: SpecialistLanguage) => {
    setAction(SpecialistAction.DeleteLanguage);
    setSelected(item);
  };

  const handleClose = () => {
    setAction(SpecialistAction.None);
    setSelected(null);
  };

  const handleSubmit = async (data?: SpokenLanguage) => {
    let languages = spokenLanguage.map(item => ({ language: item.language, level: item.level }));
    if (action === SpecialistAction.AddLanguage && data) {
      languages = [...languages, data];
      await addLanguage({ id, languages });
    }

    if (action === SpecialistAction.EditLanguage && selected && data) {
      languages = languages.map(item =>
        item.language === selected.language ? { language: data.language, level: data.level } : item,
      );
      await editLanguage({ id, languages });
    }

    if (action === SpecialistAction.DeleteLanguage && selected) {
      languages = languages.filter(item => item.language !== selected.language);
      await deleteLanguage({ id, languages });
    }

    handleClose();
  };

  const languages = spokenLanguage.map((item, index, arr) => (
    <LanguageItem
      key={item.id}
      subtitle={item.level}
      title={languageOptions[item.language].value}
      separator={index !== arr.length - 1}
      onEdit={(e: MouseEvent<HTMLOrSVGElement>) => {
        e.stopPropagation();
        handleEditLanguage(item);
      }}
      onDelete={(e: MouseEvent<HTMLOrSVGElement>) => {
        e.stopPropagation();
        handleDeleteLanguage(item);
      }}
    />
  ));

  const content = (
    <BodyLarge mb="80px">
      {t('specialists.personalInfo.deleteLanguage', {
        value: selected?.language ? languageOptions[selected.language].value : '',
      })}
    </BodyLarge>
  );

  return (
    <>
      <EmployeeSection
        position="middle"
        visibleCount={7}
        count={languages.length}
        title={t('specialists.personalInfo.languages')}
        description={t('specialists.personalInfo.whatSpecialist')}
        placeholder={t('specialists.personalInfo.noInfoAboutLanguages')}
        buttonLabel={t('specialists.personalInfo.addLanguage')}
        onClick={handleAddLanguage}
        separator={isEmpty(languages) && isDesktop ? <Separator mb="16px" /> : null}
      >
        {languages}
      </EmployeeSection>
      {action === SpecialistAction.AddLanguage && <EditLanguages onSubmit={handleSubmit} onClose={handleClose} />}
      {action === SpecialistAction.EditLanguage && (
        <EditLanguages onSubmit={handleSubmit} onClose={handleClose} defaultValue={selected} />
      )}
      {action === SpecialistAction.DeleteLanguage &&
        selected &&
        (isDesktop ? (
          <PopUp
            title={t('specialists.personalInfo.deletingLanguage')}
            description=""
            onSubmit={handleSubmit}
            onClose={handleClose}
            confirm={t('button.delete')}
            cancel={t('button.cancel')}
            isLoading={false}
          >
            {content}
          </PopUp>
        ) : (
          <BottomSheet
            isOpen={action === SpecialistAction.DeleteLanguage && selected}
            onClose={handleClose}
            handleClose={handleClose}
            label={t('specialists.personalInfo.deletingLanguage')}
            content={content}
            detent="content-height"
            FooterBody={
              <Flex flexDirection="column" width="100%" gap="8px">
                <Button onClick={handleSubmit}>{t('button.delete')}</Button>
                <Button onClick={handleClose} design="secondary">
                  {t('button.cancel')}
                </Button>
              </Flex>
            }
          />
        ))}
    </>
  );
};
