import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { Nullable } from 'tsdef';
import { NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { getFullName, getNotifyContent } from 'helpers';
import { emptyClient, selectClient } from 'store/redux-slices/clientSlice';
import { Client, ClientActions, ErrorDataType } from 'types';
import { genders, InvalidVariants } from '../../../../constants';
import { useAppSelector } from '../../../../store/hooks';
import { FormWrapper } from '../../style';
import { ClientPopup } from '../ClientPopup';
import {
  ClientForm,
  ClientFormFields,
  clientsSectionValidationSchema,
  getInitialValues,
} from './ClientForm.definitions';
import { ContactInformationForm } from './Forms/ContactInformationForm';
import { GeneralInformationForm } from './Forms/GeneralInformationForm';

export type ClientSidebar = {
  action: ClientActions;
  onClose: () => void;
  onSubmit: (data: ClientForm, orgId?: string) => Promise<Client | null>;
};

export const ClientSidebar = ({ action, onSubmit, onClose }: ClientSidebar) => {
  const { t } = useTranslation();
  const notify = useNotify();
  const client = useAppSelector(selectClient);

  const verified = client?.verified ?? false;
  const isEditAction = action === ClientActions.Edit;

  const formRef = useRef<HTMLFormElement>(null);

  const [selected, setSelected] = useState<Nullable<{ id: string }>>(null);
  const [isOpen, setOpen] = useState(false);
  const [genderIndex, setGenderIndex] = useState(
    client?.gender ? genders.findIndex(item => item === client.gender?.toLowerCase()) : -1,
  );

  const onFormSubmit = async (data: ClientForm, { setFieldError, setSubmitting }: FormikHelpers<ClientForm>) => {
    if (!isEditAction && !selected) {
      setOpen(true);
    } else {
      setSubmitting(true);
      onSubmit(data, selected?.id)
        .then(() => {
          notify(getNotifyContent(NotifyStatus.SUCCESS, action, t));
          onClose();
        })
        .catch(({ statusCode, message }: ErrorDataType) => {
          const isAlreadyExist = message.includes('created');
          if (statusCode === 400) {
            if (message.includes('number')) setFieldError(ClientFormFields.Code, InvalidVariants.UsedNumber);
            if (isAlreadyExist) setFieldError(ClientFormFields.Code, InvalidVariants.UsedNumber);
          }
          notify(
            getNotifyContent(NotifyStatus.ERROR, action, t, isAlreadyExist ? t('clients.alreadyCreated') : undefined),
          );
        })
        .finally(() => setSubmitting(false));
    }
  };

  const formikContextValue = {
    initialValues: client ? getInitialValues(client) : emptyClient,
    validationSchema: clientsSectionValidationSchema(t, action),
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  useEffect(() => {
    setGenderIndex(client?.gender ? genders.findIndex(item => item === client.gender?.toLowerCase()) : -1);
  }, [client]);

  return (
    <Formik {...formikContextValue}>
      {({ isValid, dirty, touched, handleSubmit, handleChange, setFieldValue, values, errors, isSubmitting }) => {
        const footerBody = (
          <SidebarFooter
            isLoading={isSubmitting}
            disabled={!isValid || !dirty}
            onSubmit={handleSubmit}
            onBack={onClose}
            cancelLabel={t('button.cancel')}
            confirmationLabel={isEditAction ? t('button.save') : t('button.sendInvite')}
          />
        );

        return (
          <SidebarSheet
            onClose={onClose}
            label={isEditAction ? t('settings.clients.edit') : t('settings.clients.add')}
            descriptor={
              isEditAction ? `${t('settings.clients.editHint')} ${getFullName(client)}` : t('settings.clients.addHint')
            }
            FooterBody={footerBody}
          >
            <Form ref={formRef}>
              <FormWrapper>
                <GeneralInformationForm
                  genderIndex={genderIndex}
                  setGenderIndex={setGenderIndex}
                  verified={verified}
                  idNumber={client?.idNumber ?? null}
                  avatar={client?.avatar}
                />
                <ContactInformationForm
                  initialCode={client?.code || '+972'}
                  initialAddCode={client?.addCode || ''}
                  editMode={action === ClientActions.Edit}
                  verified={verified}
                />
                {/* <NotificationsForm /> */}
                {isOpen && (
                  <ClientPopup
                    value={selected}
                    onSubmit={handleSubmit}
                    onChange={setSelected}
                    onClose={() => {
                      setOpen(false);
                      setSelected(null);
                    }}
                    info={t('clients.chooseAddressInfo')}
                    isLoading={isSubmitting}
                  />
                )}
              </FormWrapper>
            </Form>
          </SidebarSheet>
        );
      }}
    </Formik>
  );
};
