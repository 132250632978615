import styled from 'styled-components';
import { Flex, H5 } from '@beauty/beauty-market-ui';

export const CardWrapper = styled(Flex)`
  width: 100%;
  padding-left: 8px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`;

export const WidgetText = styled(H5)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
