import { useState } from 'react';
import { BodyLarge, Checkbox, colors, DownIcon, Flex, Icon, UpIcon } from '@beauty/beauty-market-ui';
import { SubCategoryCardProps } from '../types';
import { ServiceCard } from './ServiceCard';

export const SubCategoryCard = ({ title, services, selectedServices, serviceIds, onChange }: SubCategoryCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const subCategoryTitle = `${title} (${services.length})`;
  const subCategoryChecked = serviceIds.every(id => selectedServices.includes(id));

  const handleSubCategoryClick = () => onChange(serviceIds);

  return (
    <Flex flexDirection="column" p="16px" backgroundColor={colors.grey.light} borderRadius="10px">
      <Flex alignItems="center" mb={isOpen ? '22px' : '0'}>
        <Icon width="20px" height="20px" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <UpIcon /> : <DownIcon />}
        </Icon>
        <BodyLarge ml="16px" mr="auto">
          {subCategoryTitle}
        </BodyLarge>
        <Checkbox checked={subCategoryChecked} onCheckboxClick={handleSubCategoryClick} shape="round" />
      </Flex>
      {isOpen &&
        services.map(item => (
          <ServiceCard
            id={item.id}
            key={item.id}
            title={item.title}
            description={item.description}
            selectedServices={selectedServices}
            onChange={onChange}
          />
        ))}
    </Flex>
  );
};
