import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoutSidebar from '../../../page/LogoutSidebar/LogoutSidebar';
import { RouterUrl } from '../../../routes/routes';
import { logout } from '../../../store/actions/common';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAuth, setShowLogout } from '../../../store/redux-slices/authSlice';
import { selectUser } from '../../../store/redux-slices/userSlice';
// import { setAccessToken } from '../../api/api.helpers';

// import { menuItems } from './const';

export const MenuClient = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);
  const { isShowLogout } = useAppSelector(selectAuth);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  // const menuOptions = menuItems(user.userId || '1');

  // const currentOption = menuOptions.findIndex(element => element.link === location.pathname);

  const changeMenu = (id: number) => {
    // navigate(menuOptions[id].link);
  };

  const onLogout = () => {
    // setAccessToken();
    navigate(RouterUrl.Login);
    dispatch(logout());
  };

  const onLogoutCancel = () => {
    dispatch(setShowLogout(false));
  };

  const onLoginAnotherUser = () => {
    navigate(RouterUrl.Login);
    dispatch(logout());
  };

  return (
    <>
      {/* <Div mt='5px' mr='10px'> */}
      {/*  <Menu zIndex='1' currentOption={[]} onChange={id => changeMenu(id)} options={[]}> */}
      {/*    <LogoutButton onClick={() => setLogoutOpen(true)}>{t('logout.logoutButton')}</LogoutButton> */}
      {/*  </Menu> */}
      {/* </Div> */}
      <LogoutSidebar
        isOpen={isShowLogout}
        onLogout={onLogout}
        onLogoutCancel={onLogoutCancel}
        onLoginAnotherUser={onLoginAnotherUser}
      />
    </>
  );
};
