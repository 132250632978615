import { getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { Menu } from './components/Menu';
import { CutWrapper, FullWrapper, Wrapper } from './style';

type MainMenuProps = {
  isMenuOpen: boolean;
  onMenuOpen: (isOpen: boolean) => void;
};

export const MainMenu = ({ isMenuOpen, onMenuOpen }: MainMenuProps) => {
  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  return (
    <Wrapper
      isOpen={isMenuOpen}
      onMouseOver={isLarge ? () => onMenuOpen(true) : undefined}
      onMouseOut={isLarge ? () => onMenuOpen(false) : undefined}
    >
      <FullWrapper isMenuOpen={isMenuOpen}>
        <Menu isFull onClose={isLarge ? undefined : () => onMenuOpen(false)} />
      </FullWrapper>
      <CutWrapper isMenuOpen={isMenuOpen}>
        <Menu />
      </CutWrapper>
    </Wrapper>
  );
};
