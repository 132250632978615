import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Caption, Language, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { locationsWithStandardHeaderFooter } from '../../constants';
import { useAppSelector } from '../../store/hooks';
import { selectAuth } from '../../store/redux-slices/authSlice';
import { languageOptions } from './const';
import { FooterContainer, FooterWrapper, MixFlex } from './style';

const Footer = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { isLogin } = useAppSelector(selectAuth);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  return !isLogin || locationsWithStandardHeaderFooter.includes(location.pathname) ? (
    <FooterWrapper>
      <FooterContainer>
        <MixFlex alignItems="start">
          <Caption pr="20px">© 2023 BeautyMarket.com</Caption>
        </MixFlex>
        <Language
          currentLanguage={i18n.resolvedLanguage}
          handleLanguageSelect={(language: string) => i18n.changeLanguage(language)}
          options={languageOptions}
          placement={isDesktop ? 'top' : 'top-left'}
          mode={isDesktop ? 'full' : 'minimal'}
        />
      </FooterContainer>
    </FooterWrapper>
  ) : null;
};

export default Footer;
