import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { H1, BodySmall, Button } from '@beauty/beauty-market-ui';
import { setAccessToken } from '../../../api/api.helpers';
import { AccType, InvalidVariants } from '../../../constants';
import { registration } from '../../../helpers/registration';
import { signupPhoneVerification } from '../../../helpers/signupPhoneVerification';
import { getInvalidType } from '../../../helpers/utils';
import { useGetSecondsLeft } from '../../../hooks/useGetSecondsLeft';
import useRedirectAfterLogin from '../../../hooks/useRedirectAfterLogin';
import { useAppDispatch } from '../../../store/hooks';
import { setLogin } from '../../../store/redux-slices/authSlice';
import { updateSmsCodeTime, updateUser } from '../../../store/redux-slices/userSlice';
import { CreateAccountType } from '../../../types/user';
import { Rules } from '../componets/Rules';
import { SmsForm } from '../componets/SmsForm';
import { initialSmsCodeValues, LoginSmsCodeForm, loginSMSCodeFormValidationSchema } from '../Login.definitions';

const PhoneVerification = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { agreement, email, name, surname, password, number, code } = state;
  const [invalidType, setInvalidType] = useState<InvalidVariants | null>(null);
  const [isDefaultValue, setDefaultValue] = useState(true);

  const secondsLeft = useGetSecondsLeft();

  const dispatch = useAppDispatch();
  const onFormSubmit = useCallback(
    async (data: LoginSmsCodeForm) => {
      const values: CreateAccountType = {
        name,
        surname,
        code,
        number,
        email,
        password,
        marketingNotes: agreement,
        otp: data.smmcode,
        accType: AccType.BUSINESS,
      };

      const response = await registration(values);
      if (response.id) {
        setInvalidType(null);
        const { email: userEmail, name: userName, id, avatarUrl, headOrgSpecialist } = response;
        setAccessToken(response.accessToken);
        dispatch(setLogin(true));
        dispatch(updateUser({ name: userName, email: userEmail, userId: id, avatarUrl, headOrgSpecialist }));
        useRedirectAfterLogin(navigate, !!headOrgSpecialist);
      } else setInvalidType(getInvalidType(response.statusCode, 'codeVerification'));
    },
    [agreement, email, name, password, number, code, invalidType],
  );

  const handleResendClick = async () => {
    setInvalidType(null);
    const phoneVer = { code, number, email };
    const response = await signupPhoneVerification(phoneVer);
    response && dispatch(updateSmsCodeTime(new Date().getTime()));
  };

  const formikContextValue = {
    initialValues: initialSmsCodeValues,
    validationSchema: loginSMSCodeFormValidationSchema(t),
    onSubmit: onFormSubmit,
    validateOnMount: true,
  };

  return (
    <>
      <H1 mb="16px">{t('registration.smsCode')}</H1>
      <BodySmall mb="32px">
        {t('registration.smsHint')}
        <br />
        {`${code} ${number}`}
      </BodySmall>
      <SmsForm
        formikContextValue={formikContextValue}
        invalidType={invalidType}
        setInvalidType={setInvalidType}
        isDefaultValue={isDefaultValue}
      />
      <Button
        disabled={secondsLeft}
        design="quaternary"
        mt="16px"
        width="100%"
        size="large"
        onClick={() => {
          handleResendClick();
          setDefaultValue(true);
        }}
      >
        {`${t(`registration.resendCode`)}${secondsLeft ? `${` 00:`}${`0${secondsLeft}`.slice(-2)}` : ``}`}
      </Button>
      <Rules />
    </>
  );
};

export default PhoneVerification;
