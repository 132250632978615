import { ColumnFlex } from '../../../style';
import { CardWrapper, WidgetText } from './style';

type MonthHeaderProps = {
  title: string;
};

export const MonthHeader = ({ title }: MonthHeaderProps) => (
  <CardWrapper>
    <ColumnFlex justifyContent="space-between" alignItems="start" width="100%">
      <WidgetText width="100%">{title}</WidgetText>
    </ColumnFlex>
  </CardWrapper>
);
