import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';
import { colors } from '@beauty/beauty-market-ui';

export const StyledTooltip = styled(Tooltip)`
  display: flex;
  flex-direction: column;
  border-radius: 4px !important;
  padding: 4px 10px !important;
  z-index: 1000;

  & > span {
    color: ${colors.white.standard};
  }
`;
