import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { emailReg } from '../../../../constants';
import { OptionType } from '../../../../types';
import { OrganisationHeadType } from '../../../../types/organisation';

export enum OrganisationDataFormFields {
  Photo = 'photo',
  Name = 'name',
  EmployeesNum = 'employeesNum',
  Email = 'email',
  Code = 'code',
  Phone = 'number',
  Category = 'category',
  Currency = 'currency',
}

export interface OrganisationDataFormTypes {
  [OrganisationDataFormFields.Photo]: File | null | string;
  [OrganisationDataFormFields.Name]: string;
  [OrganisationDataFormFields.EmployeesNum]: number;
  [OrganisationDataFormFields.Email]: string;
  [OrganisationDataFormFields.Code]: string;
  [OrganisationDataFormFields.Phone]: string;
  [OrganisationDataFormFields.Category]: string[];
  [OrganisationDataFormFields.Currency]: OptionType | null;
}

type InitialValuesPropsType = {
  head: OrganisationHeadType;
  categoryIds: string[];
  codeOptions;
  currencyOptions: OptionType[];
};

export const initialValues = ({
  head,
  categoryIds,
  codeOptions,
  currencyOptions,
}: InitialValuesPropsType): OrganisationDataFormTypes => {
  const codeItem = codeOptions.find(item => head.contact.phone[0].code === item.code);
  const currencyItem = currencyOptions.find(item => head.currency === item.id);
  const employeesIndex = head.employeesNum ? Number(head.employeesNum) - 1 : 0;
  return {
    [OrganisationDataFormFields.Photo]: head.photo || null,
    [OrganisationDataFormFields.Name]: head.name.text || '',
    [OrganisationDataFormFields.EmployeesNum]: employeesIndex,
    [OrganisationDataFormFields.Email]: head.contact.email.email || '',
    [OrganisationDataFormFields.Code]: codeItem ? codeItem.code : '',
    [OrganisationDataFormFields.Phone]: head.contact.phone[0].number || '',
    [OrganisationDataFormFields.Category]: categoryIds.length ? [...categoryIds] : [],
    [OrganisationDataFormFields.Currency]: currencyItem || null,
  };
};

export const OrganisationDataFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [OrganisationDataFormFields.Name]: Yup.string()
      .min(2, t('validation.tooShort'))
      .max(50, t('validation.tooLong'))
      .trim()
      .required(t('validation.notEmpty')),
    [OrganisationDataFormFields.EmployeesNum]: Yup.string(),
    [OrganisationDataFormFields.Email]: Yup.string()
      .trim()
      .email()
      .matches(emailReg, t('validation.emailNotValid'))
      .required(t('validation.notEmpty')),
    [OrganisationDataFormFields.Code]: Yup.string().required(t('validation.phoneRequired')),
    [OrganisationDataFormFields.Phone]: Yup.string()
      .matches(/^\d{7,}$/, t('validation.enterValidPhone'))
      .required(t('validation.phoneRequired')),
    [OrganisationDataFormFields.Category]: Yup.array().min(1, t('validation.notEmpty')),
  });
