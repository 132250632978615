import styled from 'styled-components';
import { colors, Flex, Link, Scroll, mediaQueries } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  position: relative;
  width: 100%;
  top: -12px;
  max-height: calc(100vh - 130px);
  ${mediaQueries.md} {
    width: 400px;
    max-height: calc(100vh - 152px);

    border-radius: 16px;
    padding: 0 4px 16px;
    position: absolute;
    z-index: 11;
    top: 80px;
    right: 102px;
    box-shadow:
      0 0 80px 0 rgba(0, 0, 0, 0.16),
      0 0 2px 0 rgba(0, 0, 0, 0.12);
  }
  background-color: ${colors.white.standard};
  flex-direction: column;
  box-sizing: border-box;
`;

export const TitleWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 24px;
  ${mediaQueries.md} {
    padding: 24px;
  }
  box-sizing: border-box;
`;

export const CardsWrapper = styled(Scroll)`
  padding-left: 4px;
  padding-right: 4px;
`;

export const FooterWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 96px;
  padding: 24px;
  background-color: ${colors.grey.light};
  ${mediaQueries.md} {
    height: 64px;
    padding: 16px 24px;
    background-color: ${colors.white.standard};
  }
  box-sizing: border-box;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

export const StyledLink = styled(Link)`
  gap: 4px !important;
`;
