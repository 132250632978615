import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import {
  BodySmall,
  Flex,
  getMinWidthMediaQuery,
  H5,
  Switcher,
  useMediaQuery,
  Caption,
  colors,
} from '@beauty/beauty-market-ui';
import { Paper, Tooltip } from 'components';
import { useRequest } from 'hooks';
import { SpecialistAction } from 'types';
import { useAppSelector } from '../../../../store/hooks';
import { selectSpecialist, ThunkSpecialist } from '../../../../store/redux-slices/specialistSlice';
import { getErrors } from '../helpers';

export const EmployeePublish = () => {
  const { t } = useTranslation();
  const { published, active, organization } = useAppSelector(selectSpecialist);

  const publishSpecialist = useRequest(ThunkSpecialist.editSpecialist, SpecialistAction.PublishOn);
  const unpublishSpecialist = useRequest(ThunkSpecialist.editSpecialist, SpecialistAction.PublishOff);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const errorMessages = getErrors(t, published, active, organization);

  const handleSwitchPublished = (e: ChangeEvent<HTMLInputElement>) => {
    const isPublished = e.currentTarget.checked;
    const params = { published: isPublished };
    isPublished ? publishSpecialist(params) : unpublishSpecialist(params);
  };

  return (
    <Paper alignItems="center" p={isDesktop ? '24px' : '16px 8px'} borderRadius={isDesktop ? '0' : '10px'} gap="16px">
      <Flex flexDirection="column" mr="auto" gap="4px">
        <H5>{t('specialists.availableForOnlineBooking')}</H5>
        {isDesktop ? (
          <BodySmall>{t('specialists.clientsCanBookAppointments')}</BodySmall>
        ) : (
          <Caption color={colors.grey.dark}>{t('specialists.toggleToMakeSpecialist')}</Caption>
        )}
      </Flex>
      <Tooltip id="publishing" show={!isEmpty(errorMessages)} place="top" content={errorMessages}>
        <Switcher
          data-tooltip-id="publishing"
          disabled={!isEmpty(errorMessages)}
          defaultChecked={!!published}
          size="small"
          onChange={handleSwitchPublished}
        />
      </Tooltip>
    </Paper>
  );
};
