import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const IconBox = styled(Flex)`
  align-self: center;
  gap: 16px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  width: 0;
  margin-left: 0;
`;

export const Box = styled(Flex)<{ available: boolean }>`
  &:hover ${IconBox} {
    opacity: ${({ available }) => (available ? 1 : 0)};
    width: ${({ available }) => (available ? 'max-content' : 0)};
    margin-left: ${({ available }) => (available ? '16px' : 0)};
  }
`;
