import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const FullName = styled(Flex)`
  gap: 8px;
  flex-wrap: wrap;

  ${mediaQueries.sm} {
    flex-wrap: nowrap;
  }
`;

export const StyledIcon = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.grey.light};
  border-radius: 50%;
  margin-right: 16px;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;

  & svg,
  & path {
    width: 24px;
    height: 24px;

    stroke: ${({ theme }) => theme.colors.blue.standard};
  }
`;
