import styled, { css } from 'styled-components';
import { Flex, H5, Caption, mediaQueries, colors } from '@beauty/beauty-market-ui';

export const CardWrapper = styled(Flex)<{ forAgenda: boolean }>`
  position: relative;
  width: 100%;
  padding: 10px 8px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

  button svg {
    display: flex;
    ${mediaQueries.md} {
      display: none;
    }
  }

  :hover {
    svg {
      display: flex;
      cursor: pointer;
    }
  }

  ${({ forAgenda }) =>
    forAgenda &&
    css`
      margin-top: 1px;
      margin-bottom: 1px;
      background-color: ${colors.white.standard};
      height: 72px;
    `}
`;

export const WidgetTitle = styled(Caption)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const WidgetText = styled(H5)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
