import { BackgroundCircle, ProgressCircle, CircleContent, CircleSvg, Circle } from './style';

export const CircleProgress = ({ progress, children, showCircle, design = 'grey' }) => (
  <Circle>
    {showCircle && (
      <CircleSvg viewBox="0 0 100 100">
        <BackgroundCircle design={design} />
        <ProgressCircle progress={progress} />
      </CircleSvg>
    )}
    <CircleContent>{children}</CircleContent>
  </Circle>
);
