import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { NotifyStatus, useNotify } from '@beauty/beauty-market-ui';
import { getNotifyContent } from '../helpers';
import { useAppDispatch } from '../store/hooks';
import {
  AddressAction,
  AppointmentsAction,
  OrganizationAction,
  ProfileActions,
  ServiceAction,
  SpecialistAction,
} from '../types';

type RequestFunction = (params?: unknown) => Promise<void>;

export const useRequest = (
  request,
  action: AddressAction | ServiceAction | SpecialistAction | AppointmentsAction | OrganizationAction | ProfileActions,
): RequestFunction => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notify = useNotify();

  return useCallback(
    (params = undefined) =>
      dispatch(request(params))
        .unwrap()
        .then(() => {
          const message = getNotifyContent(NotifyStatus.SUCCESS, action, t);
          if (message) notify(message);
        })
        .catch(err => {
          const message = getNotifyContent(NotifyStatus.ERROR, action, t);
          if (message) notify(message);
          throw err;
        }),
    [request, action, dispatch, notify, t],
  );
};
