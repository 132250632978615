import { MouseEvent } from 'react';
import { TFunction } from 'react-i18next';
import { isArray } from 'lodash';
import { NotifyPropsType, NotifyStatus } from '@beauty/beauty-market-ui';
import { formatPhoneNumber } from 'helpers';
import { AddressType } from 'types/organisation';
import { SpecialistOrganizationType } from 'types/specialist';
import {
  AddressFilterBy,
  ImageType,
  NamedAddressType,
  PhotoStoreType,
  PublishErrorType,
  PublishLogType,
} from '../../../types';
import { AddressCard } from './components';

export const getWarningMessage = (orgName: string, err: PublishErrorType, t: TFunction<'transition', undefined>) => {
  switch (err) {
    case PublishErrorType.ScheduleEmpty:
      return {
        title: t('address.alerts.title.emptyScheduleWarning'),
        subtitle: t('address.alerts.subtitle.emptyScheduleWarning', { orgName }),
      };
    case PublishErrorType.ServiceEmpty:
      return {
        title: t('address.alerts.title.emptyServiceWarning'),
        subtitle: t('address.alerts.subtitle.emptyServiceWarning', { orgName }),
      };
    case PublishErrorType.SpecialistEmpty:
      return {
        title: t('address.alerts.title.emptySpecialistWarning'),
        subtitle: t('address.alerts.subtitle.emptySpecialistWarning', { orgName }),
      };
    case PublishErrorType.UnpublishedSpecialist:
      return {
        title: t('address.alerts.title.unpublishSpecilaistWarning'),
        subtitle: t('address.alerts.subtitle.unpublishSpecilaistWarning', { orgName }),
      };
    case PublishErrorType.All:
      return {
        title: t('address.alerts.title.allWarningAtOneTime'),
        subtitle: t('address.alerts.subtitle.allWarningAtOneTime'),
      };
    default:
      return null;
  }
};

const getNote = (id, title, subtitle) => ({ id, status: NotifyStatus.WARNING, title, subtitle });

export const showWarningMessages = (
  orgLog: PublishLogType[],
  notify: (props: NotifyPropsType) => void,
  t: TFunction<'transition', undefined>,
) => {
  let messageId = 0;
  orgLog.forEach(({ name, errors }) => {
    if (errors.length === 3) {
      const message = getWarningMessage(name, PublishErrorType.All, t);
      messageId += 1;
      message && messageId === 1 && notify(getNote(messageId, message.title, message.subtitle));
    } else {
      errors.forEach(err => {
        const message = getWarningMessage(name, err, t);
        messageId += 1;
        return message && notify(getNote(messageId, message.title, message.subtitle));
      });
    }
  });
};

export const getPublishInfo = (t: TFunction<'translation', undefined>) => [
  t('address.oneSpecialist'),
  t('address.oneService'),
  t('address.specialistSchedule'),
  t('address.specialistPublish'),
];

export const filterAddressItems = (items, filter) =>
  items.filter(item => {
    switch (filter) {
      case AddressFilterBy.Online:
        return item.published;
      case AddressFilterBy.Offline:
        return !item.published;
      default:
        return true;
    }
  });

export const getAddressItems = (
  filter: AddressFilterBy,
  items?: NamedAddressType[] | SpecialistOrganizationType[],
  setItem?: (props: NamedAddressType | SpecialistOrganizationType) => void,
  isDesktop?: boolean,
) =>
  isArray(items)
    ? filterAddressItems(items, filter).map(item => {
        const handlePublishClick = (e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          setItem && setItem(item);
        };

        return (
          <AddressCard
            name={item.name}
            address={item.address}
            published={item.published}
            id={item?.id || item.orgId}
            key={item?.id || item.orgId}
            mainPhoto={item.mainPhoto?.url || item.mainPhoto}
            onPublishClick={handlePublishClick}
            isDesktop={isDesktop}
          />
        );
      })
    : [];

export const getValidationNotifyContent = (
  action: string,
  message: string,
  t: TFunction<'translation', undefined>,
): NotifyPropsType => ({
  id: action,
  status: NotifyStatus.ERROR,
  title: t('alerts.error'),
  subtitle: t(message),
});

export const extractAddress = (address: any) => {
  const { country, city, street, building, office: orgOffice } = address;
  const office = orgOffice ? `, ${orgOffice}` : '';
  return `${country}, ${city}, ${street}, ${building}${office}`;
};

export const getDeletedPhotos = (store: PhotoStoreType, photoToDelete: ImageType) => {
  const deletedPhoto = store.photoStore.find(item => item.url === photoToDelete);
  return deletedPhoto ? [...store.deletePhotos, deletedPhoto] : [...store.deletePhotos];
};

export const getTooltipInfo = (spec, t) =>
  [
    {
      text: t('address.missingInformation'),
      value: false,
    },
    {
      text: t('address.specialistNeedPublished'),
      value: spec.published,
    },
    {
      text: t('address.scheduleNeedFilled'),
      value: spec.publishedCheck.schedule,
    },
    {
      text: t('address.serviceNeedAdded'),
      value: spec.publishedCheck.service,
    },
  ]
    .filter(item => !item.value)
    .map(item => item.text);

export const getAddressContent = (org: AddressType, t: TFunction<'translation', undefined>) =>
  [
    {
      id: 'name',
      label: t('address.name'),
      value: org?.name,
    },
    {
      id: 'fullAddress',
      label: t('address.address'),
      value: [`${org.address?.street} ${org.address?.building}`, org.address?.city, org.address?.country].join(', '),
    },
    {
      id: 'email',
      label: t('address.email'),
      value: org?.email,
    },
    {
      id: 'instagram',
      label: t('address.instagram'),
      value: org?.social?.instagram ? `@${org.social.instagram}` : '',
    },
    {
      id: 'telegram',
      label: t('address.telegram'),
      value: org?.social?.telegram ?? '',
    },
    {
      id: 'phone',
      label: t('address.phone'),
      value: formatPhoneNumber(org?.phone?.code, org?.phone?.number),
    },
  ].filter(item => item.value);
