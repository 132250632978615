import styled from 'styled-components';
import { Flex, colors, mediaQueries } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)<{ delta?: number }>`
  flex-direction: column;
  height: ${({ delta }) => `calc(100vh - ${delta || 180}px)`};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  padding: 16px 8px;

  ${mediaQueries.md} {
    max-width: 1280px;
    padding: 24px 24px 8px;
  }
`;

export const GalleryImage = styled.img`
  object-fit: cover;
  border-radius: 10px;
  width: 170px;
  height: 120px;
`;

export const GalleryStub = styled(Flex)`
  width: 210px;
  height: 120px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.grey.light};
  padding: 10px 24px;
  box-sizing: border-box;
`;
