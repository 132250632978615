import { Views } from 'react-big-calendar';
import { createSlice, isAnyOf, isFulfilled, isRejected, PayloadAction } from '@reduxjs/toolkit';
import { uniq } from 'lodash';
import moment from 'moment/moment';
import { BreakType, CategoryDataType } from 'types/appointment';
import { EventStatus } from '../../constants';
import { getISOStartDay } from '../../page/Calendar/helpers';
import {
  AppointmentData,
  AppointmentDataType,
  AppointmentsState,
  SpecialistDataType,
  SpecialistSummaryType,
  AppointmentType,
  TimeType,
  OrganisationServiceResponse,
} from '../../types';
import { RelatedCategoryType } from '../../types/service';
import { logout } from '../actions/common';
import {
  createAppointment,
  getAppointmentsData,
  updateAppointment,
  convertGoogle,
  closeAppointment,
  cancelAllAppointment,
} from '../asyncActions/appointments';
import { RootState } from '../store';
import { ThunkAddress } from './addressSlice';

const initialState: AppointmentsState = {
  view: Views.DAY,
  date: getISOStartDay(moment()),
  range: {
    start: getISOStartDay(moment()),
    end: getISOStartDay(moment().add(1, 'day')),
  },
  unclosed: [],
  inProgress: [],
  specialistData: {},
  categoryData: {},
  specialistIds: [],
  categoryIds: [],
  selectedSpecialist: null,
  selectedCategory: null,
  selectedAddress: null,
  selectedClient: null,
  isLoading: true,
};

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    addAppointment: (state, action: PayloadAction<AppointmentType>) => {
      const specId = action.payload.orgSpecId;
      state.specialistData[specId].appointment.push(action.payload);
    },
    addBreak: (state, action: PayloadAction<BreakType>) => {
      const specId = action.payload.orgSpecialistId;
      state.specialistData[specId].break.push(action.payload);
    },
    deleteAppointment: (state, action: PayloadAction<AppointmentType>) => {
      const { orgSpecId, id, eventId } = action.payload;
      !eventId
        ? (state.specialistData[orgSpecId].appointment = state.specialistData[orgSpecId].appointment.filter(
            appointment => appointment.id !== id,
          ))
        : (state.specialistData[orgSpecId].googleEvents = state.specialistData[orgSpecId].googleEvents?.filter(
            appointment => appointment.id !== eventId,
          ));
    },
    deleteBreak: (state, action: PayloadAction<BreakType>) => {
      const { orgSpecialistId, id } = action.payload;
      state.specialistData[orgSpecialistId].break = state.specialistData[orgSpecialistId].break.filter(
        breakItem => breakItem.id !== id,
      );
    },
    changeAppointment: (state, action: PayloadAction<AppointmentType>) => {
      const specId = action.payload.orgSpecId;
      const appointmentId = action.payload.id;
      state.specialistData[specId].appointment = state.specialistData[specId].appointment.map(appointment =>
        appointment.id === appointmentId ? action.payload : appointment,
      );
    },
    changeBreak: (state, action: PayloadAction<BreakType>) => {
      const { orgSpecialistId: specId, id } = action.payload;
      state.specialistData[specId].break = state.specialistData[specId].break.map(breakItem =>
        breakItem.id === id ? action.payload : breakItem,
      );
    },
    setSpecialistAppointments: (state, action: PayloadAction<AppointmentData>) => {
      const specId = action.payload.id;
      if (specId) {
        state.specialistData[specId].appointment = action.payload.appointment;
        state.specialistData[specId].break = action.payload.break;
      }
      if (specId && action.payload.googleEvents) {
        state.specialistData[specId].googleEvents = action.payload.googleEvents;
      }
      state.isLoading = false;
    },
    setAppointments: (state, action: PayloadAction<AppointmentDataType[]>) => {
      action.payload.forEach(spec => {
        const specId = spec.orgSpecIds[0];
        if (state.specialistData[specId]) {
          state.specialistData[specId].appointment = spec.appointment;
          state.specialistData[specId].googleEvents = spec.googleEvents;
          state.specialistData[specId].break = spec.break;
        }
        state.isLoading = false;
      });
    },
    setUnclosedAppointments: (state, action: PayloadAction<AppointmentType[]>) => {
      state.unclosed = action.payload;
    },
    setInProgressAppointments: (state, action: PayloadAction<AppointmentType[]>) => {
      state.inProgress = action.payload;
    },
    updateInProgressAppointments: (state, action: PayloadAction<AppointmentType>) => {
      const eventId = action.payload.id;
      if (action.payload.status === EventStatus.CANCELLED) {
        state.inProgress = state.inProgress.filter(event => event.id !== eventId);
      } else {
        !state.inProgress.find(event => event.id === eventId)
          ? action.payload.status === EventStatus.INPROGRESS && state.inProgress.push(action.payload)
          : (state.inProgress[state.inProgress.findIndex(event => event.id === eventId)] = action.payload);
      }
    },
    updateUnclosedAppointments: (state, action: PayloadAction<AppointmentType>) => {
      const eventId = action.payload.id;
      !state.unclosed.find(event => event.id === eventId) && state.unclosed.push(action.payload);
      state.inProgress.find(event => event.id === eventId) &&
        (state.inProgress = state.inProgress.filter(event => event.id !== eventId));
    },
    deleteUnclosedAppointment: (state, action: PayloadAction<string>) => {
      state.unclosed = state.unclosed.filter(event => event.id !== action.payload);
    },
    clearAppointments: state => {
      state.specialistIds.forEach(specId => {
        if (state.specialistData[specId]) {
          state.specialistData[specId].appointment = [];
          state.specialistData[specId].googleEvents = [];
          state.specialistData[specId].break = [];
        }
      });
    },
    setSpecialistData: (state, action: PayloadAction<SpecialistSummaryType[]>) => {
      const data: SpecialistDataType = {};
      const ids: string[] = [];
      action.payload.forEach(spec => {
        const specId = spec.orgSpecId;
        data[specId] = { ...spec, appointment: [], googleEvents: [], break: [], orgSpecIds: [spec.orgSpecId] };
        ids.push(specId);
      });
      state.specialistData = data;
      state.specialistIds = ids;
    },
    setCategoryData: (state, action: PayloadAction<RelatedCategoryType[]>) => {
      const data: CategoryDataType = {};
      const ids: string[] = [];
      action.payload.forEach(orgService => {
        const { parentId } = orgService;
        if (!data[parentId]) data[parentId] = { title: orgService.parent.title, orgServiceIds: [], serviceIds: [] };
        data[parentId].orgServiceIds.push(orgService.id);
        ids.push(parentId);
      });
      state.categoryData = data;
      state.categoryIds = uniq(ids);
    },
    setServiceData: (state, action: PayloadAction<OrganisationServiceResponse[]>) => {
      action.payload.forEach(service => {
        state.categoryIds.forEach(categoryId => {
          state.categoryData[categoryId].orgServiceIds.includes(service.category.id) &&
            state.categoryData[categoryId].serviceIds.push(service.id);
        });
      });
    },
    setView: (state, action: PayloadAction<Views>) => {
      state.view = action.payload;
    },
    setRange: (state, action: PayloadAction<TimeType>) => {
      state.range = action.payload;
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    },
    setSelectedSpecialist: (state, action: PayloadAction<string | null>) => {
      state.selectedSpecialist = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<string | null>) => {
      state.selectedCategory = action.payload;
    },
    setSelectedAddress: (state, action: PayloadAction<string | null>) => {
      state.isLoading = true;
      state.selectedAddress = action.payload;
      state.selectedSpecialist = null;
      state.selectedCategory = null;
    },
    setSelectedClient: (state, action: PayloadAction<string | null>) => {
      state.selectedClient = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      // TODO Type properly
      .addCase(getAppointmentsData.fulfilled, (state, action) => {
        // action.payload.forEach(spec => {
        //   const specId = spec.orgSpecIds[0];
        //   state.specialistData[specId] = spec;
        //   state.specialistIds.push(specId);
        // });
        state.isLoading = false;
      })
      .addCase(getAppointmentsData.rejected, state => {
        state.specialistData = {};
        state.specialistIds = [];
      })
      .addCase(closeAppointment.fulfilled, (state, action) => {
        const index = state.unclosed.findIndex(item => item.id === action.meta.arg.id);
        if (index !== -1) state.unclosed.splice(index, 1);
      })
      .addCase(ThunkAddress.fetchAllAddresses.fulfilled, (state, action) => {
        if (!state.selectedAddress) {
          state.selectedAddress = action.payload[0].id;
        }
      })
      .addCase(logout, () => initialState)
      .addMatcher(isAnyOf(getAppointmentsData.pending), state => {
        state.isLoading = true;
      })
      .addMatcher(isFulfilled, state => {
        state.isLoading = false;
      })
      .addMatcher(isRejected, state => {
        state.isLoading = false;
      });
  },
});

export const {
  setAppointments,
  setUnclosedAppointments,
  setInProgressAppointments,
  updateInProgressAppointments,
  updateUnclosedAppointments,
  addAppointment,
  addBreak,
  changeBreak,
  deleteAppointment,
  deleteUnclosedAppointment,
  deleteBreak,
  changeAppointment,
  setSpecialistAppointments,
  setSpecialistData,
  clearAppointments,
  setView,
  setRange,
  setDate,
  setSelectedSpecialist,
  setSelectedCategory,
  setCategoryData,
  setServiceData,
  setSelectedAddress,
  setSelectedClient,
  setLoading,
} = appointmentsSlice.actions;

export const ThunkAppointments = {
  getAppointmentsData,
  createAppointment,
  updateAppointment,
  convertGoogle,
  closeAppointment,
  cancelAllAppointment,
};

export const selectAppointments = (state: RootState) => state.appointments;

export default appointmentsSlice.reducer;
