import { useTranslation } from 'react-i18next';
import { Button, PlusIcon } from '@beauty/beauty-market-ui';
import LogoutImage from '../../../assets/exit-image.png';
import { Stub } from '../../../components';

export const ClientStub = ({ onAddClick }) => {
  const { t } = useTranslation();
  return (
    <Stub
      title={t('clients.noClients')}
      subtitle={t('clients.addClientsToWork')}
      src={LogoutImage}
      alt={t('logout.altImg')}
    >
      <Button prefix={<PlusIcon width="20px" height="20px" />} onClick={onAddClick} design="primary" size="small">
        {t('clients.addNew')}
      </Button>
    </Stub>
  );
};
