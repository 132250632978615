import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BodyLarge, colors, MoreIcon } from '@beauty/beauty-market-ui';
import { languageOptions } from '../../Footer/const';
import { BlockSeparator, FooterWrapper, IconFlex, LanguageItem, LanguageList } from '../style';
import { MenuProps } from '../types';

const MenuFooter = ({ isFull }: MenuProps) => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectedLang = languageOptions.find(item => item.sortLan === i18n.resolvedLanguage);

  const handleClick = () => setIsOpen(!isOpen);
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
    setIsOpen(false);
  };

  return (
    <FooterWrapper isMenuOpen={isFull} isOpen={isOpen} onMouseLeave={() => setIsOpen(false)}>
      <LanguageList isOpen={isOpen}>
        <BlockSeparator show={isOpen} />
        {languageOptions.map(item => (
          <LanguageItem key={item.language} onClick={() => handleChange(item.sortLan)} event={isOpen}>
            <IconFlex size="24px">{item.flag}</IconFlex>
            {isFull && (
              <BodyLarge large color={colors.white.standard}>
                {t(`language.${item.language}`)}
              </BodyLarge>
            )}
          </LanguageItem>
        ))}
      </LanguageList>
      <BlockSeparator show={!isOpen} />
      <LanguageItem onClick={handleClick} event>
        <IconFlex size="24px">{selectedLang?.flag}</IconFlex>
        {isFull && (
          <>
            <BodyLarge large color={colors.white.standard}>
              {t(`language.${selectedLang?.language}`)}
            </BodyLarge>
            <IconFlex ml="auto" size="16px">
              <MoreIcon stroke={colors.white.standard} />
            </IconFlex>
          </>
        )}
      </LanguageItem>
    </FooterWrapper>
  );
};

export const MainMenuFooter = memo(MenuFooter);
