import { BodySmall, Checkbox, Flex } from '@beauty/beauty-market-ui';
import { ServiceCardProps } from '../types';

export const ServiceCard = ({ id, title, description, selectedServices, onChange }: ServiceCardProps) => {
  const serviceChecked = selectedServices.includes(id);
  const handleServiceClick = () => onChange([id]);

  return (
    <Flex p="10px 0 10px 36px" alignItems="center">
      <Flex flexDirection="column" gap="4px" mr="auto">
        <BodySmall>{title}</BodySmall>
        {/* <BodySmall color={colors.grey.dark}>{serviceAvailableFor}</BodySmall> */}
      </Flex>
      <Checkbox checked={serviceChecked} shape="round" onCheckboxClick={handleServiceClick} />
    </Flex>
  );
};
