import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { calendarColors, colors } from '@beauty/beauty-market-ui';
import { WidgetHeader } from 'components/Calendar/WidgetHeader/WidgetHeader';
import { AppointmentType, EventType, ResourceMapType, SpecialistDataType } from 'types';
import { EventStatus, SpecialistsRoles } from '../constants';
import { useAppSelector } from '../store/hooks';
import { selectAppointments } from '../store/redux-slices/appointmentsSlice';
// import { calendarColorsMock } from '../constants';

const getTime = ({ start, end }: { start: string; end: string }) => {
  const startTime = new Date(start);
  const endTime = new Date(end);
  const isMidnight = endTime.getDate() !== startTime.getDate();
  isMidnight && endTime.setTime(endTime.getTime() - 1);
  return {
    startTime,
    endTime,
  };
};

const getResource = (
  data: SpecialistDataType,
  specIds: string[],
  onAddBreak: () => void,
  setBreakSpecialist: (id: string) => void,
  setCancelAllSpecialist: (orgSpecId: string) => void,
  onCancelAll: () => void,
  setDateFromMenu: (date: string) => void,
  selectedWeekday: string,
  isMobile: boolean,
) => {
  let resourceMap: ResourceMapType[] = [];
  const urls = specIds.length > 1 ? specIds.map(specId => data[specId].avatarUrl) : undefined;
  const generalProps = {
    date: selectedWeekday,
    onAddBreak,
    onAddSpecialistBreak: setBreakSpecialist,
    onSelectDate: setDateFromMenu,
    onCancelSpecialistAll: setCancelAllSpecialist,
    onCancelAll,
  };
  if (isMobile && specIds.length === 1) {
    const specialist = data[specIds[0]];
    if (specialist) {
      const specialistName = `${specialist.name} ${specialist.surname}`;
      if (specialist.role !== SpecialistsRoles.MANAGER)
        resourceMap = [
          {
            resourceId: specIds[0],
            resourceTitle: (
              <WidgetHeader
                {...generalProps}
                avatarUrl={specialist.avatarUrl}
                specialistName={specialistName}
                title={specialist.specialization}
                text={specialistName}
                specId={specIds[0]}
                isMobile
              />
            ),
          },
        ];
    }
  } else if (isMobile && specIds.length > 1) {
    resourceMap = [
      {
        resourceId: '',
        resourceTitle: (
          <WidgetHeader
            {...generalProps}
            avatarUrl={null}
            specialistName={undefined}
            title=""
            text=""
            specId={null}
            urls={urls}
            isMobile
          />
        ),
      },
    ];
  } else
    specIds?.forEach(specId => {
      const specialist = data[specId];
      if (specialist) {
        const specialistName = `${specialist.name} ${specialist.surname}`;
        specialist.role !== SpecialistsRoles.MANAGER &&
          resourceMap.push({
            resourceId: specId,
            resourceTitle: (
              <WidgetHeader
                {...generalProps}
                avatarUrl={specialist.avatarUrl}
                specialistName={specialistName}
                title={specialist.specialization}
                text={specialistName}
                specId={specId}
              />
            ),
          });
      }
    });
  return resourceMap;
};

const getCurrentEvents = (
  currentEvents: AppointmentType[],
  specialistData: SpecialistDataType,
  showPhone: boolean,
  t: TFunction<'translation', undefined>,
) => {
  const events: EventType[] = [];
  currentEvents.forEach(event => {
    const { startTime, endTime } = getTime(event);
    const specialist = specialistData[event.orgSpecId];
    const specialistName = specialist ? `${specialist.name} ${specialist.surname}` : '';
    events.push({
      start: startTime,
      end: endTime,
      title: event.orgService?.title,
      resourceId: event.orgSpecId,
      resource: {
        // backgroundColor: calendarColorsMock[random(9)],
        appointmentId: event.id,
        backgroundColor: calendarColors.lilac,
        status: event.status,
        duration: event.orgService?.duration,
        price: event.orgService?.price,
        client: {
          name: `${event.client?.name} ${event.client?.surname}`,
          number:
            event.client?.code && event.client?.number && showPhone
              ? `${event.client?.code} ${event.client?.number}`
              : '',
          email: event.client?.email,
        },
        specialist: {
          specialistName,
          photo: specialist?.avatarUrl || '',
        },
      },
    });
  });
  return events;
};

const getEvents = (
  data: SpecialistDataType,
  specIds: string[],
  categoryId: string | null,
  showPhone: boolean,
  t: TFunction<'translation', undefined>,
) => {
  const events: EventType[] = [];
  specIds?.forEach(specId => {
    const specialist = data[specId];
    if (specialist) {
      const specialistName = `${specialist.name} ${specialist.surname}`;
      specialist.appointment.forEach(event => {
        const { startTime, endTime } = getTime(event);
        event.status !== EventStatus.CANCELLED &&
          specialist.role !== SpecialistsRoles.MANAGER &&
          (categoryId === null || categoryId === event.category.id) &&
          events.push({
            start: startTime,
            end: endTime,
            title: event.orgService?.title,
            resourceId: specId,
            resource: {
              // backgroundColor: calendarColorsMock[random(9)],
              appointmentId: event.id,
              backgroundColor: calendarColors.lilac,
              status: event.status,
              duration: event.orgService?.duration,
              price: event.orgService?.price,
              client: {
                name: `${event.client?.name} ${event.client?.surname}`,
                number:
                  event.client?.code && event.client?.number && showPhone
                    ? `${event.client?.code} ${event.client?.number}`
                    : '',
                email: event.client?.email,
              },
              specialist: {
                specialistName,
                photo: specialist.avatarUrl || '',
              },
            },
          });
      });

      specialist.googleEvents?.forEach(event => {
        const { startTime, endTime } = getTime(event);

        event.status !== EventStatus.CANCELLED &&
          specialist.role !== SpecialistsRoles.MANAGER &&
          events.push({
            start: startTime,
            end: endTime,
            title: event.title,
            resourceId: specId,
            resource: {
              // backgroundColor: calendarColorsMock[random(9)],
              appointmentId: event.id,
              backgroundColor: calendarColors.oceanBlue,
              status: event.status,
              specialist: {
                specialistName,
                photo: specialist.avatarUrl || '',
              },
              isGoogle: true,
              description: event.description,
            },
          });
      });

      specialist.break?.forEach(breakEvent => {
        const { startTime, endTime } = getTime(breakEvent);

        events.push({
          start: startTime,
          end: endTime,
          title: t('calendar.break'),
          resourceId: specId,
          resource: {
            // backgroundColor: calendarColorsMock[random(9)],
            appointmentId: breakEvent.id,
            backgroundColor: colors.white,
            status: EventStatus.PENDING,
            specialist: {
              specialistName,
              photo: specialist.avatarUrl || '',
            },
            isBreak: true,
          },
        });
      });
    }
  });
  return events;
};

type UseCalendarDataProps = {
  onAddBreak: () => void;
  setBreakSpecialist: (id: string) => void;
  setCancelAllSpecialist: (orgSpecId: string) => void;
  onCancelAll: () => void;
  setDateFromMenu: (date: string) => void;
  selectedWeekday: string;
  isShowPhone: boolean;
  isMobile: boolean;
};

export const useCalendarData = ({
  onAddBreak,
  setBreakSpecialist,
  setCancelAllSpecialist,
  onCancelAll,
  setDateFromMenu,
  selectedWeekday,
  isShowPhone,
  isMobile,
}: UseCalendarDataProps) => {
  const { t } = useTranslation();
  const { specialistData, specialistIds, selectedSpecialist, selectedCategory, categoryData, unclosed, inProgress } =
    useAppSelector(selectAppointments);

  const hasAppointments = isArray(specialistIds) && specialistIds.length;

  const specIds = selectedSpecialist ? [selectedSpecialist] : specialistIds;

  const events = useMemo(
    () => (hasAppointments ? getEvents(specialistData, specIds, selectedCategory, isShowPhone, t) : []),
    [hasAppointments, specialistData, specIds, selectedCategory, categoryData, isShowPhone, t],
  );

  const unclosedEvents = useMemo(
    () => (unclosed ? getCurrentEvents(unclosed, specialistData, isShowPhone, t) : []),
    [unclosed, specialistData, isShowPhone, t],
  );

  const inProgressEvents = useMemo(
    () => (inProgress ? getCurrentEvents(inProgress, specialistData, isShowPhone, t) : []),
    [inProgress, specialistData, isShowPhone, t],
  );

  const resourceMap = useMemo(
    () =>
      hasAppointments
        ? getResource(
            specialistData,
            selectedSpecialist ? [selectedSpecialist] : specialistIds,
            onAddBreak,
            setBreakSpecialist,
            setCancelAllSpecialist,
            onCancelAll,
            setDateFromMenu,
            selectedWeekday,
            isMobile,
          )
        : [],
    [hasAppointments, selectedSpecialist, specialistIds, t, isMobile],
  );
  const specialistCount = useMemo(
    () => isArray(specialistIds) && specialistIds.length,
    [specialistIds, selectedSpecialist],
  );

  return {
    resourceMap,
    events,
    unclosedEvents,
    inProgressEvents,
    specialistCount,
  };
};
