import styled from 'styled-components';
import { colors, Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { StyledButton } from '../KebabMenu/style';

const getBackgroundBodyColor = type => {
  switch (type) {
    case 'today':
      return colors.blue.light;
    case 'past':
      return colors.grey.light;
    default:
      return 'transparent';
  }
};

export const DateContainer = styled(Flex)<{ isShowDots: boolean }>`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  height: 100%;
  overflow: visible;

  &:hover ${StyledButton} {
    * {
      display: ${({ isShowDots }) => (isShowDots ? `flex` : `none`)};
    }
  }
`;

export const DateAppointment = styled(Flex)`
  width: auto;
  border-radius: 10px;
  padding: 0 8px;
  ${mediaQueries.md} {
    width: 100%;
    padding: 2px 8px;
  }
  background-color: ${colors.blue.standard};
  color: white;
  cursor: pointer;
`;

export const DateHeader = styled(Flex)`
  position: relative;
  width: auto;
  ${mediaQueries.md} {
    width: 100%;
  }
  align-items: center;
  justify-content: space-between;
`;

export const BodyWrapper = styled(Flex)<{ type: string }>`
  width: 100%;
  height: 100%;

  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  ${({ type }) => `background-color: ${getBackgroundBodyColor(type)}`}
   flex-direction: row;
    border-bottom: 1px solid ${colors.grey.light};
    ${mediaQueries.md} {
      flex-direction: column;
      border-bottom: none;
      padding: 0;
      background-color: transparent;
    }
  }

  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
