import { UKFlag, IsraelFlag, RussiaFlag } from '@beauty/beauty-market-ui';

export const languageOptions = [
  {
    flag: <UKFlag width="21px" height="21px" />,
    language: 'english',
    sortLan: 'en',
  },
  {
    flag: <IsraelFlag width="21px" height="21px" />,
    language: 'hebrew',
    sortLan: 'he',
  },
  {
    flag: <RussiaFlag width="21px" height="21px" />,
    language: 'russian',
    sortLan: 'ru',
  },
];
