import { Trans, useTranslation } from 'react-i18next';
import { Button, Flex, H5, H6 } from '@beauty/beauty-market-ui';
import { usePrice } from 'hooks';
import { SubscriptionPricePlanType } from 'types';
import { transComponents } from '../../../constants';
import { Tooltip } from '../../Tooltip';
import { Paper } from '../style';
import { BlueSpanInclude } from './style';

export const TelegramSection = ({ onClick }) => {
  const { t } = useTranslation();
  const { price, amount, isPast, isAvailable, isConnected } = usePrice(SubscriptionPricePlanType.TELEGRAM);

  return (
    <Paper justifyContent="space-between" alignItems="flex-start">
      <Flex flexDirection="column" gap="8px" width="100%">
        <H5>{t('profile.connectTelegramTitle')}</H5>
        <BlueSpanInclude>
          <Trans components={transComponents}>
            {isConnected
              ? t('profile.yourPlatformSyncWithOutUserName', { value: 'Telegram' })
              : t('profile.connectTelegramInfo')}
          </Trans>
        </BlueSpanInclude>
      </Flex>
      <Flex gap="24px" alignItems="center" flexShrink={0}>
        {!isAvailable && !!amount && <H6>{`${price} ${t('subscriptions.perMonth')}`}</H6>}
        <Tooltip
          id="telegram-connect"
          content={t('subscriptions.forActivateNeedToHave', { value: 'Telegram' })}
          show={isPast && !isConnected}
        >
          <Button
            data-tooltip-id="telegram-connect"
            onClick={onClick}
            disabled={isPast && !isConnected}
            design={isConnected ? 'secondary' : 'primary'}
            size="extraSmall"
          >
            {isConnected ? t('profile.disconnect') : t('profile.connect')}
          </Button>
        </Tooltip>
      </Flex>
    </Paper>
  );
};
