import { forwardRef, memo, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik, FormikProps } from 'formik';
import {
  Caption,
  colors,
  H6,
  ListRegionCode,
  Select,
  TabCategory,
  TabStack,
  UploadSinglePhoto,
} from '@beauty/beauty-market-ui';
import { getListRegionCode, getPhotoImage, hasArrayChanged } from 'helpers/utils';
import { useGetTopCategories } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectHead } from 'store/redux-slices/organisationSlice';
import { ColumnFlex } from 'style';
import { HeadOrganisationFormType, OptionType } from 'types';
import { FormikInput } from '../../../../components/functional/formik/formik-input/FormikInput';
import { getCurrencyOptions, getSelectedLanguage } from '../../../../constants';
import { CategoriesWrapper, PhoneInputsWrapper, SidebarContentSection, SidebarContentWrapper } from '../style';
import {
  initialValues as getInitialValues,
  OrganisationDataFormFields,
  OrganisationDataFormTypes,
  OrganisationDataFormValidationSchema,
} from './HeadOrganisationData.definitions';

interface OrganisationDataProps {
  editMode: boolean;
  setIsValid: (value: boolean) => void;
  setIsDirty: (value: boolean) => void;
  onSubmit: (params: HeadOrganisationFormType) => void;
}

const OrganisationData = memo(
  forwardRef(({ setIsValid, setIsDirty, editMode, onSubmit }: OrganisationDataProps, ref) => {
    const { t } = useTranslation();
    const head = useAppSelector(selectHead);
    const { topCategoriesData } = useGetTopCategories();
    const categoryIds = head.headOrgCategory.map(item => item.serviceCategoryId);
    const initialCategoryIds = useRef(categoryIds);

    const language = getSelectedLanguage();
    const codeOptions = getListRegionCode();
    const currencyOptions = getCurrencyOptions(t);

    const tabStackItems = [`${t('settings.sidebarOrganisationSettings.justMe')}`, '2', '3', '4', '5', '6', '7+'];

    const onFormSubmit = ({ employeesNum, category, currency, ...rest }: OrganisationDataFormTypes) => {
      const categoryString = JSON.stringify(category);
      const params = {
        ...rest,
        language,
        currency: currency?.id,
        category: categoryString,
        employeesNum: employeesNum + 1,
      };
      onSubmit(params);
    };

    const formikContextValue = {
      initialValues: getInitialValues({ head, categoryIds, currencyOptions, codeOptions }),
      validationSchema: OrganisationDataFormValidationSchema(t),
      onSubmit: onFormSubmit,
      validateOnMount: false,
    };

    const { Photo, Code, Currency, Phone, Email, Name, EmployeesNum, Category } = OrganisationDataFormFields;

    return (
      <Formik
        innerRef={ref as (instance: FormikProps<OrganisationDataFormTypes> | null) => void}
        {...formikContextValue}
      >
        {({ isValid, dirty, setFieldValue, values }) => {
          useEffect(() => {
            const hasChanges = hasArrayChanged(initialCategoryIds.current, values[Category]);
            setIsDirty(dirty || hasChanges);
          }, [dirty, values[Category].length]);

          useEffect(() => {
            setIsValid(isValid);
          }, [isValid]);

          return (
            <Form>
              <SidebarContentWrapper>
                {!editMode && (
                  <ColumnFlex mb="24px">
                    <H6>{t('settings.sidebarOrganisationSettings.logotype')}</H6>
                    <Caption mb="16px" lowline color={colors.grey.dark}>
                      {t('settings.sidebarOrganisationSettings.logoHint')}
                    </Caption>
                    <UploadSinglePhoto
                      id={Photo}
                      name={Photo}
                      image={getPhotoImage(values[Photo])}
                      onUpload={(imageData: File) => setFieldValue(Photo, imageData)}
                      width="101px"
                    />
                  </ColumnFlex>
                )}
                <SidebarContentSection>
                  <H6>{t('settings.sidebarOrganisationSettings.title')}</H6>
                  <FormikInput
                    id={Name}
                    name={Name}
                    placeholder={t('settings.sidebarOrganisationSettings.namePlaceholder')}
                    design="white"
                  />
                  {editMode && (
                    <Field
                      id={Currency}
                      name={Currency}
                      options={currencyOptions}
                      selected={values[Currency]}
                      onSelect={(item: OptionType) => setFieldValue(Currency, item)}
                      placeholder={t('settings.sidebarOrganisationSettings.currency')}
                      component={Select}
                      width="102px"
                      mr="8px"
                    />
                  )}
                </SidebarContentSection>
                {!editMode && (
                  <SidebarContentSection>
                    <H6>{t('settings.sidebarOrganisationSettings.allEmployees')}</H6>
                    <TabStack
                      id={EmployeesNum}
                      name={EmployeesNum}
                      items={tabStackItems}
                      active={values[EmployeesNum]}
                      onStackClick={(index: number) => setFieldValue(EmployeesNum, index)}
                    />
                  </SidebarContentSection>
                )}
                <SidebarContentSection>
                  <H6>{t('settings.sidebarOrganisationSettings.contact')}</H6>
                  <PhoneInputsWrapper>
                    <Field
                      id={Code}
                      name={Code}
                      options={codeOptions}
                      currentRegionCode={values[Code]}
                      handleSelect={(_code: string) => setFieldValue(Code, _code)}
                      placeholder={t('settings.sidebarOrganisationSettings.region')}
                      component={ListRegionCode}
                      design="white"
                      width="102px"
                      mr="8px"
                    />
                    <FormikInput
                      id={Phone}
                      name={Phone}
                      placeholder={t('settings.sidebarOrganisationSettings.phone')}
                      design="white"
                      width="100%"
                    />
                  </PhoneInputsWrapper>
                  <FormikInput
                    id={Email}
                    name={Email}
                    placeholder={t('settings.sidebarOrganisationSettings.email')}
                    design="white"
                    width="100%"
                  />
                </SidebarContentSection>
                <SidebarContentSection>
                  <H6>{t('settings.sidebarOrganisationSettings.occupation')}</H6>
                  <CategoriesWrapper>
                    {topCategoriesData.map(({ id, icon, name }) => (
                      <TabCategory
                        id={id}
                        key={id}
                        icon={icon}
                        title={name}
                        selected={values[Category].includes(id)}
                        onClick={() => {
                          const index = values[Category].findIndex(category => category === id);
                          if (index !== -1) {
                            const isNotInitialCategory = !initialCategoryIds.current.includes(values[Category][index]);
                            isNotInitialCategory && values[Category].splice(index, 1);
                          } else {
                            values[Category].push(id);
                          }
                          setFieldValue(Category, values[Category]);
                        }}
                        size="medium"
                      />
                    ))}
                  </CategoriesWrapper>
                </SidebarContentSection>
              </SidebarContentWrapper>
            </Form>
          );
        }}
      </Formik>
    );
  }),
);

export default OrganisationData;
