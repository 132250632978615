import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, getMinWidthMediaQuery, H4, useMediaQuery } from '@beauty/beauty-market-ui';
import { Paper } from '../../components';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { selectUser, ThunkUser } from '../../store/redux-slices/userSlice';
import { ChangePassword, DeleteAccount, GoogleCalendarSync, NotificationLanguage, Telegram } from './components';
import { ProfileCard } from './components/ProfileCard';

export const PersonalProfile = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector(selectUser);

  const dispatch = useAppDispatch();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  useEffect(() => {
    if (user.userId) dispatch(ThunkUser.getProfile(user.userId));
  }, []);

  return (
    <>
      <Flex width="100%" flexDirection="column" gap="1px" mb="80px">
        {!isDesktop && <ProfileCard />}
        <Paper borderRadius={isDesktop ? '10px 10px 0 0' : '10px'} p={isDesktop ? '24px' : '16px 8px'}>
          <H4>{t('profile.personalProfile')}</H4>
        </Paper>
        <ChangePassword />
        <GoogleCalendarSync />
        <Telegram />
        <NotificationLanguage />
        <DeleteAccount />
      </Flex>
      {isDesktop && <ProfileCard />}
    </>
  );
};
