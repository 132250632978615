import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { Flex, useNotify } from '@beauty/beauty-market-ui';
import { hasScheduleChanges, updatePhotosByChunk } from 'helpers';
import { useRequest } from 'hooks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAddressState, ThunkAddress, updateMainPhoto } from '../../../store/redux-slices/addressSlice';
import { AddressAction } from '../../../types';
import { Schedule, SidebarSheet } from '../../index';
import { SidebarFooter } from '../../SidebarFooter';
import { defaultAddressFields } from '../constants';
import { DetailedInformationForm, DetailedInformationFormType } from '../DetailedInformation';
import { MainInformationFormType } from '../MainInformation';
import { MainInformationForm } from '../MainInformation/MainInformationForm';
import { PhotosForm } from '../Photos/PhotosForm';
import { ScheduleView } from '../Schedule/ScheduleView';
import { EditAddressPropsType } from '../types';

export const EditAddress = ({ onClose, fields = defaultAddressFields }: EditAddressPropsType) => {
  const { t } = useTranslation();
  const { newAddress, initialValues } = useAppSelector(selectAddressState);
  const { prevSchedule, schedule, name, allDayTemplate } = newAddress;

  const updateOrganisation = useRequest(ThunkAddress.editOrganization, AddressAction.EditAddress);

  const dispatch = useAppDispatch();
  const notify = useNotify();

  const currentSchedule = allDayTemplate || schedule;

  const [isSubmitting, setSubmitting] = useState(false);

  const [isEditScheduleOpen, setIsEditScheduleOpen] = useState(false);

  const [isPhotoDirty, setIsPhotoDirty] = useState(false);
  const [isPhotoValid, setIsPhotoValid] = useState(true);

  const [isMainDirty, setIsMainDirty] = useState(false);
  const [isMainValid, setIsMainValid] = useState(true);

  const [isDetailedDirty, setIsDetailedDirty] = useState(false);
  const [isDetailedValid, setIsDetailedValid] = useState(true);

  const [isScheduleDirty, setIsScheduleDirty] = useState(false);

  const mainFormRef = useRef<FormikProps<MainInformationFormType>>();
  const detailedFormRef = useRef<FormikProps<DetailedInformationFormType>>();

  const handleClose = () => {
    if (initialValues?.photos?.mainPhoto) {
      dispatch(updateMainPhoto(initialValues.photos.mainPhoto));
    }
    onClose();
  };

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      if (isPhotoDirty) {
        await updatePhotosByChunk(newAddress.photos, AddressAction.EditPhotos, dispatch, notify);
      }
      if (isMainDirty || isDetailedDirty || isScheduleDirty) {
        await mainFormRef.current?.submitForm();
        await detailedFormRef.current?.submitForm();
        await updateOrganisation();
      }
    } finally {
      onClose();
      setSubmitting(false);
    }
  };

  const handleOpenEditSchedule = () => setIsEditScheduleOpen(true);
  const handleCloseEditSchedule = () => setIsEditScheduleOpen(false);

  const isValid = isPhotoValid && isMainValid && isDetailedValid;
  const isDirty = isPhotoDirty || isMainDirty || isDetailedDirty || isScheduleDirty;
  const isDisabled = !isDirty || !isValid;

  const footer = (
    <SidebarFooter
      applyChanges
      cancel
      disabled={isDisabled}
      onSubmit={handleSubmit}
      onBack={handleClose}
      isLoading={isSubmitting}
    />
  );

  useEffect(() => {
    dispatch(ThunkAddress.fetchCoordinates());
  }, []);

  useEffect(() => {
    const dirty = hasScheduleChanges(prevSchedule, currentSchedule);
    setIsScheduleDirty(dirty);
  }, [prevSchedule, currentSchedule]);

  return (
    <SidebarSheet
      FooterBody={footer}
      onClose={handleClose}
      label={`${t('settings.addresses.sidebar.editOrganisation')} "${name}"`}
      descriptor={t('settings.addresses.sidebar.updateCommonInformation')}
    >
      <Flex flexDirection="column" gap="24px">
        {fields?.photos && (
          <PhotosForm editBar setIsDirty={setIsPhotoDirty} setIsValid={setIsPhotoValid} fields={fields.photos} />
        )}
        {fields?.mainInformation && (
          <MainInformationForm
            editBar
            ref={mainFormRef}
            setIsValid={setIsMainValid}
            setIsDirty={setIsMainDirty}
            fields={fields.mainInformation}
          />
        )}
        {fields?.detailedInformation && (
          <DetailedInformationForm
            editBar
            ref={detailedFormRef}
            setIsValid={setIsDetailedValid}
            setIsDirty={setIsDetailedDirty}
            fields={fields.detailedInformation}
          />
        )}
        {fields?.schedule && <ScheduleView schedule={currentSchedule} onClick={handleOpenEditSchedule} />}
        {fields?.schedule && isEditScheduleOpen && (
          <Schedule
            editBar
            stepper={<div />}
            handleNextStep={handleCloseEditSchedule}
            handleBackStep={handleCloseEditSchedule}
            handleClose={onClose}
          />
        )}
      </Flex>
    </SidebarSheet>
  );
};
