import { TFunction } from 'react-i18next';
import { NavigateFunction } from 'react-router-dom';
import {
  CalendarIcon,
  CoinsHandIcon,
  colors,
  HomeIcon,
  PinIcon,
  ProfileIcon,
  StarFilledIcon,
  WalletIcon,
  ToolsIcon,
  SlidersIcon,
} from '@beauty/beauty-market-ui';
import { AccessRange } from '../../constants';
import { RouterUrl } from '../../routes/routes';

export const getGeneralItems = (t: TFunction<'translation', undefined>, navigate: NavigateFunction) => ({
  title: t('menu.general'),
  items: [
    {
      id: `general-${0}`,
      title: t('menu.home'),
      icon: HomeIcon,
      fill: colors.grey.standard,
      isStroke: false,
      onClick: () => navigate(RouterUrl.AccountSetting),
      itemsList: [
        { title: 'item1', count: '23' },
        { title: 'item2', count: '6' },
        { title: 'item3', count: '823' },
        { title: 'item4', count: '600' },
      ],
      accessRange: AccessRange.ADMIN,
      isMobile: true,
    },
    {
      id: `general-${1}`,
      title: t('menu.calendar'),
      icon: CalendarIcon,
      fill: colors.grey.standard,
      isStroke: false,
      onClick: () => navigate(RouterUrl.Calendar),
      accessRange: AccessRange.SPECIALIST,
      isMobile: true,
    },
    {
      id: `general-${2}`,
      title: t('menu.clients'),
      icon: CoinsHandIcon,
      fill: 'none',
      isStroke: true,
      onClick: () => navigate(RouterUrl.Clients),
      accessRange: AccessRange.ADMIN,
      isMobile: true,
    },
    /* {
        title: t('menu.online'),
        icon: GlobeIcon,
        fill: 'none',
        isStroke: true,
      },
      {
        title: t('menu.reports'),
        icon: ReportsIcon,
        fill: 'none',
        isStroke: true,
      }, */
  ],
});

export const getFinanceItems = (t: TFunction<'translation', undefined>) => ({
  title: t('menu.finance'),
  items: [
    {
      title: t('menu.sales'),
      count: '48',
      icon: StarFilledIcon,
      itemsList: [
        { title: 'item1', count: '23' },
        { title: 'item2', count: '6' },
      ],
    },
    {
      title: t('menu.discounts'),
      icon: WalletIcon,
    },
  ],
});

export const getInstrumentsItems = (t: TFunction<'translation', undefined>, navigate: NavigateFunction) => ({
  title: t('menu.instruments'),
  items: [
    {
      id: `instruments-${0}`,
      title: t('menu.addresses'),
      icon: PinIcon,
      fill: colors.grey.standard,
      isStroke: true,
      onClick: () => navigate(RouterUrl.AddressList),
      accessRange: AccessRange.ADMIN,
      isMobile: true,
    },
    {
      id: `instruments-${1}`,
      title: t('menu.employees'),
      icon: ProfileIcon,
      fill: 'none',
      isStroke: true,
      onClick: () => navigate(RouterUrl.EmployeeList),
      accessRange: AccessRange.ADMIN,
      isMobile: true,
    },
    {
      id: `instruments-${2}`,
      title: t('menu.services'),
      icon: ToolsIcon,
      fill: 'none',
      isStroke: true,
      onClick: () => navigate(RouterUrl.ServiceList),
      accessRange: AccessRange.ADMIN,
      isMobile: false,
    },
    {
      id: `instruments-${3}`,
      title: t('menu.settings'),
      icon: SlidersIcon,
      fill: 'none',
      isStroke: true,
      onClick: () => navigate(RouterUrl.ProfileSettings),
      accessRange: AccessRange.ADMIN,
      isMobile: false,
    },
  ],
});

export const disabledLinksByHeadOrgConditions = [
  `general-${1}`,
  `general-${2}`,
  `instruments-${0}`,
  `instruments-${1}`,
  `instruments-${2}`,
];

export const getTooltipInfo = (acceptance, t) =>
  [
    {
      text: t('menu.tooltip.pageNotAvailable'),
      value: true,
    },
    {
      text: t('menu.tooltip.addAddress'),
      value: acceptance.address,
    },
    {
      text: t('menu.tooltip.addSpecialist'),
      value: acceptance.specialist,
    },
    {
      text: t('menu.tooltip.addService'),
      value: acceptance.service,
    },
  ]
    .filter(item => !!item.value)
    .map(item => item.text);

export const sectionsBody = [getGeneralItems, getInstrumentsItems /* , getFinanceItems,  */];
