import { useTranslation } from 'react-i18next';
import { ContentCard, Flex, Icon } from '@beauty/beauty-market-ui';
import { TopCategoryCardProps } from '../types';
import { SubCategoryCard } from './SubCategoryCard';

export const TopCategoryCard = ({
  icon,
  title,
  selectedServices,
  subCategories,
  serviceIds,
  onChange,
}: TopCategoryCardProps) => {
  const { t } = useTranslation();
  const topCategoryChecked = serviceIds.every(id => selectedServices.includes(id));
  const topCategoryIcon = (
    <Icon height="40px" width="40px">
      {icon}
    </Icon>
  );

  const handleTopCategoryClick = () => onChange(serviceIds);

  return (
    <Flex flexDirection="column" gap="16px" ml="1px">
      <ContentCard
        heading={title}
        label={t('common.selectAll')}
        checked={topCategoryChecked}
        serviceIcon={topCategoryIcon}
        onChange={handleTopCategoryClick}
      />
      <Flex flexDirection="column" gap="8px">
        {Object.values(subCategories).map(subCategory => (
          <SubCategoryCard
            id={subCategory.id}
            key={subCategory.id}
            title={subCategory.title}
            services={subCategory.services}
            serviceIds={subCategory.serviceIds}
            selectedServices={selectedServices}
            onChange={onChange}
          />
        ))}
      </Flex>
    </Flex>
  );
};
