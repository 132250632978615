export { ActivateBar } from './ActivateBar';
export { PaymentBar } from './PaymentBar';
export { EmployeeCoverStack } from './EmployeeCoverStack';
export { EmployeePublish } from './EmployeePublish';
export { EmployeeSchedule } from './Schedule/EmployeeSchedule';
export { EmployeeServices } from './Services/EmployeeServices';
export { EmployeeWorkplaces } from './Workplaces/EmployeeWorkplaces';
export { About } from './About';

export { Education } from './Education/Education';
export { Languages } from './Languages/Languages';
export { PhotoGallery } from './PhotoGallery/PhotoGallery';
