import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {
  colors,
  ExtendedSearchInput,
  Flex,
  getMinWidthMediaQuery,
  H3,
  H4,
  PlusIcon,
  RightIcon,
  Scroll,
  Separator,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { formatPhoneNumber, getFullName } from 'helpers';
import { RouterUrl } from 'routes/routes';
import { useAppSelector } from 'store/hooks';
import { selectClients } from 'store/redux-slices/clientSlice';
import { InputWrapper, StyledButton, Wrapper } from '../style';
import { ClientCard } from './ClientCard';

export const ClientSection = ({ onAddClick }) => {
  const { t } = useTranslation();
  const clients = useAppSelector(selectClients);
  const navigate = useNavigate();

  const mediaQuery = getMinWidthMediaQuery('sm');
  const isDesktop = useMediaQuery(mediaQuery);

  const [toSearch, setSearch] = useState('');

  const filteredClients = useMemo(() => {
    const toSearchWithoutSpaces = toSearch.toLowerCase().trim().split(' ').join('');
    return clients.filter(({ name, surname, email, code, number, idNumber }) => {
      const combinedProperties = [name, surname, email, code, number, idNumber].filter(Boolean).join('').toLowerCase();
      return combinedProperties.includes(toSearchWithoutSpaces);
    });
  }, [toSearch, clients]);

  return (
    <Wrapper maxWidth="1280px">
      <Flex width="100%" justifyContent="space-between" pb={isDesktop ? '16px' : '24px'} alignItems="center">
        {isDesktop ? <H3>{t('clients.title')}</H3> : <H4>{t('clients.title')}</H4>}
        <StyledButton size="small" prefix={<PlusIcon />} onClick={onAddClick}>
          {isDesktop ? t('clients.add') : ''}
        </StyledButton>
      </Flex>
      {isDesktop && <Separator mb="24px" />}
      <InputWrapper>
        <ExtendedSearchInput
          onlyService
          placeholder={t('clients.placeholder')}
          maxWidth="100%"
          onChange={e => setSearch(e.target.value)}
          onCrossClick={() => setSearch('')}
          zIndex={1}
        />
      </InputWrapper>
      <Scroll scrollColor={colors.grey.light} mt="100px">
        {filteredClients.map(item => {
          const { idNumber, avatar, headOrgClientId, code, number, email } = item;
          const phone = formatPhoneNumber(code, number);
          const contact = [phone, isDesktop ? email : null, idNumber].filter(Boolean).join(' • ');
          return (
            <ClientCard
              key={headOrgClientId}
              avatar={avatar}
              icon={<RightIcon />}
              heading={getFullName(item)}
              subtitle={contact}
              onClick={() => navigate(generatePath(RouterUrl.ClientPageData, { id: headOrgClientId }))}
            />
          );
        })}
      </Scroll>
    </Wrapper>
  );
};
