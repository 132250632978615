import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export enum DetailedInformationFormFields {
  Country = 'country',
  City = 'city',
  Street = 'street',
  Building = 'building',
  Office = 'office',
  Post = 'postal',
  Description = 'description',
}

export type DetailedInformationFormType = {
  [DetailedInformationFormFields.Country]: string;
  [DetailedInformationFormFields.City]: string;
  [DetailedInformationFormFields.Street]: string;
  [DetailedInformationFormFields.Building]: string;
  [DetailedInformationFormFields.Office]: string | null;
  [DetailedInformationFormFields.Post]: string | null;
  [DetailedInformationFormFields.Description]: string | null;
};

export const detailedInformationValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [DetailedInformationFormFields.Country]: Yup.string().trim().required(t('validation.notEmpty')),
    [DetailedInformationFormFields.City]: Yup.string().trim().required(t('validation.notEmpty')),
    [DetailedInformationFormFields.Street]: Yup.string().trim().required(t('validation.notEmpty')),
    [DetailedInformationFormFields.Building]: Yup.string().trim().required(t('validation.notEmpty')),
    [DetailedInformationFormFields.Description]: Yup.string()
      .trim()
      .min(2, t('validation.minLength2'))
      .max(150, t('validation.maxLength150')),
  });
