import { useMemo, useRef, useState } from 'react';
import moment from 'moment';
import { Flex, Caption, colors, getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { BreakType, GoogleEventType } from 'types/appointment';
import { EventType } from 'types/calendar';
import { statusIcons, EventStatus, cardTooltipWidth, timeStepInDayWeekView } from '../../../constants';
import { CardTooltip } from '../CardTooltip';
import { EventWrapper } from './style';

type DayEventProps = {
  event: EventType;
  currentDate: Date;
  handleClick: (id: string, event?: GoogleEventType | BreakType) => void;
  isSingleAppointment: boolean;
  isAgenda?: boolean;
};

export const DayEvent = ({ event, currentDate, handleClick, isSingleAppointment, isAgenda = false }: DayEventProps) => {
  const { start, end, title, resource, resourceId } = event;
  const [tooltip, setTooltip] = useState(false);

  const ref = useRef<HTMLElement>(null);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const now = moment();
  const startTime = moment(start).format('HH:mm');
  const endTime = (() => {
    if (moment(end).format('HH:mm') !== '23:59') return moment(end).format('HH:mm');
    return '24:00';
  })();

  const status = now.isBetween(moment(start), moment(end)) ? EventStatus.INPROGRESS : resource.status;
  const isPastEvent = moment(end).isBefore(currentDate);
  const EventIcon = statusIcons[status || EventStatus.PENDING];

  const eventSchedule = `${startTime}-${endTime}`;
  const colorType = isPastEvent && !resource.isBreak ? 'light' : 'standard';

  const timeslot = {
    start: startTime,
    end: endTime,
  };

  const diff = moment(end).diff(moment(start), 'minutes');

  // Set up count of strings for display in event card
  const defaultStringsCount = diff > 2 * timeStepInDayWeekView ? 5 : (diff > timeStepInDayWeekView && 3) || 1;
  const stringsToTrim = isAgenda ? 1 : defaultStringsCount;

  let leftOffset = ref.current
    ? 0.5 * ((ref.current.clientWidth < cardTooltipWidth ? ref.current.clientWidth : cardTooltipWidth) || 0)
    : cardTooltipWidth / 8;
  if (Number(endTime.slice(0, 2)) >= 23) leftOffset = -4;

  const bgColor = isAgenda ? 'transparent' : resource?.backgroundColor[colorType];
  const defaultTitleColor = isAgenda ? colors.grey.dark : colors.white.standard;

  const currentEvent = useMemo(() => {
    if (resource.isGoogle)
      return {
        id: resource.appointmentId as string,
        orgSpecId: resourceId,
        start: moment(start).toISOString(),
        end: moment(end).toISOString(),
        title,
        status: resource.status,
        isGoogle: true,
        description: resource.description,
      };
    if (resource.isBreak)
      return {
        id: resource.appointmentId as string,
        start: moment(start).toISOString(),
        end: moment(end).toISOString(),
        orgSpecialistId: resourceId,
        isGoogle: false,
      };
    return undefined;
  }, [resource]);

  const touchStartY = useRef(0);

  const handleEventClick = () => {
    resource?.appointmentId && handleClick(resource.appointmentId, currentEvent);
  };

  const handleTouchStart = (e: TouchEvent) => {
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = (e: TouchEvent) => {
    const touchEndY = e.changedTouches[0].clientY;
    Math.abs(touchStartY.current - touchEndY) < 10 && handleEventClick();
  };

  return (
    <Flex>
      {tooltip && isDesktop && !isAgenda && (
        <CardTooltip
          id={`tooltip-${resource.appointmentId}`}
          timeslot={timeslot}
          duration={resource.duration}
          status={status !== EventStatus.UNCLOSED ? status : EventStatus.COMPLETED}
          service={title}
          specialist={resource.specialist}
          client={!resource.isGoogle ? resource.client : undefined}
          isGoogle={resource.isGoogle}
          isBreak={resource.isBreak}
          leftOffset={leftOffset}
          isSingleAppointment={isSingleAppointment}
          isOpen={!isAgenda && tooltip}
        />
      )}
      <EventWrapper
        ref={ref}
        width="100%"
        data-tooltip-id={`tooltip-${resource.appointmentId}`}
        backgroundColor={bgColor}
        lineColor={resource?.backgroundColor[colorType]}
        onClick={isDesktop ? handleEventClick : null}
        onMouseEnter={() => setTooltip(true)}
        onMouseLeave={() => setTooltip(false)}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        isGoogle={resource.isGoogle}
        isBreak={resource.isBreak}
        stringsToTrim={stringsToTrim}
        isAgenda={isAgenda}
      >
        <Flex flexDirection="column" justifyContent={isAgenda ? 'center' : 'auto'}>
          <Caption
            lowline
            large={resource.isBreak}
            color={resource.isBreak || isAgenda ? colors.black.standard : colors.white.standard}
          >
            {eventSchedule}
          </Caption>
          <Caption color={resource.isBreak ? '#54cc29' : defaultTitleColor}>{title}</Caption>
        </Flex>
        <Caption>
          {/* TODO: This color is missing in CRM design system */}
          <EventIcon
            width={isAgenda ? '16px' : '12px'}
            height={isAgenda ? '16px' : '12px'}
            fill={isAgenda ? colors.grey.standard : '#FFFFFF99'}
          />
        </Caption>
      </EventWrapper>
    </Flex>
  );
};
