import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { getMinWidthMediaQuery, useMediaQuery } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../../routes/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ThunkAddress } from '../../../store/redux-slices/addressSlice';
import { ThunkOrganisation } from '../../../store/redux-slices/organisationSlice';
import { ThunkService } from '../../../store/redux-slices/serviceSlice';
import { ThunkSpecialist } from '../../../store/redux-slices/specialistSlice';
import { selectUserHead } from '../../../store/redux-slices/userSlice';
import { Loader } from '../../Loader';
import { MainMenu } from '../../MainMenu';
import { MobileMenu } from '../../MobileMenu/MobileMenu';
import { BodyWrapper } from './style';

export const NavbarLayout = () => {
  const userHead = useAppSelector(selectUserHead);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  useEffect(() => {
    const initializeApp = async () => {
      if (userHead) {
        const orgId = userHead.headOrgId;
        await dispatch(ThunkOrganisation.fetchHead(orgId));
        await dispatch(ThunkAddress.fetchAllAddresses(orgId));
        await dispatch(ThunkSpecialist.fetchAllSpecialists(orgId));
        await dispatch(ThunkService.fetchAllServices(orgId));
      }
    };

    initializeApp().finally(() => setLoading(false));
  }, []);

  return (
    <>
      <MainMenu onMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
      <BodyWrapper>{isLoading ? <Loader /> : <Outlet />}</BodyWrapper>
      {isLarge ? null : (
        <MobileMenu
          onCalendarClick={() => navigate(RouterUrl.Calendar)}
          onMenuClick={() => setIsMenuOpen(true)}
          onHomeClick={() => navigate(RouterUrl.AccountSetting)}
        />
      )}
    </>
  );
};
