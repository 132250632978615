import { BodyLarge, BodySmall, Caption, colors, Link, TrashIcon } from '@beauty/beauty-market-ui';
import { Widget } from 'components';
import { Adaptive } from 'hoc';

export const ServiceItem = ({ category, price, name, onDelete, separator, onClick, isDesktop }) => (
  <Widget
    p="16px 0"
    title={
      <Adaptive mobile={Caption} desktop={BodySmall} color={isDesktop ? colors.black.standard : colors.grey.dark}>
        {category}
      </Adaptive>
    }
    subtitle={
      <Adaptive mobile={BodyLarge} desktop={BodyLarge} large>
        {name}
      </Adaptive>
    }
    description={
      <Adaptive mobile={BodySmall} desktop={BodySmall} large>
        {price}
      </Adaptive>
    }
    icons={<Link design="gray" size="md" icon={<TrashIcon />} onClick={onDelete} />}
    separator={separator}
    onClick={onClick}
  />
);
