import styled from 'styled-components';
import { Flex, colors } from '@beauty/beauty-market-ui';
import { croppedSingleLineText } from 'style';

export const AddressOptionWrapper = styled(Flex)`
  width: 85%;
  height: 56px;
  padding: 8px 0;
  box-sizing: border-box;
  border-radius: 10px;
  align-items: center;
  gap: 8px;

  div:first-child > div {
    padding: 0;
  }
`;

export const CategoryOptionWrapper = styled(AddressOptionWrapper)`
  div > div {
    justify-content: center;
  }
  svg {
    width: 24px !important;
    height: 24px !important;
    rect {
      fill: ${colors.grey.light};
    }
  }
`;

export const TextWrapper = styled(Flex)`
  flex-direction: column;
  overflow-x: auto;
  * {
    ${croppedSingleLineText};
  }
`;
