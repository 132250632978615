import { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { FilterDropdown } from '@beauty/beauty-market-ui';
import { checkAccess } from 'helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectAllAddresses } from 'store/redux-slices/addressSlice';
import { selectAppointments, setSelectedAddress } from 'store/redux-slices/appointmentsSlice';
import { selectSpecialist } from 'store/redux-slices/specialistSlice';
import { AccessRange } from '../../../constants';
import { getAllAddressOptions, getSpecialistAddressOptions } from './helpers';

export const FilterByAddress = () => {
  const dispatch = useAppDispatch();
  const isSuperAdmin = checkAccess(AccessRange.SUPERADMIN);
  const specialist = useAppSelector(selectSpecialist);
  const allAddress = useAppSelector(selectAllAddresses);
  const { selectedAddress } = useAppSelector(selectAppointments);

  const addressOptions = useMemo(
    () => (isSuperAdmin ? getAllAddressOptions(allAddress) : getSpecialistAddressOptions(specialist.organization)),
    [isSuperAdmin, allAddress, specialist.organization],
  );

  const handleAddressSelect = (id: string) => {
    dispatch(setSelectedAddress(id));
  };

  useEffect(() => {
    if (!isEmpty(addressOptions) && !selectedAddress) {
      dispatch(setSelectedAddress(addressOptions[0].id));
    }
  }, [addressOptions]);

  return !isEmpty(addressOptions) ? (
    <FilterDropdown
      options={addressOptions}
      selectedOptionId={selectedAddress}
      onSelect={handleAddressSelect}
      placeholder="Search"
    />
  ) : null;
};
