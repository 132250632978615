import { Avatar, BodyLarge, BodySmall, Caption, Checkbox, colors, Flex, Separator } from '@beauty/beauty-market-ui';
import { Adaptive } from 'hoc';

export const WorkPlace = ({ image, separator, service, address, onChange, checked, isDesktop }) => (
  <>
    <Flex alignItems="center" p="8px 0">
      {typeof image === 'string' || typeof image === 'undefined' ? (
        <Flex minWidth="max-content" flexShrink={0} mr="16px">
          <Avatar url={image} rating="" size="s" />
        </Flex>
      ) : (
        image
      )}
      <Flex flexDirection="column" mr="auto" width={isDesktop ? '330px' : '65vw'}>
        <Adaptive mobile={BodyLarge} desktop={BodySmall} truncated large={!isDesktop} title={service}>
          {service}
        </Adaptive>
        <Adaptive mobile={Caption} desktop={BodySmall} truncated color={colors.grey.dark} title={address}>
          {address}
        </Adaptive>
      </Flex>
      <Checkbox shape="round" onCheckboxClick={onChange} checked={checked} />
    </Flex>
    {separator && <Separator />}
  </>
);
