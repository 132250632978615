import { TFunction } from 'react-i18next';
import * as Yup from 'yup';

export enum MainServiceFormFields {
  Name = 'name',
  Description = 'description',
}

export type MainServiceFormType = {
  [MainServiceFormFields.Name]: string;
  [MainServiceFormFields.Description]: string;
};

export const mainServiceValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [MainServiceFormFields.Name]: Yup.string()
      .trim()
      .required(t('validation.notEmpty'))
      .min(2, t('validation.minLength2'))
      .max(150, t('validation.maxLength150')),
    [MainServiceFormFields.Description]: Yup.string()
      .trim()
      .min(2, t('validation.minLength2'))
      .max(1000, t('validation.maxLength1000')),
  });
