import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { CalendarIcon, Flex } from '@beauty/beauty-market-ui';
import { SidebarFooter, SidebarSheet } from 'components';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { SpecialistEducation } from 'types';
import { Dropzone } from './Dropzone';
import { EducationFormFields, EducationFormType, getInitialValues, schema } from './Education.definitions';

type Props = {
  defaultValues?: SpecialistEducation | null;
  onClose: () => void;
  onSubmit: (data: EducationFormType) => Promise<void>;
};

export const EditEducation = ({ onClose, onSubmit, defaultValues }: Props) => {
  const { t } = useTranslation();
  const { School, Start, End, Field, Degree } = EducationFormFields;

  const onSubmitForm = async (data: EducationFormType) =>
    onSubmit({
      ...data,
      file: data.file || null,
    }).finally(onClose);

  const formikContextValue = {
    initialValues: getInitialValues(defaultValues),
    validationSchema: schema(t),
    validateOnMount: true,
    onSubmit: onSubmitForm,
  };

  return (
    <Formik {...formikContextValue}>
      {({ handleSubmit, isValid, isSubmitting, dirty }) => (
        <Form>
          <SidebarSheet
            onClose={onClose}
            onBack={onClose}
            label={t('specialists.personalInfo.education')}
            descriptor={t('specialists.personalInfo.addInfoAboutSpecialist')}
            FooterBody={
              <SidebarFooter
                onBack={onClose}
                onSubmit={handleSubmit}
                confirmationLabel={t('button.save')}
                cancelLabel={t('button.cancel')}
                isLoading={isSubmitting}
                disabled={!isValid || !dirty}
              />
            }
          >
            <Flex flexDirection="column" gap="16px" width="100%" mb="80px">
              <FormikInput
                id={School}
                name={School}
                design="white"
                placeholder={t('specialists.personalInfo.school')}
              />
              <FormikInput
                id={Degree}
                name={Degree}
                design="white"
                placeholder={t('specialists.personalInfo.degree')}
              />
              <FormikInput
                id={Field}
                name={Field}
                design="white"
                placeholder={t('specialists.personalInfo.fieldOfStudy')}
              />
              <Flex gap="16px">
                <FormikInput
                  id={Start}
                  name={Start}
                  design="white"
                  placeholder={t('specialists.personalInfo.startYear')}
                  iconLeft={<CalendarIcon />}
                />
                <FormikInput
                  id={End}
                  name={End}
                  design="white"
                  placeholder={t('specialists.personalInfo.endYear')}
                  iconLeft={<CalendarIcon />}
                />
              </Flex>
              <Dropzone />
            </Flex>
          </SidebarSheet>
        </Form>
      )}
    </Formik>
  );
};
