import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { Badge, Button, getMinWidthMediaQuery, PlusIcon, Separator, useMediaQuery } from '@beauty/beauty-market-ui';
import { DeleteService, EditService, InfoBlock, ServiceStep } from 'components';
import { getFormattedCurrency, getTranslation } from 'helpers';
import { useStepper } from 'hooks';
import { selectHeadState } from 'store/redux-slices/organisationSlice';
import { getSelectedLanguage } from '../../../../constants';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearNewService, selectAllServices, ThunkService } from '../../../../store/redux-slices/serviceSlice';
import { selectAllSpecialists } from '../../../../store/redux-slices/specialistSlice';
import { ServiceItem } from './ServiceItem';

export const ServiceSection = () => {
  const { t } = useTranslation();
  const { initialized, head } = useAppSelector(selectHeadState);
  const allServices = useAppSelector(selectAllServices) || [];
  const staff = useAppSelector(selectAllSpecialists) || [];

  const dispatch = useAppDispatch();
  const language = getSelectedLanguage();

  const [openEditBar, setIsOpenEditBar] = useState(false);
  const [serviceId, setServiceId] = useState<string | null>(null);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleClosePopup = useCallback(() => setServiceId(null), []);

  const title = [
    '',
    t('settings.services.stepper.main'),
    t('settings.services.stepper.photoUpload'),
    t('settings.services.stepper.tariff'),
  ];

  const { currentStep, handleFirstStep, handleBackStep, handleReset, StepperComponent, handleNextStep } = useStepper({
    title,
  });

  const handleClose = () => {
    if (openEditBar) setIsOpenEditBar(false);
    dispatch(clearNewService());
    handleReset();
  };

  const handleAddClick = () => {
    dispatch(clearNewService());
    handleFirstStep();
  };

  const serviceItems = useMemo(() => {
    if (!isArray(allServices)) return [];
    return allServices.map((item, index, arr) => {
      const handleServiceClick = async (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        await dispatch(ThunkService.fetchServiceById(item.id));
        setIsOpenEditBar(true);
      };

      const price =
        item.listPrice !== null
          ? getFormattedCurrency(item.listPrice, head?.currency, language)
          : t('settings.services.nullPrice');

      const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setServiceId(item.id);
      };

      return (
        <ServiceItem
          key={item.id}
          price={price}
          name={item.title.text}
          onDelete={handleDelete}
          onClick={handleServiceClick}
          category={item.category ? getTranslation(item.category.title, language) : ''}
          separator={index !== arr.length - 1}
          isDesktop={isDesktop}
        />
      );
    });
  }, [allServices, language, t]);

  const specialists = useMemo(() => staff.filter(item => item.role && item.role === 'SPECIALIST'), [staff]);

  const empty = isEmpty(serviceItems);
  const button = (
    <Button
      size={empty && !isDesktop ? 'small' : 'extraSmall'}
      onClick={handleAddClick}
      prefix={<PlusIcon width="16px" height="16px" />}
      my={empty && !isDesktop ? '16px' : '0'}
      disabled={initialized ? false : specialists.length === 0}
    >
      {(empty || isDesktop) && t('button.add')}
    </Button>
  );
  return (
    <>
      <InfoBlock
        title={t('settings.services.title')}
        description={t('settings.services.description')}
        placeholder={t('settings.services.empty')}
        count={serviceItems.length}
        visibleCount={1}
        badge={empty ? null : <Badge text={serviceItems.length} design="lightGrey" />}
        separator={isDesktop ? <Separator mb={empty ? '16px' : '0'} /> : null}
        position="default"
        button={button}
        emptyButton={button}
      >
        {serviceItems}
      </InfoBlock>
      <ServiceStep
        stepper={StepperComponent}
        currentStep={currentStep}
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        handleClose={handleClose}
      />
      {openEditBar && <EditService onClose={handleClose} />}
      {serviceId && <DeleteService id={serviceId} onClose={handleClosePopup} />}
    </>
  );
};
