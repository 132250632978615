import styled from 'styled-components';
import { Flex, colors } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  gap: 10px;
  height: 32px;
  padding: 6px 10px;
  border-radius: 200px;
  box-sizing: border-box;
  background-color: ${colors.grey.light};
  align-items: center;
  button {
    padding: 0;
    color: ${colors.black.standard};
  }
`;
