import styled from 'styled-components';
import { Flex } from '@beauty/beauty-market-ui';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  padding: 24px 24px 8px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white.standard};
  border-radius: 10px;

  & > div:nth-of-type(3) {
    max-width: 100%;
  }
`;

export const ServiceListContent = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  max-width: 1280px;
  width: 100%;
`;
