import { capitalize, isEqual } from 'lodash';
import moment from 'moment/moment';
import { NotifyPropsType } from '@beauty/beauty-market-ui';
import { TimeItemType } from '../../../../hooks/useTimeList';
import { DayParamsType, EditWorkingTimeParamsType, ErrorDataType } from '../../../../types';
import { WeekDay } from '../../../../types/organisation';
import { DayOrgDataType, DayType, OrgMapType, ScheduleStateType } from './types';

export const createWeek = (): { id: string; dayOfWeek: string }[] =>
  WeekDay.map(dayName => ({
    id: crypto.randomUUID(),
    dayOfWeek: dayName,
  }));

export const createDay = (dayName: string): DayType => ({
  isWorkDay: false,
  dayOfWeek: dayName,
  dayOrgMap: {},
  dayOrgIds: [],
});

export const getEditScheduleError = (err: ErrorDataType, t): NotifyPropsType => {
  let errorMessage = null;
  switch (err.statusCode) {
    case 400: {
      if (err.message.includes('brake')) {
        const splitter = err.message.split(' ');
        const items = { day: t(`daysOfWeek.${capitalize(splitter[5])}`), week: splitter[9], time: splitter[10] };
        errorMessage = t('specialists.alerts.subtitle.validation.brake', items);
        break;
      }
      break;
    }
    case 409: {
      if (err.message.includes('overlapping')) {
        errorMessage = t('specialists.alerts.subtitle.validation.overlapping');
        break;
      }
      break;
    }
    default:
      errorMessage = null;
  }
  return errorMessage;
};

const extractOrganisationDayTime = (dayOfWeek: string, dayOrgData: DayOrgDataType, timeList: TimeItemType[]) => {
  const { startIndex, endIndex, breaks } = dayOrgData;
  const orgSpecDayBreak = breaks.map(breakOne => ({
    start: timeList[breakOne.startIndex].item,
    end: timeList[breakOne.endIndex].item,
  }));

  return {
    dayOfWeek,
    start: timeList[startIndex].item,
    end: timeList[endIndex].item,
    orgSpecDayBreak,
  };
};

export const extractEditWorkingTimeParams = (
  state: ScheduleStateType,
  orgId: string,
  orgSpecId: string,
  timeList: TimeItemType[],
): EditWorkingTimeParamsType => {
  const weeks = state.weeks.slice(0, state.repeatIndex + 1);
  const mappedWeeks = weeks.map((week, index) => {
    const templateDayId = week.find(
      dayId => state.days[dayId].dayOrgIds.includes(orgId) && state.days[dayId].dayOrgMap[orgId].isApplyForAllDays,
    );
    let daySchedule: DayParamsType[] = [];

    if (templateDayId) {
      daySchedule = week.map(dayId =>
        extractOrganisationDayTime(state.days[dayId].dayOfWeek, state.days[templateDayId].dayOrgMap[orgId], timeList),
      );
    } else {
      daySchedule = week
        .filter(dayId => state.days[dayId].isWorkDay && state.days[dayId].dayOrgIds.includes(orgId))
        .map(dayId =>
          extractOrganisationDayTime(state.days[dayId].dayOfWeek, state.days[dayId].dayOrgMap[orgId], timeList),
        );
    }

    return {
      weekNumber: index + 1,
      daySchedule,
    };
  });

  return {
    orgSpecId,
    scheduleStart: moment(state.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    weekSchedule: mappedWeeks,
  };
};

const isSpecWorkDayOutOfSchedule = (
  dayData: DayType,
  orgMap: OrgMapType,
  allDayMap: Record<string, DayOrgDataType>,
): boolean => {
  const { dayOrgIds, dayOrgMap, dayOfWeek, isWorkDay } = dayData;
  return dayOrgIds.some(dayOrgId => {
    const template = allDayMap && allDayMap[dayOrgId];
    if (template && !orgMap[dayOrgId][dayOfWeek]) return true;
    if (!isWorkDay) return false;
    if (!orgMap[dayOrgId][dayOfWeek]) return true;

    const { startIndex: templateStartIndex, endIndex: templateEndIndex } = template ?? {
      startIndex: null,
      endIndex: null,
    };
    const { startIndex: orgStartIndex, endIndex: orgEndIndex } = orgMap[dayOrgId][dayOfWeek];
    const { startIndex: specStartIndex, endIndex: specEndIndex } = dayOrgMap[dayOrgId];

    const startIndex = templateStartIndex || specStartIndex;
    const endIndex = templateEndIndex || specEndIndex;

    if (startIndex < orgStartIndex) return true;
    return endIndex > orgEndIndex;
  });
};

const isOrgWorkDayOutOfSpecSchedule = (
  dayData: DayType,
  orgMap: OrgMapType,
  orgIds: string[],
  allDayMap: Record<string, DayOrgDataType>,
): boolean => {
  const { dayOfWeek, isWorkDay } = dayData;
  return orgIds.some(orgId => {
    const isOrgWorkDay = orgMap[orgId][dayOfWeek];
    const isSpecWorkDay = allDayMap && allDayMap[orgId] ? allDayMap[orgId].isApplyForAllDays : isWorkDay;
    return Boolean(isOrgWorkDay) !== Boolean(isSpecWorkDay);
  });
};

const getAllDayMapForOrganisations = (
  weeks: string[][],
  days: Record<string, DayType>,
): Record<number, Record<string, DayOrgDataType>> => {
  const allDayMap = {};
  weeks.forEach((week, weekIndex) => {
    week.forEach(dayId => {
      days[dayId].dayOrgIds.forEach(dayOrgId => {
        if (days[dayId].dayOrgMap[dayOrgId].isApplyForAllDays) {
          if (!allDayMap[weekIndex]) allDayMap[weekIndex] = {};
          if (!allDayMap[weekIndex][dayOrgId]) allDayMap[weekIndex][dayOrgId] = {};
          allDayMap[weekIndex][dayOrgId] = days[dayId].dayOrgMap[dayOrgId];
        }
      });
    });
  });
  return allDayMap;
};

export const checkOutOfSchedule = (state: ScheduleStateType): boolean => {
  const weeks = state.weeks.slice(0, state.repeatIndex + 1);
  // const dayIds = weeks.flatMap(dayId => dayId);
  const allDayMap = getAllDayMapForOrganisations(weeks, state.days);

  let specialistWorkDayOutOfOrgSchedule = false;
  let organisationWorkDayOutOfSpecSchedule = false;

  for (let weekIndex = 0; weekIndex < weeks.length; weekIndex += 1) {
    specialistWorkDayOutOfOrgSchedule = weeks[weekIndex].some(dayId =>
      isSpecWorkDayOutOfSchedule(state.days[dayId], state.orgMap, allDayMap[weekIndex]),
    );

    organisationWorkDayOutOfSpecSchedule = weeks[weekIndex].some(dayId =>
      isOrgWorkDayOutOfSpecSchedule(state.days[dayId], state.orgMap, state.orgIds, allDayMap[weekIndex]),
    );

    if (specialistWorkDayOutOfOrgSchedule || organisationWorkDayOutOfSpecSchedule) {
      return true;
    }
  }

  return false;
};

export const getDayId = (state: ScheduleStateType) => state.weeks[state.weekIndex][state.dayIndex];

export const setErrors = (orgId, day, errors) => {
  const { id, startIndex, endIndex, breaks } = day.dayOrgMap[orgId];

  if (startIndex === -1 || endIndex === -1) {
    errors[id] = ' ';
  } else if (startIndex >= endIndex) {
    errors[id] = 'validation.compareTime';
  } else {
    errors[id] = '';
  }

  breaks.forEach(breakOne => {
    if (breakOne.startIndex === -1 || breakOne.endIndex === -1) {
      errors[breakOne.id] = ' ';
    } else if (breakOne.startIndex >= breakOne.endIndex) {
      errors[breakOne.id] = 'validation.compareBreak';
    } else if (breakOne.endIndex >= endIndex) {
      errors[breakOne.id] = 'validation.compareBreakAndTime';
    } else if (breakOne.startIndex <= startIndex) {
      errors[breakOne.id] = 'validation.compareBreakAndTime';
    } else {
      errors[breakOne.id] = '';
    }
  });
};

export const clearOrganisationErrors = (org, errors) => {
  org?.breaks.forEach(breakOne => {
    if (errors[breakOne.id]) {
      delete errors[breakOne.id];
    }
  });

  if (org?.id && errors[org.id]) {
    delete errors[org.id];
  }
};

export const clearErrors = (day, errors) => {
  day.dayOrgIds.forEach(dayOrgId => {
    clearOrganisationErrors(day.dayOrgMap[dayOrgId], errors);
  });
};

export const clearWeekOrganisationAllDays = (week, days, orgId) => {
  week.forEach(dayOfWeekId => {
    if (days[dayOfWeekId].dayOrgIds.includes(orgId)) {
      days[dayOfWeekId].dayOrgMap[orgId].isApplyForAllDays = false;
    }
  });
};

export const hasScheduleChanged = (days: Record<string, DayType>, initialDays: Record<string, DayType>): boolean => {
  const index = Object.keys(days).findIndex(dayId => {
    const isOrgTimeChanged = days[dayId].dayOrgIds.some(
      id => !isEqual(days[dayId].dayOrgMap[id], initialDays[dayId].dayOrgMap[id]),
    );
    if (isOrgTimeChanged) return true;
    if (days[dayId].isWorkDay !== initialDays[dayId].isWorkDay) return true;
    return days[dayId].dayOrgIds.length !== initialDays[dayId].dayOrgIds.length;
  });

  return index !== -1;
};
