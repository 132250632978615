import { Calendar } from 'react-big-calendar';
import styled, { css } from 'styled-components';
import { colors, Caption, H6BasicStyles, mediaQueries, Flex } from '@beauty/beauty-market-ui';
import { lineColor, todayColorAll } from 'components/Calendar/constant';

export const StyledCalendar = styled(Calendar)<{
  isTimeIndicator: boolean;
  withBanner: boolean;
  isSubscription: boolean;
  isLoading: boolean;
  dayOfWeek?: number;
}>`
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: -webkit-fill-available;
  z-index: 1;
  background-color: ${colors.grey.light};
  max-height: ${({ withBanner }) => (withBanner ? 'calc(100vh - 158px)' : 'calc(100vh - 110px)')};

  .rbc-time-slot {
    position: relative;
    width: 100%;

    ${({ isSubscription }) =>
      isSubscription &&
      css`
        :hover {
          cursor: pointer;
          ${mediaQueries.md} {
            ::before {
              position: absolute;
              font-size: 22px;
              content: '+';
              top: -9px;
              right: 0;
            }
          }
        }
      `}
  }

  .rbc-timeslot-group,
  .rbc-time-slot {
    z-index: 1;
  }

  .rbc-time-header-gutter {
    padding: 0;
    width: 0 !important;
    min-width: 0 !important;
    ${mediaQueries.md} {
      padding: 0 33px;
      width: 88px !important;
    }

    background-color: ${colors.white.standard};
    border: none;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 16px;
    max-width: 88px !important;
  }
  .rbc-time-header-content {
    border-left: none;
    border-top-right-radius: 16px;
  }
  .rbc-time-header {
    height: 64px;
    width: inherit;
    border-radius: 16px 16px 0px 0px;
    border-right: none;
    background-color: ${colors.white.standard};
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    margin-right: 0 !important;
  }
  .rbc-time-header-content > .rbc-row.rbc-row-resource {
    border-bottom: none;
    height: 72px;
    ${mediaQueries.md} {
      height: 64px;
    }
  }
  .rbc-time-view {
    border-radius: 8px;
    ${mediaQueries.md} {
      border-radius: 16px;
    }
    border: none;
    overflow-x: hidden;
    background-color: ${colors.white.standard};
  }
  .rbc-time-content {
    & > span:first-child {
      width: 100%;
      max-width: 64px;
      ${mediaQueries.md} {
        max-width: 88px !important;
      }
    }
    width: 100%;
    border-radius: 0 0 16px 16px;
    border-top: none;
    background: repeating-linear-gradient(135deg, transparent, transparent 8px, ${lineColor} 9px, ${lineColor} 10px);
    span .reset {
      background: ${colors.white.standard} !important;
    }
    & > .rbc-time-column:last-child {
      border-right: 1px solid #ddd;
    }
  }

  .rbc-time-gutter {
    border-right: none;
    z-index: 1;

    .rbc-time-column * {
      background: ${colors.white.standard} !important;
    }
    button {
      display: none !important;
    }
  }

  .rbc-events-container {
    margin-right: 15px;
  }

  .rbc-current-time-indicator {
    ${({ isTimeIndicator }) =>
      isTimeIndicator
        ? css`
            height: 2px;
            background-color: ${colors.blue.standard};
            left: -90px;
          `
        : `display: none;`}
  }

  // rbc-event and rbc-event-label used to override native event with custom one
  .rbc-event {
    padding: 0px;
    border-radius: 0px;
    border: none;
    background-color: transparent;
    overflow: visible;
    width: 100%;
    right: 16px !important;
    max-width: calc(100% - 16px);
  }

  .rbc-event-content > div {
    height: -webkit-fill-available;
  }

  .rbc-event-label {
    display: none;
  }

  .rbc-today {
    background: transparent;
    .reset {
      background: ${todayColorAll};
    }
  }

  .rbc-header:last-child {
    border-top-right-radius: 0;
    ${mediaQueries.md} {
      border-top-right-radius: 16px;
    }
  }

  .rbc-month-view {
    border: none;
    overflow: overlay;
    ${mediaQueries.md} {
      overflow: hidden;
      border-bottom: 1px solid #ddd;
    }

    .rbc-today {
      background: ${colors.blue.light};
    }

    .rbc-row.rbc-month-header {
      flex: 0 0;
      z-index: 1;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
      .rbc-header:first-child {
        border-top-left-radius: 16px;
      }
    }
    .rbc-row-content {
      height: inherit;
      z-index: auto;
    }
    .rbc-row {
      height: inherit;
      flex-direction: column;
      ${mediaQueries.md} {
        flex-direction: row;
      }
      .rbc-date-cell:first-of-type > div > div > div:first-of-type > div:last-child > div {
        right: -180px;
      }
      .rbc-row-segment {
        display: none;
      }
    }
    .rbc-month-row {
      overflow: visible;
      height: auto;
      background: ${colors.white.standard};
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-top: none;
      ${mediaQueries.md} {
        height: 100%;
        border-top: 1px solid #ddd;
      }
      .rbc-row:not(:has(.rbc-date-cell)) {
        display: none;
      }
    }
    .rbc-header {
      display: none;
      ${mediaQueries.md} {
        display: flex;
      }
    }
    .rbc-date-cell {
      padding-right: 0px;
      & > div {
        flex-direction: row;
        padding: 0 8px;
        ${mediaQueries.md} {
          flex-direction: column;
          padding: 8px;
        }
      }
    }
    .rbc-now div[type='today'] {
      background-color: ${colors.blue.light};
    }

    .rbc-row-bg {
      display: none;
      ${mediaQueries.md} {
        display: flex;
      }
    }
  }

  .rbc-time-header-content
    > .rbc-row.rbc-time-header-cell
    > .rbc-header:first-of-type
    > .rbc-button-link
    > div
    > div:last-of-type
    > div {
    right: -8px;
  }

  // temporary hidden cause it will be unused in our case
  .rbc-allday-cell {
    display: none;
  }

  .rbc-header {
    display: flex;
    overflow: visible;
    position: relative;
    text-align: left;

    border-radius: 8px;
    ${mediaQueries.md} {
      height: 64px;
      border-radius: 0;
    }

    border-left: none;
    padding: 0px;
    background-image: none;
    background-color: ${colors.white.standard};

    & > button:first-of-type {
      width: 100%;
    }
    & > div:first-of-type {
      height: 72px;
      ${mediaQueries.md} {
        height: auto;
      }
    }
  }

  .rbc-header.past-date-cell {
    button svg {
      display: flex;
    }
    ${mediaQueries.md} {
      button svg {
        display: none;
      }
    }
  }

  .rbc-header.past-date-cell.rbc-today {
    :hover {
      button svg {
        display: flex;
      }
    }
  }

  .rbc-time-header {
    overflow: visible;
    margin-top: 1px;
    height: 72px;
    ${mediaQueries.md} {
      margin-top: 0;
      height: 64px;
    }
    .rbc-time-header-cell {
      height: 100%;
    }
  }

  ${mediaQueries.md} {
    .rbc-header.rbc-today:after {
      content: '';
      position: absolute;
      bottom: -4px;
      display: block;
      width: 100%;
      height: 4px;
      background: ${({ theme }) => theme.colors.blue.standard};
      z-index: 2;
    }
  }

  .rbc-time-header,
  .rbc-time-header-cell {
    width: 100%;
    ${mediaQueries.md} {
      width: calc(100% - 2px);
    }
  }

  .rbc-off-range-bg {
    background: ${colors.grey.light};
  }

  .rbc-month-row {
    .rbc-button-link {
      width: 100%;
      text-align: left;
      ${({ theme }) => H6BasicStyles(theme)}
      padding-top: 20px;
      padding-left: 26px;
    }
    .rbc-off-range {
      display: none;
      ${mediaQueries.md} {
        display: flex;
        & > div {
          width: 100%;
        }
      }
    }
  }

  .rbc-month-row:last-child {
    button ~ div {
      top: -125px;
      right: 32px;
    }
  }

  & ::-webkit-scrollbar {
    background: ${({ theme }) => theme.colors.white.standard};
    width: 4px;
  }

  & ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey.standard};
    border-radius: 15px;
  }

  & ::-webkit-scrollbar-track {
    background: transparent;
  }

  .rbc-day-slot {
    .past-date-cell {
      :hover {
        cursor: auto;
        ::before {
          display: none;
        }
      }
    }
  }

  ${props => {
    let styles = '';
    for (let i = 1; i <= 7; i += 1) {
      styles += `
        .rbc-month-row:has(.past-date-cell:nth-child(${i})) {
          & .rbc-date-cell:nth-child(${i}) {
            button {
              cursor: auto;
            }
            button svg {
              display: none;
            }
          }
        }
      `;
    }
    return styles;
  }}

  ${({ dayOfWeek }) => {
    if (dayOfWeek) {
      let styles = '';
      for (let i = 1; i <= 7; i += 1) {
        if (i !== dayOfWeek) {
          styles += `
          .rbc-time-content {
            & > div:nth-of-type(${i}) {
                display: none;
              }
            }
        `;
          styles += `.rbc-row.rbc-time-header-cell .rbc-header:nth-child(${i}) {display: none;}`;
        }
      }
      return styles;
    }
    return '';
  }}

  .rbc-date-cell.rbc-now {
    ${({ isSubscription }) =>
      isSubscription &&
      css`
        :hover {
          button svg {
            display: flex !important;
            cursor: pointer;
          }
        }
      `}
  }

  .rbc-agenda-view {
    padding: 8px;
    background-color: ${colors.white.standard};
    border-radius: 8px;
  }
  .rbc-agenda-date-cell {
    display: none;
  }

  .rbc-agenda-time-cell {
    display: none;
  }

  .rbc-agenda-table {
    border: none !important;
    border-spacing: 0 16px;
    // background-color: ${colors.white.standard};
    thead {
      display: none;
    }
    tbody > tr + tr {
      border-top: none !important;
    }
    tbody > tr > td + td {
      border-left: none !important;
    }
    tbody > tr > td {
      padding: 0 !important;
    }
  }

  .rbc-row {
    min-height: 0;
    ${mediaQueries.md} {
      min-height: auto;
    }
  }
  .rbc-agenda-view {
    height: 100%;
    ${({ isLoading }) => isLoading && `justify-content: center;`}
  }
`;

export const CustomTimeGutterWrapper = styled(Caption)`
  background-color: ${colors.white.standard};
  padding: 6px 16px 0px 8px;
  ${mediaQueries.md} {
    padding: 0 24px;
  }

  .rbc-timeslot-group {
    border-bottom: none;
    text-align: left;
    color: ${colors.grey.standard};
  }

  .rbc-timeslot-group:nth-child(4n + 1) {
    color: ${colors.black.standard};
  }
`;

export const FloatingButton = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  position: fixed;
  bottom: 38px;
  right: 8px;
  width: 56px;
  z-index: 1;

  ${mediaQueries.md} {
    bottom: 18px;
  }
`;
