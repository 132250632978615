import styled, { css } from 'styled-components';
import { colors, Div, Flex, mediaQueries } from '@beauty/beauty-market-ui';
import { zIndex } from '../../constants';

export const Wrapper = styled(Flex)<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  transition: width 0.2s ease-out;
  z-index: ${zIndex.mainMenuColumn};
  background-color: ${colors.black.standard};

  &:hover {
    cursor: pointer;
  }

  ${({ isOpen }) => `width: ${isOpen ? '100%' : '0'};`};

  ${mediaQueries.md} {
    ${({ isOpen }) => `width: ${isOpen ? '260px' : '84px'}`};
  }
`;

export const MenuWrapper = styled(Div)`
  box-sizing: border-box;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr 40px;
  grid-column-gap: 0;
  grid-row-gap: 0;
  justify-content: start;
  align-items: start;
  padding: 16px 8px;

  ${mediaQueries.md} {
    padding: 24px 16px;
  }
`;

export const LogoWrapper = styled(Flex)`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: start;
  padding: 0 6px;
`;

export const SectionWrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
  margin-top: 20px;

  & > div {
    width: 100%;
    height: 40px;
    min-height: 40px;
  }

  ${mediaQueries.md} {
    margin-top: 24px;

    & > div {
      height: 52px;
      min-height: 52px;
    }
  }
`;

export const IconFlex = styled(Flex)<{ size: string }>`
  align-items: center;
  justify-content: center;

  & svg {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }

  :hover {
    cursor: pointer;
  }
`;

export const StyledScroll = styled(Div)`
  width: 100%;
  height: 100%;
`;

export const CutWrapper = styled(Flex)<{ isMenuOpen: boolean }>`
  position: absolute;
  height: 100%;
  width: inherit;

  ${({ isMenuOpen }) => css`
    opacity: ${isMenuOpen ? '0' : '1'} !important;
    display: ${isMenuOpen ? 'none' : 'block'} !important;
  `}
`;

export const FullWrapper = styled(Flex)<{ isMenuOpen: boolean }>`
  position: absolute;
  height: 100%;
  width: inherit;

  ${({ isMenuOpen }) => css`
    opacity: ${isMenuOpen ? '1' : '0'} !important;
  `}/* transition: opacity 0.5s linear, visibility 0s ease-out 0.5s; */
`;

export const FooterWrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  z-index: 5;
  bottom: 16px;
`;

export const LanguageList = styled(Flex)<{ isOpen: boolean }>`
  position: absolute;
  width: 100%;
  flex-direction: column;
  background: ${colors.black.standard};
  box-shadow: 0px -30px 28px 0px ${colors.black.standard};
  z-index: 4;
  transition: opacity 0.2s linear;

  transform: ${({ isOpen }) => (isOpen ? 'translateY(-100%)' : 'translateY(100%)')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};

  ${mediaQueries.md} {
    width: 222px;
  }
`;

export const LanguageItem = styled(Flex)<{ event?: boolean }>`
  position: relative;
  z-index: 11;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
  padding: 8px 14px;

  pointer-events: ${({ event }) => (event ? 'all' : 'none')};

  ${mediaQueries.md} {
    padding: 14px;
  }

  :hover {
    background-color: #171721;

    div div div:nth-child(2) div svg {
      opacity: 1;
    }

    div div div:first-child,
    div div div:nth-child(2) div svg {
      cursor: pointer;
    }
  }
`;

export const BlockSeparator = styled(Div)<{ show: boolean }>`
  height: 8px;
  border-top: 1px solid #ffffff14;
  background-color: ${colors.black.standard};
  transition: opacity 0.2s linear;

  opacity: ${({ show }) => (show ? 1 : 0)};
`;
