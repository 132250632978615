import { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import { Nullable } from 'tsdef';
import { AppointmentDetails, Loader } from 'components';
import { useGetClientAppointments } from 'hooks';
import { Accordion } from './Accordion';
import { AppointmentsList } from './AppointmentsList';

type Props = {
  type: 'past' | 'future';
  title: string;
  placeholder: string;
};

const TAppointmentsSection = ({ type, title, placeholder }: Props) => {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [data, isLoading] = useGetClientAppointments(id, type, page, 10);
  const [appointmentId, setAppointmentId] = useState<Nullable<string>>(null);

  const getMore = useCallback(() => {
    page < data.totalPages && setPage(page + 1);
  }, [setPage, page, data.totalPages]);

  const handleClose = () => setAppointmentId(null);
  const handleRefresh = () => setPage(1);

  return (
    <>
      <Accordion
        title={title}
        defaultState={!isEmpty(data.appointments) && data.appointments.length < 5}
        onEnd={getMore}
        type={type}
      >
        <AppointmentsList appointments={data.appointments} onClick={setAppointmentId} placeholder={placeholder} />
        {isLoading && <Loader />}
      </Accordion>
      {appointmentId && <AppointmentDetails id={appointmentId} onClose={handleClose} onRefresh={handleRefresh} />}
    </>
  );
};

export const AppointmentsSection = memo(TAppointmentsSection);
