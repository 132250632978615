import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, useNotify } from '@beauty/beauty-market-ui';
import { updatePhotosByChunk } from 'helpers';
import { AddressAction } from 'types';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectAddressPhotos, setSave } from '../../../store/redux-slices/addressSlice';
import { SidebarFooter } from '../../SidebarFooter';
import { SidebarSheet } from '../../SidebarSheet';
import { PhotosForm } from './PhotosForm';

export const Photos = ({ handleClose, handleBackStep, stepper }) => {
  const { t } = useTranslation();
  const photos = useAppSelector(selectAddressPhotos);
  const dispatch = useAppDispatch();
  const notify = useNotify();

  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBack = () => {
    dispatch(setSave(true));
    handleBackStep();
  };

  const onFormSubmit = () => {
    setIsSubmitting(true);
    updatePhotosByChunk(photos, AddressAction.EditPhotos, dispatch, notify).finally(() => {
      setIsSubmitting(false);
      handleClose();
    });
  };

  const content = <PhotosForm setIsValid={setIsValid} setIsDirty={setIsDirty} />;

  const footerBody = (
    <SidebarFooter
      disabled={!isValid || !isDirty}
      onSubmit={onFormSubmit}
      onBack={handleBack}
      confirmationLabel={t('button.saveAddress')}
      isLoading={isSubmitting}
    />
  );

  return (
    <SidebarSheet
      onClose={handleClose}
      onBack={handleBack}
      FooterBody={footerBody}
      label={t('settings.addresses.sidebar.workplace')}
      descriptor={t('settings.addresses.sidebar.addPhotos')}
    >
      <Flex flexDirection="column" gap="16px" mb="80px">
        {stepper}
        {content}
      </Flex>
    </SidebarSheet>
  );
};
