import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BodySmall,
  colors,
  Flex,
  getMinWidthMediaQuery,
  H5,
  Icon,
  RightIcon,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { Paper } from 'components';
import { useAppSelector } from 'store/hooks';
import { selectUser } from 'store/redux-slices/userSlice';
import { getLanguageOptions } from '../../../../constants';
import { Language } from '../../../../types';
import { EditLanguage } from './EditLanguage';

export const NotificationLanguage = () => {
  const { t } = useTranslation();
  const { profile } = useAppSelector(selectUser);

  const [isOpen, setOpen] = useState(false);

  const language = profile.language || Language.English;
  const languageOptions = getLanguageOptions(t);
  const languageItem = languageOptions?.data[language] || languageOptions.data.EN;

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <>
      <Paper
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
        onClick={() => setOpen(true)}
        borderRadius={isDesktop ? '0' : '10px'}
        p={isDesktop ? '24px' : '16px 8px'}
      >
        <Flex flexDirection="column">
          <H5>{t('profile.notificationLanguage')}</H5>
          <BodySmall>{t('profile.changeNotificationLanguage')}</BodySmall>
        </Flex>
        <Icon p="10px" minWidth="20px" height="20px" ml="auto">
          {languageItem.icon}
        </Icon>
        <BodySmall large lowline color={colors.grey.standard}>
          {languageItem.value}
        </BodySmall>
        <Icon width="20px" height="20px" ml="16px">
          <RightIcon />
        </Icon>
      </Paper>
      {isOpen && <EditLanguage onClose={handleClose} />}
    </>
  );
};
