import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppleIcon,
  BodySmall,
  Div,
  FacebookIcon,
  Flex,
  GoogleIcon,
  H2,
  Icon,
  MailFillIcon,
  PhoneFillIcon,
  Separator,
  Tab,
  Tabs,
} from '@beauty/beauty-market-ui';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { IconWrapper } from '../style';
import CreateNewAccount from './CreateNewAccount';
import LoginByEmail from './LoginByEmail';
import LoginByPhone from './LoginByPhone';
import { Rules } from './Rules';

const LogInto = () => {
  const { t } = useTranslation();
  const [isEmail, setIsEmail] = useState<boolean>(true);

  const { client } = useGetUrl();

  const choiceLogin = useMemo(
    () => [
      {
        icon: isEmail ? <PhoneFillIcon /> : <MailFillIcon />,
        text: isEmail ? t('login.phone') : t('login.email'),
        function: () => setIsEmail(!isEmail),
      },
      {
        icon: <GoogleIcon />,
        text: 'Google',
        function: () => console.log('google'),
      },
      {
        icon: <AppleIcon />,
        text: 'Apple ID',
        function: () => console.log('Apple ID'),
      },
      {
        icon: (
          <Icon stroke="none" fill="#4267B2" width="24px" heigth="24px">
            <FacebookIcon />
          </Icon>
        ),
        text: 'Facebook',
        function: () => console.log('Facebook'),
      },
    ],
    [isEmail, t],
  );

  return (
    <>
      <H2 mb="0px">{t('login.login')}</H2>
      <BodySmall mb="30px" mt="16px">
        {isEmail ? t('login.emailHint') : t('login.phoneHint')}
      </BodySmall>
      <Tabs stroked activeIndex={1}>
        <Tab label={t('login.customer')} url={client} />
        <Tab label={t('login.business')}>
          <Div mt="15px" width="100%">
            {isEmail ? <LoginByEmail /> : <LoginByPhone />}
          </Div>
        </Tab>
      </Tabs>

      <CreateNewAccount />
      <Separator text={t('login.or')} />
      <Flex justifyContent="space-between" mx="96px" py="32px">
        {choiceLogin.map(item => (
          <IconWrapper key={item.text} onClick={() => item.function()}>
            {item.icon}
            {/* <Caption>{item.text}</Caption> */}
          </IconWrapper>
        ))}
      </Flex>
      <Rules />
    </>
  );
};

export default LogInto;
