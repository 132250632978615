import { useEffect, useMemo, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@beauty/beauty-market-ui/dist/cjs';
import { isEmpty } from 'lodash';
import { Button, PlusIcon, Section, DataList } from '@beauty/beauty-market-ui';
import { DeleteService, EditService, ServiceStep } from 'components';
import { getFormattedCurrency, getTranslation } from 'helpers';
import { useStepper } from 'hooks';
import { getSelectedLanguage } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectHead } from '../../../store/redux-slices/organisationSlice';
import { clearNewService, selectAllServices, ThunkService } from '../../../store/redux-slices/serviceSlice';
import { ServiceListContent, Wrapper } from './style';

export const ServiceList = () => {
  const { t } = useTranslation();
  const { id, currency } = useAppSelector(selectHead);
  const allServices = useAppSelector(selectAllServices);

  const language = getSelectedLanguage();
  const dispatch = useAppDispatch();

  const [isOpenEditBar, setOpenEditBar] = useState(false);
  const [serviceId, setServiceId] = useState<string | null>(null);

  const serviceItems = useMemo(() => {
    if (!Array.isArray(allServices)) return [];
    return allServices.map((item, index) => {
      const handleServiceClick = async (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        await dispatch(ThunkService.fetchServiceById(item.id));
        setOpenEditBar(true);
      };

      const handleDeleteClick = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setServiceId(item.id);
      };

      const price =
        item.listPrice !== null
          ? getFormattedCurrency(item.listPrice, currency, language)
          : t('settings.services.nullPrice');

      return (
        <DataList
          key={item.id}
          price={price}
          label={item.title.text}
          title={item.category ? getTranslation(item.category.title, language) : ''}
          onClick={handleServiceClick}
          onLabelClick={handleServiceClick}
          onDeleteClick={handleDeleteClick}
          enableSeparator={index !== allServices.length - 1}
          cursor="pointer"
        />
      );
    });
  }, [allServices, language, t]);

  const title = [
    '',
    t('settings.services.stepper.main'),
    t('settings.services.stepper.photoUpload'),
    t('settings.services.stepper.tariff'),
  ];

  const { currentStep, handleFirstStep, handleBackStep, handleReset, StepperComponent, handleNextStep } = useStepper({
    title,
  });

  const handleClosePopup = () => {
    setServiceId(null);
  };

  const handleClose = () => {
    if (isOpenEditBar) setOpenEditBar(false);
    dispatch(clearNewService());
    handleReset();
  };

  const handleAddClick = () => {
    dispatch(clearNewService());
    handleFirstStep();
  };

  const addServiceButton = (
    <Button size="small" prefix={<PlusIcon />} onClick={handleAddClick}>
      {t('button.addNew')}
    </Button>
  );

  useEffect(() => {
    dispatch(ThunkService.fetchAllServices(id));
  }, [dispatch]);

  return (
    <ServiceListContent>
      <Section
        title={t('settings.services.title')}
        description=""
        button={addServiceButton}
        badge={!isEmpty(serviceItems) && <Badge text={serviceItems.length} design="lightGrey" />}
      />
      <Wrapper>{serviceItems}</Wrapper>
      <ServiceStep
        stepper={StepperComponent}
        currentStep={currentStep}
        handleNextStep={handleNextStep}
        handleBackStep={handleBackStep}
        handleClose={handleClose}
      />
      {isOpenEditBar && <EditService onClose={handleClose} />}
      {serviceId && <DeleteService id={serviceId} onClose={handleClosePopup} />}
    </ServiceListContent>
  );
};
