import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { BodySmall, colors, getMinWidthMediaQuery, MenuItem, useMediaQuery } from '@beauty/beauty-market-ui';
import { checkAccess } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { selectAddressState } from 'store/redux-slices/addressSlice';
import { selectService } from 'store/redux-slices/serviceSlice';
import { selectSpecialistState } from 'store/redux-slices/specialistSlice';
import { Tooltip } from '../../Tooltip';
import { disabledLinksByHeadOrgConditions, getTooltipInfo } from '../helpers';
import { SectionWrapper } from '../style';
import { MenuSectionProps } from '../types';

export const MenuSection = ({ isFull, title, items, onClose }: MenuSectionProps) => {
  const { t } = useTranslation();

  const { allSpecialists } = useAppSelector(selectSpecialistState);
  const { allServices } = useAppSelector(selectService);
  const { allAddress } = useAppSelector(selectAddressState);

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const staff = allSpecialists.filter(specialist => specialist.role === 'SPECIALIST');
  const shouldBeDisabled = isEmpty(staff) || isEmpty(allServices) || isEmpty(allAddress);
  const acceptance = {
    address: isEmpty(allAddress),
    service: isEmpty(allServices),
    specialist: isEmpty(staff),
  };

  const mappedItems = items.map(item => {
    const { id, count, itemsList, isStroke = false, accessRange } = item;

    const disableLink = disabledLinksByHeadOrgConditions.includes(id) && shouldBeDisabled;
    const tooltipInfo = disableLink ? getTooltipInfo(acceptance, t) : '';

    const hasAccess = checkAccess(accessRange);
    const hasView = isLarge ? true : item.isMobile;

    const handleClick = () => {
      if (disableLink) return;
      item.onClick();
      onClose && onClose();
    };

    return hasAccess && hasView ? (
      <Tooltip id={id} key={id} place="top" content={tooltipInfo} show={disableLink}>
        <MenuItem
          data-tooltip-id={id}
          title={item.title}
          count={count}
          itemsList={itemsList}
          isFull={isFull}
          icon={<item.icon width="24" height="24" />}
          isStroke={isStroke}
          color={disableLink ? colors.grey.darker : colors.grey.standard}
          onClick={handleClick}
          onMouseDown={handleClick}
        />
      </Tooltip>
    ) : null;
  });

  const filteredItems = mappedItems.filter(item => item !== null);

  if (!filteredItems.length) return null;

  return (
    <SectionWrapper>
      <BodySmall large color="#4C4C5F" textAlign={isFull ? 'left' : 'center'} mb="8px" ml={isFull ? '14px' : '0px'}>
        {isFull ? title : '•'}
      </BodySmall>
      {filteredItems}
    </SectionWrapper>
  );
};
