import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { CloseIcon, colors, getMinWidthMediaQuery, Icon, Logo, Scroll, useMediaQuery } from '@beauty/beauty-market-ui';
import { RouterUrl } from '../../../routes/routes';
import { sectionsBody } from '../helpers';
import { LogoWrapper, MenuWrapper, StyledScroll } from '../style';
import { MenuProps } from '../types';
import { MainMenuFooter } from './MainMenuFooter';
import { MenuSection } from './MenuSection';

export const Menu = ({ isFull, onClose }: MenuProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isLarge = useMediaQuery(mediaQuery);

  const handleLogoClick = () => {
    if (isLarge) return;
    navigate(RouterUrl.Calendar);
    onClose && onClose();
  };

  return (
    <MenuWrapper>
      <LogoWrapper>
        <Logo
          monochrome
          labelColor={colors.blue.standard}
          logoColor={colors.blue.standard}
          isHideLabel={!isFull || !isLarge}
          onClick={handleLogoClick}
        />
        {onClose && (
          <Icon stroke={colors.white.standard} width="24px" height="24px" onClick={onClose} ml="auto">
            <CloseIcon />
          </Icon>
        )}
      </LogoWrapper>

      <StyledScroll>
        <Scroll scrollColor="#141520">
          {sectionsBody.map((section, i) => {
            const data = section(t, navigate);
            const title = _.upperCase(data.title);
            return <MenuSection key={i} isFull={isFull} title={title} items={data.items} onClose={onClose} />;
          })}
        </Scroll>
      </StyledScroll>

      <MainMenuFooter isFull={isFull} />
    </MenuWrapper>
  );
};
