import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import { Flex, H6, SearchIcon } from '@beauty/beauty-market-ui';
import { FormikDropdown } from 'components/functional/formik/formik-dropdown/FormikDropdown';
import { FormikInput } from 'components/functional/formik/formik-input/FormikInput';
import { SidebarFooter, SidebarSheet } from 'components/index';
import { checkAccess, getStatusList } from 'helpers';
import { useAppSelector } from 'store/hooks';
import { selectCurrency } from 'store/redux-slices/organisationSlice';
import { AddressPayloadType, AppointmentByIdResponse, OrganisationClientsType, PaymentType } from 'types';
import { AccessRange, currencySymbol, EventStatus } from '../../../../constants';
import { convertAllClientsToOptions, convertAllServicesToOptions } from '../helpers';
import { AppointmentForm, AppointmentFormFields, getInitialValues, schema } from './EditAppointment.definitions';
import { EditNote } from './EditNote';
import { EditService } from './EditService';
import { PaymentInformation } from './PaymentInformation';
import { PaymentSection } from './PaymentSection';

type Props = {
  isSubmitting: boolean;
  readOnly?: boolean;
  appointment: AppointmentByIdResponse;
  clients: OrganisationClientsType[];
  company: AddressPayloadType;
  onClose: () => void;
  onSubmit: (data: Partial<AppointmentForm>) => void;
  onCancel?: () => void;
};

export const EditAppointment = ({
  isSubmitting,
  appointment,
  clients,
  company,
  onClose,
  onCancel,
  onSubmit,
  readOnly,
}: Props) => {
  const { t } = useTranslation();
  const { Status, Client, AppointmentDate, Payment, Service, Price } = AppointmentFormFields;

  const [editNote, setEditNote] = useState(Boolean(appointment?.notes));
  const [editService, setEditService] = useState(false);

  const currency = useAppSelector(selectCurrency);
  const isAdmin = checkAccess(AccessRange.ADMIN);

  const statusList = getStatusList(t);
  const clientList = convertAllClientsToOptions(clients, isAdmin);
  const serviceList = convertAllServicesToOptions(company?.orgService ?? [], currency, t);

  const showNegotiatedPrice = appointment.status === EventStatus.UNCLOSED && appointment.price === null;
  const currencySign = currencySymbol[currency];

  const onFormSubmit = (data: AppointmentForm) => {
    const prepareData = {
      ...data,
      [Payment]: PaymentType.CASH,
      [Status]: data[Status] === EventStatus.COMPLETED ? EventStatus.PAST : data[Status],
    };
    onSubmit(prepareData);
  };

  const formikContextValue = {
    initialValues: getInitialValues({ ...appointment, price: `${currencySign}0` }),
    validationSchema: schema(
      t,
      currencySign,
      appointment?.status === EventStatus.INPROGRESS || readOnly || !appointment.price,
    ),
    onSubmit: onFormSubmit,
    validateOnMount: false,
  };

  return (
    <Formik {...formikContextValue}>
      {({ values, setFieldValue, handleSubmit, dirty, isValid }) => {
        const sidebarProps = {
          isOpen: true,
          onClose,
          label: `${t('calendar.newAppointmentSidebar.appointment')} ${values[AppointmentDate]}`,
          descriptor: t('calendar.newAppointmentSidebar.mainInformation'),
          FooterBody: (
            <SidebarFooter
              extraActionLabel={onCancel ? t('calendar.newAppointmentSidebar.cancelAppointment') : undefined}
              confirmationLabel={
                readOnly || appointment.status === EventStatus.UNCLOSED
                  ? t('button.finishAppointment')
                  : t('button.save')
              }
              cancelLabel={t('button.cancel')}
              onBack={onClose}
              onSubmit={handleSubmit}
              handleExtraAction={onCancel}
              disabled={editService || (!readOnly && (!dirty || !isValid))}
              isLoading={isSubmitting}
            />
          ),
        };

        const content = (
          <Form>
            <Flex flexDirection="column" gap="24px">
              <FormikDropdown
                id={Status}
                name={Status}
                placeholder={t('calendar.newAppointmentSidebar.appointmentStatus')}
                currentOption={statusList.findIndex(item => item.status === values[Status])}
                options={statusList}
                onChange={index => setFieldValue(Status, statusList[index].status)}
                disabled={readOnly}
              />
              <Flex flexDirection="column" gap="16px">
                <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.client')}</H6>
                <FormikInput
                  id={Client}
                  name={Client}
                  design="white"
                  type="client"
                  searchInput
                  disabled
                  options={clientList}
                  iconLeft={<SearchIcon />}
                  onDeleteIconClick={() => setFieldValue(Client, '')}
                  onSelect={(index: number) => setFieldValue(Client, clientList[index].id)}
                  currentOption={clientList.findIndex(item => item.id === values[Client])}
                  placeholder={
                    isEmpty(clientList)
                      ? t('calendar.newAppointmentSidebar.addNewClient')
                      : t('calendar.newAppointmentSidebar.clientSearch')
                  }
                />
              </Flex>
              <Flex flexDirection="column" gap="16px" mb="40px">
                <H6 marginTop="8px">{t('calendar.newAppointmentSidebar.service')}</H6>
                <EditService
                  readOnly={readOnly}
                  company={company}
                  editMode={editService}
                  onEdit={() => setEditService(true)}
                  onApply={() => setEditService(false)}
                  onDelete={() => setEditService(true)}
                />
                {!readOnly && !editService && (
                  <EditNote editNote={editNote} onClick={() => setEditNote(true)} onBlur={() => setEditNote(false)} />
                )}

                {showNegotiatedPrice && (
                  <>
                    <H6 mt="8px">{t('calendar.newAppointmentSidebar.details.negotiatedPrice')}</H6>
                    <FormikInput
                      id={Price}
                      name={Price}
                      placeholder={t('calendar.newAppointmentSidebar.details.price')}
                      design="white"
                      onFocus={
                        values[Price] && values[Price] !== `${currencySign}0`
                          ? undefined
                          : () => setFieldValue(Price, currencySign)
                      }
                      onBlur={() => {
                        if (values[Price].charAt(0) !== currencySign) {
                          values[Price] = `${currencySign}${values[Price]}`;
                        }
                      }}
                      focused
                    />
                  </>
                )}

                {!editService && (
                  <PaymentInformation
                    service={values[Service]}
                    services={serviceList}
                    price={showNegotiatedPrice ? values[Price] : null}
                  />
                )}
                {(readOnly || showNegotiatedPrice) && <Field id={Payment} name={Payment} as={PaymentSection} />}
              </Flex>
            </Flex>
          </Form>
        );

        return <SidebarSheet {...sidebarProps}>{content}</SidebarSheet>;
      }}
    </Formik>
  );
};
