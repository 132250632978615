import { CalendarIcon, MenuIcon, HomeIcon } from '@beauty/beauty-market-ui';
import { Wrapper } from './style';

type MenuHandlers = {
  onCalendarClick: () => void;
  onMenuClick: () => void;
  onHomeClick: () => void;
};

export const MobileMenu = ({ onCalendarClick, onMenuClick, onHomeClick }: MenuHandlers) => (
  <Wrapper>
    <HomeIcon onClick={onHomeClick} />
    <CalendarIcon onClick={onCalendarClick} />
    <MenuIcon onClick={onMenuClick} />
  </Wrapper>
);
