import { useTranslation } from 'react-i18next';
import { AppointmentsSection } from './AppointmentsSection';

export const Appointments = () => {
  const { t } = useTranslation();
  return (
    <>
      <AppointmentsSection
        type="future"
        title={t('clients.profile.appointments.future')}
        placeholder={t('clients.profile.appointments.noFuture')}
      />
      <AppointmentsSection
        type="past"
        title={t('clients.profile.appointments.past')}
        placeholder={t('clients.profile.appointments.noPast')}
      />
    </>
  );
};
