import styled from 'styled-components';
import { Flex, mediaQueries } from '@beauty/beauty-market-ui';

export const PageContainer = styled(Flex)`
  flex-direction: column;
  gap: 1px;
  width: 100%;
  margin-bottom: 80px;

  ${mediaQueries.md} {
    margin-bottom: 0;
  }
`;

export const InputWrapper = styled(Flex)`
  width: 100%;
  margin-bottom: 8px;

  & > div {
    width: 100%;
    max-width: 100%;
  }

  svg {
    width: 16px;
    height: 16px;
  }

  input {
    margin: 0 0 0 4px;
  }
`;

export const IconBox = styled(Flex)`
  justify-self: center;
  align-self: center;
  gap: 16px;
  opacity: 0;
  width: 0;
`;

export const Container = styled(Flex)`
  &:hover ${IconBox} {
    opacity: 1;
    width: max-content;
  }
`;

export const Box = styled(Flex)<{ available: boolean }>`
  &:hover ${IconBox} {
    opacity: ${({ available }) => (available ? 1 : 0)};
    width: ${({ available }) => (available ? 'max-content' : 0)};
  }
`;

export const StatusCard = styled(Flex)`
  width: 100%;
  gap: 8px;
  padding: 24px 16px;
  align-items: center;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.grey.light};
  cursor: pointer;
  box-sizing: border-box;
`;

export const Content = styled(Flex)`
  flex-direction: column;
  gap: 4px;
  margin-right: 8px;
  width: 70vw;

  ${mediaQueries.sm} {
    width: 80vw;
  }

  ${mediaQueries.md} {
    width: 330px;
  }
`;
