import { TFunction } from 'react-i18next';
import * as Yup from 'yup';
import { passwordReg } from '../../../constants';

export enum RegistrationFormFields {
  Name = 'name',
  Surname = 'surname',
  Password = 'password',
  Number = 'number',
  Email = 'email',
  Region = 'code',
  Agreement = 'agreement',
}

export type RegistrationForm = {
  [RegistrationFormFields.Name]: string;
  [RegistrationFormFields.Surname]: string;
  [RegistrationFormFields.Region]: string;
  [RegistrationFormFields.Number]: string;
  [RegistrationFormFields.Email]: string;
  [RegistrationFormFields.Password]: string;
  [RegistrationFormFields.Agreement]: boolean;
};

export const initialvalues: RegistrationForm = {
  [RegistrationFormFields.Name]: '',
  [RegistrationFormFields.Surname]: '',
  [RegistrationFormFields.Region]: '',
  [RegistrationFormFields.Number]: '',
  [RegistrationFormFields.Email]: '',
  [RegistrationFormFields.Password]: '',
  [RegistrationFormFields.Agreement]: false,
};

export const registrationFormValidationSchema = (t: TFunction<'translation', undefined>) =>
  Yup.object({
    [RegistrationFormFields.Name]: Yup.string()
      .trim()
      .min(2, t('validation.minName'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [RegistrationFormFields.Surname]: Yup.string()
      .trim()
      .min(2, t('validation.minName'))
      .max(50, t('validation.maxName'))
      .required(t('validation.notEmpty')),
    [RegistrationFormFields.Region]: Yup.string().trim(),
    [RegistrationFormFields.Number]: Yup.string()
      .trim()
      .typeError(t('validation.wrongPhone'))
      .matches(/^\d{7,}$/, t('validation.enterValidPhone'))
      .required(t('validation.phoneRequired')),
    [RegistrationFormFields.Password]: Yup.string()
      .required(t('validation.notEmpty'))
      .min(8, t('validation.minPassword'))
      .matches(passwordReg, t('validation.passwordHint')),
  });
