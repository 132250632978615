import { isMobile } from 'react-device-detect';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { NotifyContainer } from '@beauty/beauty-market-ui';
import { ErrorBoundary, Footer, Header, Main, MenuClient, NavbarLayout, ProtectedRoute } from 'components';
import { checkAccess } from 'helpers';
import {
  AccountSetting,
  AddressAboutCompany,
  AddressGeneralData,
  AddressList,
  AddressPage,
  ChangePlanPage,
  ClientAppointments,
  ClientPage,
  ClientPageData,
  ClientPayments,
  ClientReviews,
  Clients,
  CurrentPlanPage,
  CustomCalendar,
  EmailVerification,
  EmployeeGeneralData,
  EmployeeList,
  EmployeePage,
  EmployeePersonalInfo,
  Home,
  Login,
  LoginIntro,
  LoginSMS,
  LogInto,
  NotificationPage,
  PersonalProfile,
  PhoneVerification,
  ProfileSettings,
  Registration,
  ResetPassword,
  SentEmailNotification,
  ServiceList,
  ServiceUnavailable,
  SignUp,
  UnsubscribePage,
  UpdatePassword,
  WelcomeCrm,
} from 'page';
import { useAppSelector } from 'store/hooks';
import { selectUserHead } from 'store/redux-slices/userSlice';
import { AccessRange } from '../constants';
import { selectAuth } from '../store/redux-slices/authSlice';
import { RouterUrl } from './routes';

const Router = () => {
  const { isLogin } = useAppSelector(selectAuth);
  const userHead = useAppSelector(selectUserHead);
  const isAdmin = checkAccess(AccessRange.ADMIN);
  const isSuperAdmin = checkAccess(AccessRange.SUPERADMIN);

  return (
    <BrowserRouter>
      <ErrorBoundary fallback={<ServiceUnavailable />}>
        <Header />
        <Main>
          <Routes>
            <Route path={RouterUrl.LoginIntro} element={<LoginIntro />} />
            <Route path={RouterUrl.Login} element={<ProtectedRoute isAllowed={!isLogin} component={<Login />} />}>
              <Route index path={RouterUrl.Login} element={<LogInto />} />
              <Route path={RouterUrl.LoginSMS} element={<LoginSMS />} />
              <Route path={RouterUrl.PhoneVerification} element={<PhoneVerification />} />
              <Route path={RouterUrl.Registration} element={<Registration />} />
              <Route path={RouterUrl.SignUp} element={<SignUp />} />
              <Route path={RouterUrl.EmailVerification} element={<EmailVerification />} />
              <Route path={RouterUrl.ResetPassword} element={<ResetPassword />} />
              <Route path={RouterUrl.UpdatePassword} element={<UpdatePassword />} />
              <Route path={RouterUrl.SentEmailNotification} element={<SentEmailNotification />} />
            </Route>

            <Route path={RouterUrl.Homepage} element={<Home />} />
            <Route
              path={RouterUrl.WelcomeCrm}
              element={<ProtectedRoute isAllowed={isLogin} component={<WelcomeCrm />} />}
            />

            <Route
              element={
                <ProtectedRoute isAllowed={isLogin} component={<NavbarLayout />} redirectPath={RouterUrl.Login} />
              }
            >
              {/* Mixed Access */}
              <Route
                path={RouterUrl.AccountSetting}
                element={isAdmin || !userHead ? <AccountSetting /> : <Navigate to={RouterUrl.Calendar} />}
              />

              {/* Admin Access */}
              <Route element={<ProtectedRoute isAllowed={isAdmin} component={<Outlet />} />}>
                <Route element={<ClientPage />}>
                  <Route path={RouterUrl.ClientPageData} element={<ClientPageData />} />
                  <Route path={RouterUrl.ClientAppointments} element={<ClientAppointments />} />
                  <Route path={RouterUrl.ClientPayments} element={<ClientPayments />} />
                  <Route path={RouterUrl.ClientReviews} element={<ClientReviews />} />
                </Route>
                <Route path={RouterUrl.Clients} element={<Clients />} />
                <Route path={RouterUrl.EmployeePage} element={<EmployeePage />}>
                  <Route path={RouterUrl.EmployeeGeneralData} element={<EmployeeGeneralData />} />
                  <Route path={RouterUrl.EmployeePersonalInfo} element={<EmployeePersonalInfo />} />
                </Route>
                <Route path={RouterUrl.AddressList} element={<AddressList />} />
                <Route path={RouterUrl.ServiceList} element={<ServiceList />} />
                <Route path={RouterUrl.EmployeeList} element={<EmployeeList />} />
                <Route path={RouterUrl.AddressPage} element={<AddressPage />}>
                  <Route path={RouterUrl.AddressGeneralData} element={<AddressGeneralData />} />
                  <Route path={RouterUrl.AddressAboutCompany} element={<AddressAboutCompany />} />
                </Route>
              </Route>

              {/* SuperAdmin Access */}
              <Route
                path={RouterUrl.ProfileSettings}
                element={<ProtectedRoute isAllowed={isSuperAdmin} component={<Outlet />} />}
              >
                <Route index path={RouterUrl.ProfileSettings} element={<ProfileSettings />} />
                <Route path={RouterUrl.CurrentPlanPage} element={<CurrentPlanPage />} />
                <Route path={RouterUrl.ChangePlanPage} element={<ChangePlanPage />} />
                <Route path={RouterUrl.UnsubscribePage} element={<UnsubscribePage />} />
                <Route path={RouterUrl.NotificationSettings} element={<NotificationPage />} />
              </Route>

              {/* Specialist Access */}
              <Route path={RouterUrl.PersonalProfile} element={<PersonalProfile />} />
              <Route path={RouterUrl.Calendar} element={<CustomCalendar />} />
            </Route>
          </Routes>
          <MenuClient />
        </Main>
        <Footer />
        <NotifyContainer position={isMobile ? 'top-center' : 'top-right'} />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default Router;
