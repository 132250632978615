import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import {
  BodyLarge,
  BodySmall,
  Button,
  Caption,
  colors,
  EditIcon,
  Flex,
  getMinWidthMediaQuery,
  H6,
  Slider,
  useMediaQuery,
} from '@beauty/beauty-market-ui';
import { InfoBlock } from 'components';
import { getTranslation } from 'helpers';
import { useRequest } from 'hooks';
import { useAppSelector } from 'store/hooks';
import { selectNewAddress, ThunkAddress } from 'store/redux-slices/addressSlice';
import { AddressAction } from 'types';
import { getSelectedLanguage } from '../../../../../constants';
import { EditAboutCompany } from './EditAboutCompany';
import { AboutCompanyFormType } from './EditAboutCompany.defenitions';

export const AboutCompany = () => {
  const { t } = useTranslation();
  const { description, property } = useAppSelector(selectNewAddress);

  const [action, setAction] = useState<AddressAction>(AddressAction.None);

  const language = getSelectedLanguage();

  const mediaQuery = getMinWidthMediaQuery('md');
  const isDesktop = useMediaQuery(mediaQuery);

  const editDescription = useRequest(ThunkAddress.editDescription, AddressAction.EditDescription);

  const handleEdit = () => setAction(AddressAction.EditDescription);

  const handleClose = () => setAction(AddressAction.None);

  const handleSubmit = async (data: AboutCompanyFormType) => {
    const params = { description: data?.description || null, property: data?.property?.join(',') ?? '' };
    return editDescription(params).finally(handleClose);
  };

  const slides = property.map(prop => {
    const title = getTranslation(prop.title, language);
    return (
      <Flex key={prop.id} gap="6px" mr="24px" width="max-content" flexWrap="nowrap">
        <img src={prop.iconUrl} alt={title} width={20} height={20} />
        <BodyLarge large>{title}</BodyLarge>
      </Flex>
    );
  });

  const button = (
    <Button size="extraSmall" design="secondary" prefix={<EditIcon height="16px" width="16px" />} onClick={handleEdit}>
      {isDesktop && t('button.edit')}
    </Button>
  );
  return (
    <InfoBlock
      title={t('address.aboutCompany')}
      button={button}
      count={description?.length || 1}
      visibleCount={500}
      position="last"
    >
      <Flex flexDirection="column" gap="24px">
        {!isEmpty(slides) && <Slider inlineSlider title={<H6 />} slides={slides} />}
        {isEmpty(description) ? (
          <Caption color={colors.grey.dark}>{t('address.haveNotDescription')}</Caption>
        ) : (
          <BodySmall>{description}</BodySmall>
        )}
      </Flex>
      {action === AddressAction.EditDescription && (
        <EditAboutCompany
          defaultValues={{ description, property: property.map(item => item.id) }}
          onClose={handleClose}
          onBack={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </InfoBlock>
  );
};
